import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Jumbotron,
  TabContent,
  TabPane,
  CardText,
  CardImg,
} from 'reactstrap';
import '../coffee.css';
import img1 from '../images/emji-coffee.png';
import img2 from '../images/coffee.png';
import Slider from 'react-slick';
import img4 from '../images/coffe-shop.png';
import img5 from '../images/coffe-shop1.png';
import img6 from '../images/delivery.png';
import img7 from '../images/catering.png';
import { connect } from 'react-redux';
import config from '../../../../../constants/config';
import classnames from 'classnames';

function formatMoney(amount, decimalCount = 0, decimal = ',', thousands = '.') {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
        Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
  }
}

function isEmpty(obj) {
  if (obj === 'null') {
    return true;
  } else if (obj === undefined) {
    return true;
  } else {
    return Object.keys(obj).length === 0;
  }
}

class FormContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      full_name: '',
      message: '',
      phone_number: '',
      email: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleSubmit = e => {
    e.preventDefault();
    const { sendBook, history } = this.props;

    return sendBook(this.state)
      .then(() => setTimeout(() => history.push('/emji'), 100))
      .then(() => setTimeout(() => history.push('/emji/coffee'), 101))
      .catch(() => { });
  };

  render() {
    return (
      <Row>
        <Col md={3} className="text-left">
          <h5 className="space__contact-h5">Mediterania Group</h5>
          <p className="space__contact-p">
            The Bellagio Mansion Unit G-01, Jl. Mega Kuningan Timur II <br />
            Kuningan, East Kuningan, Setiabudi, South <br /> Jakarta
          </p>
          <a href="tel:+6281776539000">
            <svg
              className="mr-3"
              width="51"
              height="51"
              viewBox="0 0 51 51"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="51" height="51" fill="black" />
              <path
                d="M36.9755 32.918C37.0263 33.3074 36.9077 33.646 36.62 33.9337L33.2427 37.2855C33.0903 37.4548 32.8915 37.5988 32.646 37.7172C32.4005 37.8358 32.1593 37.9119 31.9224 37.9458C31.9055 37.9458 31.8546 37.9501 31.7699 37.9586C31.6854 37.967 31.5753 37.9713 31.4399 37.9713C31.1182 37.9713 30.5976 37.9163 29.8781 37.8062C29.1586 37.6961 28.2783 37.4252 27.2373 36.9936C26.196 36.5619 25.0153 35.9143 23.6948 35.051C22.3744 34.1878 20.9693 33.0027 19.4796 31.496C18.2945 30.3279 17.3127 29.2106 16.5339 28.1441C15.7552 27.0775 15.1288 26.0914 14.6548 25.1857C14.1808 24.2801 13.8253 23.459 13.5883 22.7226C13.3513 21.9862 13.1904 21.3514 13.1058 20.8181C13.0212 20.2848 12.9873 19.8659 13.0042 19.5611C13.0212 19.2564 13.0296 19.0871 13.0296 19.0533C13.0635 18.8163 13.1397 18.575 13.2582 18.3296C13.3767 18.0841 13.5206 17.8852 13.6899 17.7328L17.0672 14.3555C17.3042 14.1185 17.575 14 17.8797 14C18.0998 14 18.2945 14.0635 18.4638 14.1904C18.6331 14.3174 18.777 14.474 18.8955 14.6602L21.6126 19.8151C21.7649 20.0859 21.8072 20.3822 21.7395 20.7038C21.6718 21.0255 21.5279 21.2963 21.3078 21.5164L20.0636 22.7607C20.0297 22.7945 20.0001 22.8496 19.9747 22.9257C19.9493 23.0019 19.9366 23.0654 19.9366 23.1162C20.0043 23.4717 20.1567 23.878 20.3937 24.3351C20.5968 24.7414 20.91 25.2365 21.3332 25.8206C21.7565 26.4046 22.3574 27.0775 23.1362 27.8393C23.898 28.6181 24.5751 29.2232 25.1676 29.655C25.76 30.0865 26.2553 30.4041 26.6532 30.6072C27.051 30.8104 27.3557 30.9331 27.5673 30.9753L27.8846 31.0388C27.9185 31.0388 27.9736 31.0261 28.0497 31.0007C28.1259 30.9753 28.1809 30.9457 28.2148 30.9118L29.6622 29.439C29.9671 29.1682 30.3224 29.0327 30.7288 29.0327C31.0166 29.0327 31.245 29.0835 31.4143 29.1851H31.4397L36.3405 32.0799C36.6962 32.3002 36.9077 32.5794 36.9755 32.918Z"
                fill="white"
              />
            </svg>
          </a>
          <a href="https://wa.me/6281776539000">
            <svg
              className="mr-3"
              width="51"
              height="51"
              viewBox="0 0 51 51"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="51" height="51" fill="black" />
              <path
                d="M41 25.5879C41 34.197 33.9675 41.1758 25.2908 41.1758C22.5364 41.1758 19.9486 40.4718 17.6972 39.2363L9 42L11.8356 33.6366C10.4052 31.2878 9.58133 28.533 9.58133 25.5879C9.58133 16.9788 16.6146 10 25.2908 10C33.9682 10 41 16.9788 41 25.5879ZM25.2908 12.4825C18.0076 12.4825 12.0834 18.3616 12.0834 25.5879C12.0834 28.4555 13.0181 31.1111 14.5993 33.2715L12.9492 38.1387L18.0247 36.5255C20.11 37.8948 22.6082 38.6933 25.2912 38.6933C32.5733 38.6933 38.4987 32.8149 38.4987 25.5886C38.4987 18.3623 32.5737 12.4825 25.2908 12.4825ZM33.2236 29.178C33.1266 29.019 32.8702 28.923 32.4855 28.7321C32.1001 28.5412 30.2064 27.6164 29.8544 27.4894C29.5013 27.3621 29.2439 27.2981 28.9876 27.6804C28.7312 28.0629 27.9931 28.923 27.768 29.178C27.5433 29.4336 27.3189 29.4656 26.9335 29.2743C26.5488 29.0834 25.3083 28.6795 23.8373 27.3781C22.6928 26.3655 21.9198 25.1154 21.6951 24.7324C21.4708 24.3502 21.6716 24.1436 21.864 23.9534C22.0375 23.782 22.2494 23.5072 22.4418 23.2843C22.6348 23.061 22.6988 22.902 22.8265 22.6468C22.9556 22.3918 22.8912 22.1689 22.7945 21.9772C22.6985 21.7863 21.9276 19.9061 21.6069 19.141C21.2862 18.3765 20.9659 18.5038 20.7408 18.5038C20.5164 18.5038 20.2594 18.4718 20.0027 18.4718C19.746 18.4718 19.3285 18.5675 18.9755 18.9497C18.6228 19.3323 17.6283 20.2567 17.6283 22.1365C17.6283 24.0167 19.0075 25.8332 19.2005 26.0878C19.3929 26.3424 21.8636 30.326 25.7783 31.856C29.6933 33.3852 29.6933 32.875 30.3995 32.811C31.1049 32.7474 32.6772 31.8869 32.9993 30.9952C33.3193 30.102 33.3193 29.3372 33.2236 29.178Z"
                fill="white"
              />
            </svg>
          </a>
          <svg
            className="mr-3"
            width="51"
            height="51"
            viewBox="0 0 51 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="51" height="51" fill="black" />
            <path
              d="M27 29.7139L23.7834 26.8984L14.5869 34.782C14.9211 35.0922 15.3724 35.2853 15.8701 35.2853H38.1299C38.6257 35.2853 39.0751 35.0922 39.4076 34.782L30.2166 26.8984L27 29.7139Z"
              fill="white"
            />
            <path
              d="M39.4131 17.2172C39.0789 16.9052 38.6294 16.7139 38.1299 16.7139H15.8701C15.3743 16.7139 14.9249 16.907 14.5906 17.2209L27 27.8567L39.4131 17.2172Z"
              fill="white"
            />
            <path
              d="M14 18.3447V33.7739L22.9756 26.1466L14 18.3447Z"
              fill="white"
            />
            <path
              d="M31.0244 26.1463L40 33.7736V18.3389L31.0244 26.1463Z"
              fill="white"
            />
          </svg>
        </Col>
        <Col md={9}>
          <Row className="pl-md-4 pt-4 pt-md-0">
            <Col md={4}>
              <FormGroup>
                <Input
                  className="border-dark"
                  type="text"
                  name="full_name"
                  id="full_name"
                  value={this.state.full_name}
                  onChange={this.handleChange}
                  placeholder="Name / Company"
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Input
                  className="border-dark"
                  type="email"
                  name="email"
                  id="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  placeholder="Email"
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Input
                  className="border-dark"
                  type="number"
                  name="phone_number"
                  id="phone_number"
                  value={this.state.phone_number}
                  onChange={this.handleChange}
                  placeholder="Mobile Phone"
                />
              </FormGroup>
            </Col>
            <Col xs={12} className="mt-3">
              <FormGroup>
                <Input
                  className="border-dark"
                  type="textarea"
                  name="message"
                  id="message"
                  value={this.state.message}
                  onChange={this.handleChange}
                  placeholder="Your Message"
                  style={{ minHeight: '163px' }}
                />
              </FormGroup>
            </Col>
            <Col xs={12} className="text-right">
              <FormGroup>
                <Button
                  className="mt-3"
                  onClick={this.handleSubmit}
                  style={{
                    backgroundColor: '#000',
                    color: 'white',
                    width: '204px',
                  }}
                >
                  SEND
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

class BevSlider extends React.Component {
  render() {
    let settings = {
      dots: false,
      infinite: false,
      speed: 500,
      arrows: false,
      slidesToShow: 3.5,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1.2,
            slidesToScroll: 1,
          },
        },
      ],
    };
    const { allProduct } = this.props;

    let filteredBeverage;
    if (allProduct !== undefined) {
      filteredBeverage = allProduct.filter(function (item) {
        return item.category === 'beverage';
      });
    }
    return (
      <Slider {...settings} className="mt-5 mb-5 pb-5">
        {!isEmpty(filteredBeverage) &&
          filteredBeverage.map((item, index) => {
            return (
              <Col xs="12" key="index">
                <div>
                  <CardImg
                    top
                    width="100%"
                    height="192px"
                    src={`${config.apiUrl}${item.image}`}
                    alt="Card image cap"
                  />
                  <CardText className="product__name mt-3">
                    {item.name}
                  </CardText>
                  <CardText className="product__price">
                    Rp. {formatMoney(item.price)}
                  </CardText>
                  <a href="https://gofood.link/u/xBmYL">
                    <Button className="product__cta">ORDER VIA GOFOOD</Button>
                  </a>
                </div>
              </Col>
            );
          })}
      </Slider>
    );
  }
}

class FoodSlider extends React.Component {
  render() {
    let settings = {
      dots: false,
      infinite: false,
      speed: 500,
      arrows: false,
      slidesToShow: 3.5,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1.2,
            slidesToScroll: 1,
          },
        },
      ],
    };
    const { allProduct } = this.props;

    let filteredFood;
    if (allProduct !== undefined) {
      filteredFood = allProduct.filter(function (item) {
        return item.category === 'food';
      });
    }
    return (
      <Slider {...settings} className="mt-5 mb-5 pb-5">
        {!isEmpty(filteredFood) &&
          filteredFood.map((item, index) => {
            return (
              <Col xs="12" key="index">
                <div>
                  <CardImg
                    top
                    width="100%"
                    height="192px"
                    src={`${config.apiUrl}${item.image}`}
                    alt="Card image cap"
                  />
                  <CardText className="product__name mt-3">
                    {item.name}
                  </CardText>
                  <CardText className="product__price">
                    Rp. {formatMoney(item.price)}
                  </CardText>
                  <a href="https://gofood.link/u/xBmYL">
                    <Button className="product__cta">ORDER VIA GOFOOD</Button>
                  </a>
                </div>
              </Col>
            );
          })}
      </Slider>
    );
  }
}

const HomeCoffee = props => {
  // props.getInstagramFeed();
  useEffect(() => {
    props.getInstagramFeed();
    props.getCoffee();
  }, []);
  const { instagramFeed } = props;
  let dataUrl = [];
  if (instagramFeed === [] || instagramFeed !== undefined) {
    dataUrl = instagramFeed.slice(0, 8);
  }
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  // console.log( dataUrl);
  return (
    <div>
      <Jumbotron className="mb-0 pb-0 pr-0 pl-0 pt-md-0">
        <Slider {...settings} style={{ maxHeight: '479px' }}>
          <img
            src="/coffee.jpg"
            className="tetot"
            alt=""
            style={{ minHeight: '200px', maxHeight: '479px' }}
          />
          <img
            src="/coffee-1.jpg"
            className="tetot"
            alt=""
            style={{ minHeight: '200px', maxHeight: '479px' }}
          />
          <img
            src="/coffee-2.jpg"
            className="tetot"
            alt=""
            style={{ minHeight: '200px', maxHeight: '479px' }}
          />
        </Slider>
      </Jumbotron>
      <Container className="mt-5">
        <Row>
          <Col sm="12" md="12">
            {' '}
            <Row className="p-5 home__box">
              <Col xs="12" md="8">
                <h3 className="home__box-title">
                  Wanna explore more in Coffee?
                  <br /> We have it all here
                </h3>
              </Col>
              <Col xs="12" md="4" className="text-center">
                <img src={img2} width="100%" alt="Emji Home" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container id="product" className="text-center mt-5">
        <Row>
          <Col xs="12" md="12">
            <h3 className="coffee__h1">Our Menu</h3>
          </Col>
        </Row>
        <Row style={{ color: 'White', padding: '4vh', display: 'inline-flex' }}>
          <div>
            <h1
              className={classnames(
                { activeCT: activeTab === '1' },
                'menu__toggle'
              )}
              onClick={() => {
                toggle('1');
              }}
            >
              FOOD
            </h1>
          </div>
          <div>
            <h1
              className={classnames(
                { activeCT: activeTab === '2' },
                'menu__toggle'
              )}
              onClick={() => {
                toggle('2');
              }}
            >
              BEVERAGE
            </h1>
          </div>
        </Row>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <FoodSlider {...props} />
          </TabPane>
          <TabPane tabId="2">
            <BevSlider {...props} />
          </TabPane>
        </TabContent>
      </Container>
      <Container id="coffee_shop" className="mt-5">
        <Row>
          <Col xs="12" md="12">
            <h3 className="text-center coffee__h1 mb-5 mt-5">Coffee Shop</h3>
          </Col>
          <Col xs="12" md="6" className="pr-4 mb-5">
            <img src="/pluit.jpg" width="80%" alt="Emji Coffee" />
            <Row>
              <Col xs="12" md="7">
                <p className="mt-4">
                  Ruko Golf Island, Blok A No. 59
                  <br /> Jl. Pantai Indah Kapuk
                  <br /> Pantai Indah Kapuk, Penjaringan, Jakarta Utara
                </p>
              </Col>
              <Col xs="12" md="5" className="pt-md-5">
                <a
                  style={{ color: '#9C9696' }}
                  href="https://www.google.com/maps/place/Emji+Coffee+Bar+%26+Space/@-6.2277389,106.8249539,18z/data=!4m8!1m2!2m1!1sThe+Bellagio+Mansion+Unit+G-01,+Jl.+Mega+Kuningan+Timur+II+Kuningan,+East+Kuningan,+Setiabudi,+South+Jakarta!3m4!1s0x2e69f3795175abcb:0xd73af7997b17db69!8m2!3d-6.2281875!4d106.8277635"
                >
                  <p className="text-right">
                    Views on Maps{' '}
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 42 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0)">
                        <path
                          d="M41.9902 39.0209L38.6905 20.8419C38.6576 20.6604 38.5451 20.5033 38.3838 20.4136L30.0788 15.7944C29.971 15.7344 29.8502 15.7105 29.7315 15.7197L31.2424 35.211L31.1918 35.2336L41.131 39.6926C41.2119 39.7288 41.2979 39.7467 41.3835 39.7467C41.5189 39.7467 41.6531 39.7021 41.7634 39.6158C41.9437 39.475 42.0309 39.246 41.9902 39.0209Z"
                          fill="#44C868"
                        />
                        <path
                          d="M21 20.2105L12.5061 15.7865C12.4043 15.7334 12.2914 15.7117 12.1803 15.7192C12.2097 15.7174 12.2393 15.7176 12.2687 15.7199L10.7578 35.2112L20.7476 39.6929C20.8283 39.729 20.9143 39.7469 21 39.7469V20.2105Z"
                          fill="#44C868"
                        />
                        <path
                          d="M12.2686 15.7197C12.1499 15.7105 12.0291 15.7344 11.9213 15.7943L3.6162 20.4135C3.45492 20.5032 3.34238 20.6603 3.30948 20.8418L0.00993102 39.0209C-0.0309206 39.2458 0.0563608 39.4748 0.236584 39.6158C0.34708 39.7021 0.481201 39.7467 0.616553 39.7467C0.702112 39.7467 0.788163 39.7289 0.868964 39.6926L10.7541 35.2579L12.2686 15.7197Z"
                          fill="#4CE166"
                        />
                        <path
                          d="M29.7314 15.7188C29.6497 15.7251 29.5689 15.7468 29.494 15.7859L21 20.2098V39.7462C21 39.7462 21 39.7462 21.0001 39.7462C21.0859 39.7462 21.172 39.7284 21.2525 39.6922L31.2424 35.2105L29.7314 15.7188Z"
                          fill="#4CE166"
                        />
                        <path
                          d="M2.30951 26.351L1.63923 30.044L11.5645 24.8017L11.795 21.8289L11.8332 21.3359L2.30951 26.351Z"
                          fill="#FFDB56"
                        />
                        <path
                          d="M11.6539 23.6494L11.5645 24.8021L1.63924 30.0444L0.00993101 39.0214C-0.0309206 39.2463 0.0563608 39.4753 0.236584 39.6163C0.34708 39.7026 0.481201 39.7472 0.616553 39.7472C0.702112 39.7472 0.788163 39.7294 0.868964 39.6931L10.7541 35.2584L11.6539 23.6494Z"
                          fill="#A8EEFC"
                        />
                        <path
                          d="M21.0025 22.647L11.8332 21.3359L11.5646 24.8017L21.0001 39.1309V33.9204L15.3697 25.0546L21.0025 25.7283V22.647Z"
                          fill="#FFBB24"
                        />
                        <path
                          d="M37.73 20.0501L33.7404 17.8311L29.9636 18.7135L30.0108 19.3235L30.1973 21.7298L37.73 20.0501Z"
                          fill="#FFBB24"
                        />
                        <path
                          d="M30.5323 26.0514L30.1746 21.4372L29.9635 18.7139L21.0025 22.6474V25.7287L26.8559 23.1833L30.5323 26.0514Z"
                          fill="#FFDB56"
                        />
                        <path
                          d="M41.9901 39.0216L41.1614 34.4553L30.1973 21.7305L30.5323 26.0517L41.7764 39.605C41.9473 39.4634 42.0299 39.2409 41.9901 39.0216Z"
                          fill="#FFBB24"
                        />
                        <path
                          d="M21.0395 2.25293C16.2847 2.25293 12.4164 6.11809 12.4164 10.869C12.4164 13.8079 13.8116 16.9631 16.5635 20.247C18.5847 22.659 20.5784 24.2103 20.6623 24.2752C20.7734 24.361 20.9064 24.404 21.0396 24.404C21.1726 24.404 21.3058 24.361 21.4168 24.2752C21.5007 24.2103 23.4945 22.659 25.5156 20.247C28.2675 16.9631 29.6628 13.808 29.6628 10.869C29.6626 6.11809 25.7943 2.25293 21.0395 2.25293ZM24.233 10.8689C24.233 12.6311 22.8032 14.0598 21.0395 14.0598C19.2758 14.0598 17.846 12.6312 17.846 10.8689C17.846 9.10665 19.2758 7.67808 21.0395 7.67808C22.8032 7.67808 24.233 9.10674 24.233 10.8689Z"
                          fill="#FF4A4A"
                        />
                        <path
                          d="M21.0395 2.25293C20.6626 2.25293 20.2915 2.27738 19.9272 2.32446C24.1587 2.87136 27.4382 6.49461 27.4382 10.869C27.4382 13.8079 26.0429 16.9631 23.291 20.247C21.9912 21.7981 20.7037 22.9924 19.9273 23.6652C20.3583 24.0386 20.6325 24.252 20.6623 24.2752C20.7734 24.361 20.9064 24.404 21.0396 24.404C21.1726 24.404 21.3058 24.361 21.4168 24.2752C21.5007 24.2103 23.4945 22.659 25.5156 20.247C28.2675 16.9631 29.6628 13.808 29.6628 10.869C29.6626 6.11809 25.7943 2.25293 21.0395 2.25293Z"
                          fill="#E7343F"
                        />
                        <path
                          d="M21.2525 39.6932L24.9673 38.0267L21 33.9209V39.7473C21 39.7473 21 39.7473 21.0001 39.7473C21.0858 39.7473 21.1719 39.7295 21.2525 39.6932Z"
                          fill="#FFDB56"
                        />
                        <path
                          d="M38.6904 20.8417C38.6575 20.6603 38.545 20.5031 38.3837 20.4134L37.7299 20.0498L30.1972 21.7296L41.1612 34.4543L38.6904 20.8417Z"
                          fill="#1EA4E9"
                        />
                        <path
                          d="M20.9999 39.7468C21 39.7468 21 39.7468 20.9999 39.7468V39.1309L11.5645 24.8018L10.7576 35.2111L20.7475 39.6928C20.8281 39.729 20.9141 39.7468 20.9999 39.7468Z"
                          fill="#1EA4E9"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect width="42" height="42" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </p>
                </a>
              </Col>
            </Row>
          </Col>
          <Col xs="12" md="6" className="pl-4">
            <img src="/kuningan.jpg" width="80%" alt="Emji Coffee" />
            <Row>
              <Col xs="12" md="7">
                <p className="mt-4">
                  The Bellagio Mansion Unit G-01,<br></br> Jl. Mega Kuningan
                  Timur II<br></br> Kuningan, East Kuningan, Setiabudi, South
                  Jakarta
                </p>
              </Col>
              <Col xs="12" md="5" className="pt-md-5">
                <a
                  style={{ color: '#9C9696' }}
                  href="https://www.google.com/maps/place/Emji+Coffee+Bar+%26+Space/@-6.2277389,106.8249539,18z/data=!4m8!1m2!2m1!1sThe+Bellagio+Mansion+Unit+G-01,+Jl.+Mega+Kuningan+Timur+II+Kuningan,+East+Kuningan,+Setiabudi,+South+Jakarta!3m4!1s0x2e69f3795175abcb:0xd73af7997b17db69!8m2!3d-6.2281875!4d106.8277635"
                >
                  <p className="text-right">
                    Views on maps{' '}
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 42 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0)">
                        <path
                          d="M41.9902 39.0209L38.6905 20.8419C38.6576 20.6604 38.5451 20.5033 38.3838 20.4136L30.0788 15.7944C29.971 15.7344 29.8502 15.7105 29.7315 15.7197L31.2424 35.211L31.1918 35.2336L41.131 39.6926C41.2119 39.7288 41.2979 39.7467 41.3835 39.7467C41.5189 39.7467 41.6531 39.7021 41.7634 39.6158C41.9437 39.475 42.0309 39.246 41.9902 39.0209Z"
                          fill="#44C868"
                        />
                        <path
                          d="M21 20.2105L12.5061 15.7865C12.4043 15.7334 12.2914 15.7117 12.1803 15.7192C12.2097 15.7174 12.2393 15.7176 12.2687 15.7199L10.7578 35.2112L20.7476 39.6929C20.8283 39.729 20.9143 39.7469 21 39.7469V20.2105Z"
                          fill="#44C868"
                        />
                        <path
                          d="M12.2686 15.7197C12.1499 15.7105 12.0291 15.7344 11.9213 15.7943L3.6162 20.4135C3.45492 20.5032 3.34238 20.6603 3.30948 20.8418L0.00993102 39.0209C-0.0309206 39.2458 0.0563608 39.4748 0.236584 39.6158C0.34708 39.7021 0.481201 39.7467 0.616553 39.7467C0.702112 39.7467 0.788163 39.7289 0.868964 39.6926L10.7541 35.2579L12.2686 15.7197Z"
                          fill="#4CE166"
                        />
                        <path
                          d="M29.7314 15.7188C29.6497 15.7251 29.5689 15.7468 29.494 15.7859L21 20.2098V39.7462C21 39.7462 21 39.7462 21.0001 39.7462C21.0859 39.7462 21.172 39.7284 21.2525 39.6922L31.2424 35.2105L29.7314 15.7188Z"
                          fill="#4CE166"
                        />
                        <path
                          d="M2.30951 26.351L1.63923 30.044L11.5645 24.8017L11.795 21.8289L11.8332 21.3359L2.30951 26.351Z"
                          fill="#FFDB56"
                        />
                        <path
                          d="M11.6539 23.6494L11.5645 24.8021L1.63924 30.0444L0.00993101 39.0214C-0.0309206 39.2463 0.0563608 39.4753 0.236584 39.6163C0.34708 39.7026 0.481201 39.7472 0.616553 39.7472C0.702112 39.7472 0.788163 39.7294 0.868964 39.6931L10.7541 35.2584L11.6539 23.6494Z"
                          fill="#A8EEFC"
                        />
                        <path
                          d="M21.0025 22.647L11.8332 21.3359L11.5646 24.8017L21.0001 39.1309V33.9204L15.3697 25.0546L21.0025 25.7283V22.647Z"
                          fill="#FFBB24"
                        />
                        <path
                          d="M37.73 20.0501L33.7404 17.8311L29.9636 18.7135L30.0108 19.3235L30.1973 21.7298L37.73 20.0501Z"
                          fill="#FFBB24"
                        />
                        <path
                          d="M30.5323 26.0514L30.1746 21.4372L29.9635 18.7139L21.0025 22.6474V25.7287L26.8559 23.1833L30.5323 26.0514Z"
                          fill="#FFDB56"
                        />
                        <path
                          d="M41.9901 39.0216L41.1614 34.4553L30.1973 21.7305L30.5323 26.0517L41.7764 39.605C41.9473 39.4634 42.0299 39.2409 41.9901 39.0216Z"
                          fill="#FFBB24"
                        />
                        <path
                          d="M21.0395 2.25293C16.2847 2.25293 12.4164 6.11809 12.4164 10.869C12.4164 13.8079 13.8116 16.9631 16.5635 20.247C18.5847 22.659 20.5784 24.2103 20.6623 24.2752C20.7734 24.361 20.9064 24.404 21.0396 24.404C21.1726 24.404 21.3058 24.361 21.4168 24.2752C21.5007 24.2103 23.4945 22.659 25.5156 20.247C28.2675 16.9631 29.6628 13.808 29.6628 10.869C29.6626 6.11809 25.7943 2.25293 21.0395 2.25293ZM24.233 10.8689C24.233 12.6311 22.8032 14.0598 21.0395 14.0598C19.2758 14.0598 17.846 12.6312 17.846 10.8689C17.846 9.10665 19.2758 7.67808 21.0395 7.67808C22.8032 7.67808 24.233 9.10674 24.233 10.8689Z"
                          fill="#FF4A4A"
                        />
                        <path
                          d="M21.0395 2.25293C20.6626 2.25293 20.2915 2.27738 19.9272 2.32446C24.1587 2.87136 27.4382 6.49461 27.4382 10.869C27.4382 13.8079 26.0429 16.9631 23.291 20.247C21.9912 21.7981 20.7037 22.9924 19.9273 23.6652C20.3583 24.0386 20.6325 24.252 20.6623 24.2752C20.7734 24.361 20.9064 24.404 21.0396 24.404C21.1726 24.404 21.3058 24.361 21.4168 24.2752C21.5007 24.2103 23.4945 22.659 25.5156 20.247C28.2675 16.9631 29.6628 13.808 29.6628 10.869C29.6626 6.11809 25.7943 2.25293 21.0395 2.25293Z"
                          fill="#E7343F"
                        />
                        <path
                          d="M21.2525 39.6932L24.9673 38.0267L21 33.9209V39.7473C21 39.7473 21 39.7473 21.0001 39.7473C21.0858 39.7473 21.1719 39.7295 21.2525 39.6932Z"
                          fill="#FFDB56"
                        />
                        <path
                          d="M38.6904 20.8417C38.6575 20.6603 38.545 20.5031 38.3837 20.4134L37.7299 20.0498L30.1972 21.7296L41.1612 34.4543L38.6904 20.8417Z"
                          fill="#1EA4E9"
                        />
                        <path
                          d="M20.9999 39.7468C21 39.7468 21 39.7468 20.9999 39.7468V39.1309L11.5645 24.8018L10.7576 35.2111L20.7475 39.6928C20.8281 39.729 20.9141 39.7468 20.9999 39.7468Z"
                          fill="#1EA4E9"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect width="42" height="42" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </p>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container id="partnership" className="text-center mt-5">
        <Row>
          <Col xs="12" md="12" className="mt-5">
            <h3 className="coffee__h1">Partnership</h3>
            <p className="mb-5">
              Interested to have our coffee for your event or business? Let us
              know
            </p>
          </Col>
          <Col xs="12" md="6" className="mb-4 mb-md-0">
            <div className="home__border-p py-4">
              <img
                src={img6}
                style={{ width: 'unset' }}
                height="156px"
                className="mt-2"
                alt="coffe-home"
              />
              <p className="mt-4">Coffee To Your Door</p>
              <span>
                Subscribe our coffee, and have it<br></br> delivered to your
                office/house<br></br> every day
              </span>
              <a href="https://wa.me/6281776539000">
                <Button className="mt-5 py-3 px-4 wa__btn" size="sm">
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ verticalAlign: 'bottom' }}
                  >
                    <path
                      d="M26 12.6652C26 19.66 20.2861 25.3304 13.2363 25.3304C10.9983 25.3304 8.89576 24.7584 7.06651 23.7545L0 26L2.30389 19.2048C1.14169 17.2964 0.472333 15.058 0.472333 12.6652C0.472333 5.67031 6.18684 0 13.2363 0C20.2866 0 26 5.67031 26 12.6652ZM13.2363 2.01702C7.31871 2.01702 2.50524 6.7938 2.50524 12.6652C2.50524 14.9951 3.26473 17.1528 4.54942 18.9081L3.20869 22.8627L7.33258 21.552C9.02691 22.6645 11.0566 23.3133 13.2366 23.3133C19.1533 23.3133 23.9677 18.5371 23.9677 12.6658C23.9677 6.79438 19.1536 2.01702 13.2363 2.01702ZM19.6817 15.5821C19.6028 15.453 19.3946 15.375 19.082 15.2198C18.7688 15.0647 17.2302 14.3133 16.9442 14.2102C16.6573 14.1067 16.4482 14.0547 16.2399 14.3653C16.0316 14.6761 15.4319 15.375 15.249 15.5821C15.0664 15.7898 14.8841 15.8158 14.571 15.6604C14.2584 15.5052 13.2505 15.1771 12.0553 14.1197C11.1254 13.297 10.4974 12.2812 10.3148 11.9701C10.1325 11.6596 10.2957 11.4917 10.452 11.3372C10.593 11.1979 10.7652 10.9746 10.9214 10.7935C11.0783 10.612 11.1303 10.4829 11.234 10.2755C11.3389 10.0684 11.2866 9.88722 11.208 9.73151C11.13 9.57638 10.5037 8.04873 10.2431 7.42704C9.98256 6.80593 9.72227 6.90936 9.5394 6.90936C9.35711 6.90936 9.14824 6.88336 8.93967 6.88336C8.73109 6.88336 8.39193 6.96107 8.10507 7.27162C7.81849 7.58247 7.01047 8.33358 7.01047 9.86093C7.01047 11.3886 8.13107 12.8645 8.28793 13.0714C8.44422 13.2782 10.4517 16.5149 13.6324 17.758C16.8133 19.0005 16.8133 18.586 17.3871 18.534C17.9602 18.4822 19.2377 17.7831 19.4994 17.0586C19.7594 16.3329 19.7594 15.7115 19.6817 15.5821Z"
                      fill="white"
                    />
                  </svg>
                  <p className="wa__text">Whatsapp</p>
                </Button></a>
            </div>
          </Col>
          <Col xs="12" md="6">
            <div className="home__border-p py-4">
              <img
                src={img7}
                style={{ width: 'unset' }}
                height="156px"
                alt="coffe-home-2"
                className="mt-2"
              />
              <p className="mt-4">Coffee Catering</p>
              <span>
                Special coffee packages for<br></br> business/events
              </span>
              <a href="https://wa.me/6281776539000">
                <Button className="mt-5 py-3 px-4 wa__btn" size="sm">
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ verticalAlign: 'bottom' }}
                  >
                    <path
                      d="M26 12.6652C26 19.66 20.2861 25.3304 13.2363 25.3304C10.9983 25.3304 8.89576 24.7584 7.06651 23.7545L0 26L2.30389 19.2048C1.14169 17.2964 0.472333 15.058 0.472333 12.6652C0.472333 5.67031 6.18684 0 13.2363 0C20.2866 0 26 5.67031 26 12.6652ZM13.2363 2.01702C7.31871 2.01702 2.50524 6.7938 2.50524 12.6652C2.50524 14.9951 3.26473 17.1528 4.54942 18.9081L3.20869 22.8627L7.33258 21.552C9.02691 22.6645 11.0566 23.3133 13.2366 23.3133C19.1533 23.3133 23.9677 18.5371 23.9677 12.6658C23.9677 6.79438 19.1536 2.01702 13.2363 2.01702ZM19.6817 15.5821C19.6028 15.453 19.3946 15.375 19.082 15.2198C18.7688 15.0647 17.2302 14.3133 16.9442 14.2102C16.6573 14.1067 16.4482 14.0547 16.2399 14.3653C16.0316 14.6761 15.4319 15.375 15.249 15.5821C15.0664 15.7898 14.8841 15.8158 14.571 15.6604C14.2584 15.5052 13.2505 15.1771 12.0553 14.1197C11.1254 13.297 10.4974 12.2812 10.3148 11.9701C10.1325 11.6596 10.2957 11.4917 10.452 11.3372C10.593 11.1979 10.7652 10.9746 10.9214 10.7935C11.0783 10.612 11.1303 10.4829 11.234 10.2755C11.3389 10.0684 11.2866 9.88722 11.208 9.73151C11.13 9.57638 10.5037 8.04873 10.2431 7.42704C9.98256 6.80593 9.72227 6.90936 9.5394 6.90936C9.35711 6.90936 9.14824 6.88336 8.93967 6.88336C8.73109 6.88336 8.39193 6.96107 8.10507 7.27162C7.81849 7.58247 7.01047 8.33358 7.01047 9.86093C7.01047 11.3886 8.13107 12.8645 8.28793 13.0714C8.44422 13.2782 10.4517 16.5149 13.6324 17.758C16.8133 19.0005 16.8133 18.586 17.3871 18.534C17.9602 18.4822 19.2377 17.7831 19.4994 17.0586C19.7594 16.3329 19.7594 15.7115 19.6817 15.5821Z"
                      fill="white"
                    />
                  </svg>
                  <p className="wa__text">Whatsapp</p>
                </Button></a>
            </div>
          </Col>
        </Row>
      </Container>

      <Container id="gallery" className="mt-5 text-center mb-5 pb-5">
        <Row>
          <Col xs="12" md="12">
            <h3 className="coffee__h1 mt-5 mb-2">Gallery</h3>
            <p className="mb-4">
              Follow us on{' '}
              <a href="#instagram" style={{ color: '#FFBB24' }}>
                instagram
              </a>
            </p>
          </Col>
          <Col xs="12" md="12">
            <Row>
              {dataUrl.map((item, index) => {
                return (
                  <Col xs="6" md="3" className="plr-0" key={index}>
                    <img
                      src={item.url}
                      alt={item.url}
                      style={{ maxHeight: '285px' }}
                    />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Container>

      <div id="about" className="mt-5 home__bg-color pb-5">
        <div className="pb-5 text-center">
          <Col sm="12" md={{ size: 12 }} className="mt-5 pt-5 pb-5">
            <h3>About Us</h3>
            <p>
              To serve exceptional and unforgettable taste to each and every
              <br></br> one of our customers as our mission, we surely gonna
              assure<br></br> each cup served is the high quality kind of cup.
              Bringing the<br></br> name of Mediterania Group and EMJI SPACE, we
              are creating<br></br> a cozy and comfortable community place with
              affordable price.
            </p>
          </Col>
        </div>
      </div>

      <Container id="contact" className="text-center mt-5">
        <h3 className="space__contact">Contact</h3>
        <FormContact {...props} />
      </Container>
    </div>
  );
};

const mapStateToProps = state => ({
  member: state.member || {},
  instagramFeed: state.products.feed,
  allProduct: state.coffee.all,
});

const mapDispatchToProps = dispatch => ({
  sendBook: dispatch.book.sendBook,
  getInstagramFeed: dispatch.products.getInstagramFeed,
  getCoffee: dispatch.coffee.getCoffee,
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeCoffee);
