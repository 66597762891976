import { errorMessages } from '../constants/messages';
import { Firebase, FirebaseRef } from '../lib/firebase';
import axios from 'axios';
import config from '../constants/config';

export default {
  state: {}, // initial state

  /**
   * Reducers
   */
  reducers: {
    setUserLogin(state, payload) {
      const {
        token,
        email,
        id,
        phone_number,
        full_name,
        company,
        is_admin,
      } = payload;

      return {
        ...state,
        token,
        email,
        id,
        phone_number,
        full_name,
        company,
        is_admin,
      };
    },

    setUserDetails(state, payload) {
      const {
        token,
        email,
        id,
        phone_number,
        full_name,
        company,
        is_admin,
      } = payload;

      return {
        ...state,
        token,
        email,
        id,
        phone_number,
        full_name,
        company,
        is_admin,
      };
    },

    resetUser() {
      window.sessionStorage.removeItem('token');
      return {};
    },
  },

  /**
   * Effects/Actions
   */
  effects: dispatch => ({
    /**
     * Sign Up
     *
     * @param {obj} formData - data from form
     * @return {Promise}
     */
    signUp(formData) {
      const { email, password, full_name, company, phone_number } = formData;

      return new Promise(async (resolve, reject) => {
        // Validation rules
        if (!email) return reject({ message: errorMessages.missingEmail });

        axios
          .post(`${config.apiUrl}/api/v1/users/register`, {
            email,
            password,
            phone_number,
            full_name,
            company,
          })
          .then(res => {
            this.setUserLogin(res.data);
            this.setUserDetails(res.data);
            resolve();
          })
          .catch(reject);
      }).catch(err => {
        throw err.response.data.message;
      });
    },

    /**
     * Listen for realtime updates on the current user
     */
    listenForMemberProfileUpdates() {
      const UID =
        FirebaseRef &&
        Firebase &&
        Firebase.auth() &&
        Firebase.auth().currentUser &&
        Firebase.auth().currentUser.uid
          ? Firebase.auth().currentUser.uid
          : null;

      if (!UID) return false;

      const ref = FirebaseRef.child(`users/${UID}`);

      return ref.on('value', snapshot => {
        const userData = snapshot.val() || [];

        this.setUserDetails(userData); // Send to reducer
      });
    },

    /**
     * Get the current Member's Details
     *
     * @returns {Promise}
     */
    getMemberData(id) {
      const rt = id.token;
      let tkn = '';
      if (rt === undefined || rt === null) {
        tkn = window.sessionStorage.getItem('token');
      } else {
        tkn = id.token;
      }
      const headers = {
        'Content-Type': 'application/json',
        'x-access-token': tkn,
      };
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${config.apiUrl}/api/v1/users/current`, {
            id,
            headers,
          })
          .then(res => {
            if (res.data) {
              this.setUserLogin(res.data);
              window.sessionStorage.setItem('token', res.data.token);
              this.setUserDetails(res.data);
              resolve();
            } else {
              this.logout();
            }
          })
          .catch(reject => {
            window.sessionStorage.removeItem('token');
            this.logout();
            return reject;
          });
      });
    },

    /**
     * Login to Firebase with Email/Password
     *
     * @param {obj} formData - data from form
     * @return {Promise}
     */
    login(formData) {
      const { email, password } = formData;

      return new Promise(async (resolve, reject) => {
        // Validation rules
        if (!email) return reject({ message: errorMessages.missingEmail });

        axios
          .post(`${config.apiUrl}/api/v1/users/login`, {
            email,
            password,
          })
          .then(res => {
            this.setUserLogin(res.data);
            this.setUserDetails(res.data);
            resolve();
          })
          .catch(reject);
      }).catch(err => {
        throw err;
      });
    },

    /**
     * Reset Password
     *
     * @param {obj} formData - data from form
     * @return {Promise}
     */
    resetPassword(formData) {
      const { email } = formData;

      return new Promise(async (resolve, reject) => {
        // Validation rules
        if (!email) return reject({ message: errorMessages.missingEmail });

        // Go to Firebase
        return Firebase.auth()
          .sendPasswordResetEmail(email)
          .then(() => {
            this.resetUser();
            resolve();
          })
          .catch(reject);
      }).catch(err => {
        throw err.message;
      });
    },

    /**
     * Update Profile
     *
     * @param {obj} formData - data from form
     * @return {Promise}
     */
    updateProfile(formData, token) {
      const { email, id } = formData;
      const headers = {
        'Content-Type': 'application/json',
        'x-access-token': token.member.token,
      };
      const options = {
        method: 'PUT',
        headers: headers,
        data: formData,
        url: `${config.apiUrl}/api/v1/users/${id}`,
      };

      return new Promise(async (resolve, reject) => {
        // Validation rules
        if (!email) return reject({ message: errorMessages.missingEmail });
        axios(options)
          .then(res => {
            this.setUserLogin(res.data);
            this.setUserDetails(res.data);
            resolve();
          })
          .catch(reject);
      }).catch(err => {
        throw err.response.data.message;
      });
    },

    /**
     * Logout
     *
     * @returns {Promise}
     */
    logout() {
      this.resetUser();
      return;
    },
  }),
};
