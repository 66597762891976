import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';

import SidebarBO from '../components/Templates/SidebarBO';

const CreatePrivateRoute = ({
  comp,
  component,
  layout,
  title,
  metaDesc,
  auth,
  ...rest
}) => {
  let LayoutToRender = layout;
  return (
    <Route
      {...rest}
      render={props =>
        auth ? (
          <SidebarBO pageTitle={title} metaDesc={metaDesc}>
            <LayoutToRender {...props} />
          </SidebarBO>
        ) : (
          <Redirect
            to={{
              pathname: '/backoffice/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = state => ({ auth: state.admin.token });

export default withRouter(connect(mapStateToProps)(CreatePrivateRoute));
