import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Templates
import TemplateNothing from '../components/Templates/Nothing';
import TemplateSidebar from '../components/Templates/Sidebar';
import TemplateBack from '../components/Templates/SidebarBack';
import TemplateSidebarSpace from '../components/Templates/SidebarSpace';
import TemplateSidebarCoffe from '../components/Templates/SidebarCoffe';

// Routes
import Home from '../components/User/Home/index';
import HomeSpace from '../components/User/Space/Home/index';
import HomeCoffee from '../components/User/Coffee/Home/index';

// Backoffice
import HomeBO from '../components/Backoffice/Home';

import GalleryBO from '../components/Backoffice/Gallery';
import AmentiesBO from '../components/Backoffice/Amenties';

import ProductsBO from '../components/Backoffice/Products';
import CreateProductsBO from '../components/Backoffice/Product/Create';
import EditProductsBO from '../components/Backoffice/Product/Edit';
import DetailProductsBO from '../components/Backoffice/Product/Detail';

import CoffeesBO from '../components/Backoffice/Coffees';
import CreateCoffeesBO from '../components/Backoffice/Coffee/Create';
import EditCoffeesBO from '../components/Backoffice/Coffee/Edit';
import DetailCoffeesBO from '../components/Backoffice/Coffee/Detail';

import PromocodesBO from '../components/Backoffice/Promocode';
import CreatePromocodesBO from '../components/Backoffice/Promocode/Create';
import EditPromocodesBO from '../components/Backoffice/Promocode/Edit';
import DetailPromocodesBO from '../components/Backoffice/Promocode/Detail';

import LocationsBO from '../components/Backoffice/Locations';
import CreateLocationsBO from '../components/Backoffice/Location/Create';
import EditLocationsBO from '../components/Backoffice/Location/Edit';
import DetailLocationsBO from '../components/Backoffice/Location/Detail';

import TransactionBO from '../components/Backoffice/Transactions';

import MessageBO from '../components/Backoffice/Messages';
import DetailMessageBO from '../components/Backoffice/Message/Detail';

import PromoBO from '../components/Backoffice/Promocode';

import LocationSpace from '../components/User/Space/LocationDetail/index';
import ProductSpace from '../components/User/Space/ProductDetail/index';
import SharedSpace from '../components/User/Space/Shared/index';
import DedicatedSpace from '../components/User/Space/Dedicated/index';
import FunctionalSpace from '../components/User/Space/Functional/index';
import OfficeSpace from '../components/User/Space/Office/index';
import MeetingSpace from '../components/User/Space/Meeting/index';
import Booking from '../components/User/Booking';
import Dashboard from '../components/User/Dashboard';
import TransactionDetail from '../components/User/TransactionDetail';
import TransactionProcess from '../components/User/TransactionProcess';
import BandarCoffee from '../components/User/BandarCoffee/home/index';

import RecipesContainer from '../../containers/Recipes';
import RecipeListingComponent from '../components/Recipe/Listing';
import RecipeSingleComponent from '../components/Recipe/Single';

import SignUpContainer from '../../containers/SignUp';
import SignUpComponent from '../components/User/SignUp';

import LoginContainer from '../../containers/Login';
import LoginContainerBO from '../../containers/Backoffice/Login';
import LoginComponent from '../components/User/Login';
import LoginBO from '../components/Backoffice/Login';

import ForgotPasswordContainer from '../../containers/ForgotPassword';
import ForgotPasswordComponent from '../components/User/ForgotPassword';

import UpdateProfileContainer from '../../containers/UpdateProfile';
import UpdateProfileComponent from '../components/User/UpdateProfile';

import CreatePrivateRoute from '../utils/createPrivateRoute';
import UserPrivateRoute from '../utils/privateUser';

import Error from '../components/UI/Error';

const Index = () => (
  <Switch>
    <CreatePrivateRoute
      exact
      path="/backoffice"
      layout={HomeBO}
      title="BO Emji"
      comp="login"
    />
    <CreatePrivateRoute exact path="/backoffice/products" layout={ProductsBO} />
    <CreatePrivateRoute
      exact
      path="/backoffice/product/new"
      layout={CreateProductsBO}
    />
    <CreatePrivateRoute
      exact
      path="/backoffice/product/:productId/edit"
      layout={EditProductsBO}
    />
    <CreatePrivateRoute
      exact
      path="/backoffice/product/:productId"
      layout={DetailProductsBO}
    />

    <CreatePrivateRoute exact path="/backoffice/coffees" layout={CoffeesBO} />
    <CreatePrivateRoute
      exact
      path="/backoffice/coffee/new"
      layout={CreateCoffeesBO}
    />
    <CreatePrivateRoute
      exact
      path="/backoffice/coffee/:coffeeId/edit"
      layout={EditCoffeesBO}
    />
    <CreatePrivateRoute
      exact
      path="/backoffice/coffee/:coffeeId"
      layout={DetailCoffeesBO}
    />

<CreatePrivateRoute exact path="/backoffice/promocodes" layout={PromocodesBO} />
    <CreatePrivateRoute
      exact
      path="/backoffice/promocode/new"
      layout={CreatePromocodesBO}
    />
    <CreatePrivateRoute
      exact
      path="/backoffice/promocode/:promocodeId/edit"
      layout={EditPromocodesBO}
    />
    <CreatePrivateRoute
      exact
      path="/backoffice/promocode/:promocodeId"
      layout={DetailPromocodesBO}
    />

    <CreatePrivateRoute
      exact
      path="/backoffice/locations"
      layout={LocationsBO}
    />
    <CreatePrivateRoute
      exact
      path="/backoffice/location/:locationId"
      layout={DetailLocationsBO}
    />
    <CreatePrivateRoute
      exact
      path="/backoffice/locations/new"
      layout={CreateLocationsBO}
    />
    <CreatePrivateRoute
      exact
      path="/backoffice/location/:locationId/edit"
      layout={EditLocationsBO}
    />
    <CreatePrivateRoute exact path="/backoffice/gallery" layout={GalleryBO} />
    <CreatePrivateRoute exact path="/backoffice/amenties" layout={AmentiesBO} />
    <CreatePrivateRoute exact path="/backoffice/messages" layout={MessageBO} />
    <CreatePrivateRoute exact path="/backoffice/transactions" layout={TransactionBO} />
    <CreatePrivateRoute
      exact
      path="/backoffice/message/:id"
      layout={DetailMessageBO}
    />
    <CreatePrivateRoute exact path="/backoffice/promocodes" layout={PromoBO} />
    <Route
      path="/backoffice/login"
      render={props => (
        <TemplateNothing pageTitle="Backoffice - Login">
          <LoginContainerBO {...props} Layout={LoginBO} />
        </TemplateNothing>
      )}
    />
    <Route
      exact
      path="/"
      render={props => (
        <TemplateSidebar>
          <Home {...props} />
        </TemplateSidebar>
      )}
    />

    <Route
      exact
      path="/emji/bandar-coffee"
      render={props => (
        <TemplateSidebar>
          <BandarCoffee {...props} />
        </TemplateSidebar>
      )}
    />

    <UserPrivateRoute
      exact
      path="/dashboard"
      layout={TemplateSidebar}
      comp={Dashboard}
    />

    <UserPrivateRoute
      exact
      path="/transaction_detail/:id"
      layout={TemplateBack}
      comp={TransactionDetail}
    />

    <UserPrivateRoute
      exact
      path="/transaction_process/:id"
      layout={TemplateBack}
      comp={TransactionProcess}
    />

    {/* <Route
      
      render={props => (
        <TemplateSidebar>
          <Dashboard {...props} />
        </TemplateSidebar>
      )}
    /> */}

    <Route
      exact
      path="/emji/space"
      render={props => (
        <TemplateSidebarSpace pageTitle="Emji Space">
          <HomeSpace {...props} />
        </TemplateSidebarSpace>
      )}
    />
    <Route
      exact
      path="/emji/space/location/:id"
      render={props => (
        <TemplateSidebar pageTitle="Emji Space Kuningan">
          <LocationSpace {...props} />
        </TemplateSidebar>
      )}
    />
    <Route
      exact
      path="/emji/space/products/:id"
      render={props => (
        <TemplateBack pageTitle="Emji Space">
          <ProductSpace {...props} />
        </TemplateBack>
      )}
    />
    <Route
      exact
      path="/emji/space/shared-desk"
      render={props => (
        <TemplateSidebar pageTitle="Emji Space Shared Desk">
          <SharedSpace {...props} />
        </TemplateSidebar>
      )}
    />

    <Route
      exact
      path="/emji/space/dedicated-desk"
      render={props => (
        <TemplateSidebar pageTitle="Emji Space Dedicated Desk">
          <DedicatedSpace {...props} />
        </TemplateSidebar>
      )}
    />

    <Route
      exact
      path="/emji/space/functional-desk"
      render={props => (
        <TemplateSidebar pageTitle="Emji Space Functional">
          <FunctionalSpace {...props} />
        </TemplateSidebar>
      )}
    />

    <Route
      exact
      path="/emji/space/meeting-desk"
      render={props => (
        <TemplateSidebar pageTitle="Emji Space Meeting Room">
          <MeetingSpace {...props} />
        </TemplateSidebar>
      )}
    />

    <Route
      exact
      path="/emji/space/office-desk"
      render={props => (
        <TemplateSidebar pageTitle="Emji Space Private Office">
          <OfficeSpace {...props} />
        </TemplateSidebar>
      )}
    />

    <Route
      exact
      path="/booking"
      render={props => (
        <TemplateSidebar pageTitle="Book a Tour">
          <Booking {...props} />
        </TemplateSidebar>
      )}
    />

    <Route
      exact
      path="/emji/coffee"
      render={props => (
        <TemplateSidebarCoffe pageTitle="Emji Coffe">
          <HomeCoffee {...props} />
        </TemplateSidebarCoffe>
      )}
    />

    <Route
      path="/sign-up"
      render={props => (
        <TemplateNothing pageTitle="Sign Up">
          <SignUpContainer {...props} Layout={SignUpComponent} />
        </TemplateNothing>
      )}
    />
    <Route
      path="/login"
      render={props => (
        <TemplateNothing pageTitle="Login">
          <LoginContainer {...props} Layout={LoginComponent} />
        </TemplateNothing>
      )}
    />
    <Route
      path="/forgot-password"
      render={props => (
        <TemplateNothing pageTitle="Forgot Password">
          <ForgotPasswordContainer
            {...props}
            Layout={ForgotPasswordComponent}
          />
        </TemplateNothing>
      )}
    />
    <Route
      path="/update-profile"
      render={props => (
        <TemplateSidebar pageTitle="Update Profile">
          <UpdateProfileContainer {...props} Layout={UpdateProfileComponent} />
        </TemplateSidebar>
      )}
    />
    <Route
      path="/recipes"
      render={props => (
        <TemplateSidebar pageTitle="Recipes">
          <RecipesContainer {...props} Layout={RecipeListingComponent} />
        </TemplateSidebar>
      )}
    />
    <Route
      path="/recipe/:id"
      render={props => (
        <TemplateSidebar pageTitle="Recipe View">
          <RecipesContainer {...props} Layout={RecipeSingleComponent} />
        </TemplateSidebar>
      )}
    />
    <Route
      render={props => (
        <TemplateSidebar pageTitle="404 - Page not found">
          <Error
            {...props}
            title="404"
            content="Sorry, the route you requested does not exist"
          />
        </TemplateSidebar>
      )}
    />
  </Switch>
);

export default Index;
