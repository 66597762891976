import { errorMessages } from '../constants/messages';
import { Firebase, FirebaseRef } from '../lib/firebase';
import axios from 'axios';
import config from '../constants/config'

export default {
  state: {}, // initial state

  /**
   * Reducers
   */
  reducers: {
    setUserLogin(state, payload) {
      const {
        token,
        email,
        id,
        phone_number,
        full_name,
        company,
        is_admin,
      } = payload;

      return {
        ...state,
        token,
        email,
        id,
        phone_number,
        full_name,
        company,
        is_admin,
      };
    },

    setUserDetails(state, payload) {
      const {
        token,
        email,
        id,
        phone_number,
        full_name,
        company,
        is_admin,
      } = payload;

      return {
        ...state,
        token,
        email,
        id,
        phone_number,
        full_name,
        company,
        is_admin,
      };
    },

    resetUser() {
      return {};
    },
  },

  /**
   * Effects/Actions
   */
  effects: dispatch => ({
    /**
     * Sign Up
     *
     * @param {obj} formData - data from form
     * @return {Promise}
     */
    signUp(formData) {
      const { email, password, full_name, company, phone_number } = formData;

      return new Promise(async (resolve, reject) => {
        // Validation rules
        if (!email) return reject({ message: errorMessages.missingEmail });

        axios
          .post(`${config.apiUrl}/api/v1/users/register`, {
            email,
            password,
            phone_number,
            full_name,
            company,
          })
          .then(res => {
            this.setUserLogin(res.data);
            this.setUserDetails(res.data);
            resolve();
          })
          .catch(reject);
      }).catch(err => {
        throw err.response.data.message;
      });
    },

    /**
     * Listen for realtime updates on the current user
     */
    // listenForMemberProfileUpdates() {
    //   const UID =
    //     FirebaseRef &&
    //     Firebase &&
    //     Firebase.auth() &&
    //     Firebase.auth().currentUser &&
    //     Firebase.auth().currentUser.uid
    //       ? Firebase.auth().currentUser.uid
    //       : null;

    //   if (!UID) return false;

    //   const ref = FirebaseRef.child(`users/${UID}`);

    //   return ref.on('value', snapshot => {
    //     const userData = snapshot.val() || [];

    //     this.setUserDetails(userData); // Send to reducer
    //   });
    // },

    /**
     * Get the current Member's Details
     *
     * @returns {Promise}
     */
    getAdminData(id) {
      // Ensure token is up to date
      const headers = {
        'Content-Type': 'application/json',
        'x-access-token': id.token,
      };
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${config.apiUrl}/api/v1/users/current`, {
            id,
            headers,
          })
          .then(res => {
            if (res.data.is_admin) {
              this.setUserLogin(res.data);
              window.sessionStorage.setItem('token', res.data.token);
              this.setUserDetails(res.data);
              resolve();
            } else {
              this.logout();
            }
          })
          .catch(err => this.logout());
      });
    },

    /**
     * Login to Firebase with Email/Password
     *
     * @param {obj} formData - data from form
     * @return {Promise}
     */

    login(formData) {
      const { email, password } = formData;

      return new Promise(async (resolve, reject) => {
        // Validation rules
        if (!email) return reject({ message: errorMessages.missingEmail });

        axios
          .post(`${config.apiUrl}/api/v1/users/login`, {
            email,
            password,
          })
          .then(res => {
            this.setUserLogin(res.data);
            this.setUserDetails(res.data);
            resolve();
          })
          .catch(reject);
      }).catch(err => {
        throw err.response.data.message;
      });
    },

    /**
     * Reset Password
     *
     * @param {obj} formData - data from form
     * @return {Promise}
     */
    resetPassword(formData) {
      const { email } = formData;

      return new Promise(async (resolve, reject) => {
        // Validation rules
        if (!email) return reject({ message: errorMessages.missingEmail });

        // Go to Firebase
        return Firebase.auth()
          .sendPasswordResetEmail(email)
          .then(() => {
            this.resetUser();
            resolve();
          })
          .catch(reject);
      }).catch(err => {
        throw err.message;
      });
    },

    /**
     * Update Profile
     *
     * @param {obj} formData - data from form
     * @return {Promise}
     */
    updateProfile(formData) {
      const {
        email,
        password,
        password2,
        full_name,
        company,
        changeEmail,
        changePassword,
      } = formData;

      return new Promise(async (resolve, reject) => {
        // Are they a user?
        const UID = await Firebase.auth().currentUser.uid;
        if (!UID) return reject({ message: errorMessages.memberNotAuthd });

        // Validation rules
        if (!full_name)
          return reject({ message: errorMessages.missingFirstName });
        if (!company)
          return reject({ message: errorMessages.missingLastName });
        if (changeEmail) {
          if (!email) return reject({ message: errorMessages.missingEmail });
        }
        if (changePassword) {
          if (!password)
            return reject({ message: errorMessages.missingPassword });
          if (!password2)
            return reject({ message: errorMessages.missingPassword });
          if (password !== password2)
            return reject({ message: errorMessages.passwordsDontMatch });
        }

        // Go to Firebase
        return FirebaseRef.child(`users/${UID}`)
          .update({ full_name, company })
          .then(async () => {
            // Update Email address
            if (changeEmail) {
              await Firebase.auth()
                .currentUser.updateEmail(email)
                .catch(reject);
            }

            // Change the Password
            if (changePassword) {
              await Firebase.auth()
                .currentUser.updatePassword(password)
                .catch(reject);
            }

            return resolve();
          })
          .catch(reject);
      }).catch(err => {
        throw err.message;
      });
    },

    /**
     * Logout
     *
     * @returns {Promise}
     */
    logout() {
      this.resetUser();
      return;
    },
  }),
};
