import React from 'react';
import './index.css';
import { Container } from 'reactstrap'
import coming from '../images/coming-soon.png';

const BandarCoffee = () => {
  return (
    <div>
      <Container>
        <img src={coming} width="100%" alt="Coming Soon Banda Coffe"/>
      </Container>
    </div>
  );
};

export default BandarCoffee;