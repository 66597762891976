import React from 'react';
import { Row, Jumbotron } from 'reactstrap';

const HomeBO = () => (
  <div className="text-center">
    <Row>
      <Jumbotron className="bg-dark text-center mt-5">
        <h1 className="text-white">Emji Backoffice</h1>
      </Jumbotron>
    </Row>
  </div>
);

export default HomeBO;
