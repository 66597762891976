import React from "react";
import { Container, Row, Col, FormGroup, Input, Button } from 'reactstrap';

const Booking =  () => {
    return (
      <div>
        <Container>
          <h1>Book a Tour</h1>
          <p>Want to take a peek and see our space? Just fill out the form below and we'll lock in a date.</p>
          <Row>
          <Col sm="3" md="4">
          <FormGroup>
          <Input type="text" name="email" id="nama" placeholder="Name / Company" />
      </FormGroup>
          </Col>
          <Col sm="12" md="4">
          <FormGroup>
          <Input type="email" name="email" id="email" placeholder="Email" />
      </FormGroup>
          </Col>
          <Col sm="3" md="4">
          <FormGroup>
          <Input type="number" name="notelp" id="notelp" placeholder="Mobile Phone" />
      </FormGroup>
          </Col>
        </Row>
        <Row>
        <Col sm="6" md="4">
          <FormGroup>
        <Input
          type="date"
          name="date"
          id="exampleDate"
          placeholder="date placeholder"
        />
      </FormGroup>
          </Col>
          <Col sm="6" md="4">
          <FormGroup>
        <Input
          type="time"
          name="time"
          id="exampleTime"
          placeholder="time placeholder"
        />
      </FormGroup>
          </Col>
          <Col sm="6" md="4">
            <FormGroup>
              <Input type="select" name="select" id="exampleSelect">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm="6" md="6">
          <FormGroup>
        <Input
          type="number"
          name="number"
          id="exampleTime"
          placeholder="I need space for"
        />
      </FormGroup>
          </Col>
          <Col sm="6" md="6">
          <FormGroup>
        <Input
          type="date"
          name="date"
          id="exampleDate"
          placeholder="date placeholder"
        />
      </FormGroup>
          </Col>
        </Row>
        <FormGroup>
        <Input type="textarea" name="text" id="exampleText" placeholder="Message"/>
      </FormGroup>
      <Col sm="12" md="12" className="text-right">
          <Button style={{backgroundColor: 'black' }} >SEND</Button>
          </Col>
        </Container>
      </div>
    )
}

export default Booking;