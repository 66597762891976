import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Nav,
  Navbar,
  Collapse,
  NavbarToggler,
  NavbarBrand,
  NavItem,
  NavLink,
  Container,
  Button,
} from 'reactstrap';
import { withRouter,Link } from 'react-router-dom';
import "../Landing/Header.css";
import img1 from '../../../../images/coffee-logo.png';
import './HeaderCoffee.css';


class Header extends Component {
  static propTypes = {
    member: PropTypes.shape({
      firstName: PropTypes.string,
      email: PropTypes.string,
    }),
    logout: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    member: {},
  };

  constructor(props) {
    super(props);

    this.toggleDropDown = this.toggleDropDown.bind(this);
    this.state = { isOpen: false };
  }

  onLogout = () => {
    const { logout, history } = this.props;
    logout().then(() => history.push('/login'));
  };

  toggleDropDown = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  render() {
    const { isOpen } = this.state;
    return (
      <header>
        <div>
          <Navbar color="light" light expand="md">
            <Container>
              <NavbarBrand href="/">
                <img src={img1} alt="emji-logo" />
              </NavbarBrand>
              <NavbarToggler onClick={this.toggleDropDown} />
              <Collapse isOpen={isOpen} navbar>
                <Nav className="ml-auto mt-2" navbar>
                  <NavItem>
                    <NavLink href="#product" className="nav__item header__coffee-link">
                      PRODUCT
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#coffee_shop" className="nav__item header__coffee-link">
                      COFFEE SHOP
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#partnership" className="nav__item header__coffee-link">
                      PARTNERSHIP
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#gallery" className="nav__item header__coffee-link">
                      GALLERY
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#about" className="nav__item header__coffee-link">
                      ABOUT US
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#contact" className="nav__item header__coffee-link">
                      CONTACT
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#" className="nav__item pt-md-0 ml-md-3">
                    <Link to="/">
                    <Button className="btn__back-coffee">Back to Home</Button>
                    </Link>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </Container>
          </Navbar>
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
