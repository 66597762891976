import React, { Component } from 'react';
import { Table, Container, Row, Col, Input, Button } from 'reactstrap';
import moment from 'moment';

import { connect } from 'react-redux';
import './index.css';
import { withRouter } from 'react-router-dom';

// function isEmpty(obj) {
//   return Object.keys(obj).length === 0;
// }

function formatMoney(amount, decimalCount = 0, decimal = ',', thousands = '.') {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
  }
}

const DetailTransactionTable = props => {
  const pd = props.promoData;
  let type, discount, total_discount, total_amount;
  if (pd !== null && pd !== undefined) {
    type = pd.type;
    switch (type) {
      case 'percentage':
        discount = pd.percentage;
        break;
      case 'cut_price':
        discount = pd.cut_price;
        break;

      default:
        console.log('not found');
        break;
    }
    total_discount = (parseInt(discount) / 100) * props.detailTransaction.price;
    total_amount = props.detailTransaction.price - total_discount;
    setTimeout(() => {
      props.handlePromo(total_amount);
    }, 500);
  }
  return (
    <Table hover responsive className="transaction__detail mt-5">
      <thead>
        <tr>
          <th className="td__head">Space</th>
          <th className="text-center d-none td__head d-md-table-cell">Plans</th>
          <th className="text-center d-none td__head d-md-table-cell">
            Quantity
          </th>
          <th className="text-center d-none td__head d-md-table-cell">Price</th>
          <th className="text-right pl-5 td__head">Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="td__detail">{props.detailTransaction.product_name}</td>
          <td className="text-center td__detail d-none td__head d-md-table-cell">
            {props.detailTransaction.plan}
          </td>
          <td className="text-center td__detail d-none td__head d-md-table-cell">
            {props.detailTransaction.quantity}
          </td>
          <td className="text-center td__detail d-none td__head d-md-table-cell">
            Rp. {formatMoney(props.detailTransaction.price)}
          </td>
          <td className="text-right td__detail">
            Rp. {formatMoney(props.detailTransaction.price)}
          </td>
        </tr>
        <tr>
          <td className="btb-0 d-none td__head d-md-table-cell"></td>
          <td className="text-center btb-0 d-none td__head d-md-table-cell"></td>
          <td className="text-center btb-0 d-none td__head d-md-table-cell"></td>
          <td className="text-right btb-0 td__total pt-5">Total</td>
          <td className="text-right btb-0 td__total pt-5">
            Rp. {formatMoney(props.detailTransaction.price)}
          </td>
        </tr>
        <tr>
          <td className="btb-0 d-none td__head d-md-table-cell"></td>
          <td className="text-center btb-0 d-none td__head d-md-table-cell"></td>
          <td className="text-center btb-0 d-none td__head d-md-table-cell"></td>
          <td className="text-right td__detail btb-0 pt-2 pb-2">Discount</td>
          <td className="text-right td__detail btb-0 pt-2 pb-2">
            {props.usePromo ? (
              <span>Rp. {formatMoney(total_discount)} </span>
            ) : (
              <span>Rp. 0</span>
            )}{' '}
          </td>
        </tr>
        <tr>
          <td className="btb-0 d-none td__head d-md-table-cell"></td>
          <td className="text-center btb-0 d-none td__head d-md-table-cell"></td>
          <td className="text-center btb-0 d-none td__head d-md-table-cell"></td>
          <td
            className="text-right td__detail btb-0 pt-2 pb-2 td__a-total"
            style={{ minWidth: '160px' }}
          >
            AMOUNT TOTAL
          </td>
          <td className="text-right td__detail btb-0 pt-2 pb-2 td__a-total">
            Rp.{' '}
            {formatMoney(
              total_amount ? total_amount : props.detailTransaction.price
            )}
          </td>
        </tr>
        <tr>
          <td className="btb-0 d-none td__head d-md-table-cell"></td>
          <td className="text-center btb-0 d-none td__head d-md-table-cell"></td>
          <td className="text-center btb-0 d-none td__head d-md-table-cell"></td>
          {props.usePromo ? (
            <td className="text-right td__detail btb-0 pt-4 pb-2"></td>
          ) : (
            <td className="text-right td__detail btb-0 pt-4 pb-2">
              Have a promo code?
            </td>
          )}
          {props.usePromo ? (
            <td className="text-right td__detail btb-0 pt-4 pb-2">
              Code Promo Applied ={' '}
              {props.promo ? props.promo.toUpperCase() : null}
              <div className="text-right">
                <Button
                  type="button"
                  outline
                  size="sm"
                  className="mt-1"
                  color="danger"
                  onClick={() => {
                    props.cancelPromo('error');
                  }}
                >
                  Cancel
                </Button>
              </div>
            </td>
          ) : (
            <td className="text-left td__detail btb-0 pt-4 pb-2">
              <Input
                type="text"
                name="promo"
                id="promo"
                onChange={props.handleChange}
                placeholder="Promo code"
                bsSize="sm"
                style={{ width: '100%' }}
              />
              <div className="text-right">
                <Button
                  type="button"
                  outline
                  size="md"
                  className="mt-1"
                  color="primary"
                  onClick={props.checkPromo}
                >
                  Apply
                </Button>
              </div>
            </td>
          )}
        </tr>
      </tbody>
    </Table>
  );
};

class TransactionDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isEdit: false,
      promo: null,
      usePromo: false,
      id: this.props.member.id,
      order_id: '',
    };

    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.checkPromo = this.checkPromo.bind(this);
    this.handlePromo = this.handlePromo.bind(this);
  }

  componentDidMount() {
    this.props.getDetailTransaction(window.location.pathname.split('/')[2]);
    this.cancelPromo();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ order_id: nextProps.detailTransaction.order_id });
  }

  check = async data => {
    const { checkPromocode } = this.props;
    if (this.state.promo === null) {
      return;
    } else if (data) {
      let exist = false;
      checkPromocode(data.toUpperCase())
        .then(res => {
          if (res.data.rowCount !== 0) {
            exist = true;
            if (exist) {
              this.setState({ usePromo: true, promoData: res.data.rows[0] });
              console.log('there a promo');
            }
          } else {
            this.setState({ usePromo: false, promoData: null });
            console.log('oops no promo');
          }
        })
        .catch(error => {
          console.log(error);
        });
      this.setState({ promo: null });
    } else {
      let exist = false;
      checkPromocode(this.state.promo.toUpperCase())
        .then(res => {
          if (res.data.rowCount !== 0) {
            exist = true;
            if (exist) {
              this.setState({ usePromo: true, promoData: res.data.rows[0] });
            }
          } else {
            this.setState({ usePromo: false, promoData: null });
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  pay = async data => {
    const { updateTransaction, history } = this.props;
    updateTransaction(this.state)
      .then(res => {
        if (res.data.status !== null) {
          history.push(`/transaction_process/${this.state.order_id}`);
        }
        // if (res.data.rowCount !== 0) {
        //   exist = true;
        //   if (exist) {
        //     this.setState({ usePromo: true, promoData: res.data.rows[0] });
        //     console.log('there a promo');
        //   }
        // } else {
        //   this.setState({ usePromo: false, promoData: null });
        //   console.log('oops no promo');
        // }
      })
      .catch(error => {
        console.log(error);
      });
  };

  checkPromo = () => {
    if (this.state.promo === null) {
      return;
    } else {
      this.check();
    }
  };

  handlePay = () => {
    this.pay();
  };

  cancelPromo = () => {
    if (this.state.promo === null) {
      return;
    } else {
      this.check('cancel');
    }
  };

  toggleEdit = () => {
    const { isEdit } = this.state;
    this.setState({ isEdit: !isEdit });
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handlePromo = data => this.setState({ price: data });

  render() {
    return (
      <div>
        <Container className="text-left">
          <h3 className="dashboard__heading">Detail Order</h3>
          <Row>
            <Col xs="12">
              <p className="mb-0">
                Order ID {this.props.detailTransaction.order_id}
              </p>
              <p className="mb-0">
                Date order{' '}
                {moment(this.props.detailTransaction.date).format('DD/MM/YYYY')}
              </p>
            </Col>
          </Row>
          <Row>
            <DetailTransactionTable
              {...this.props}
              {...this.state}
              checkPromo={this.checkPromo}
              cancelPromo={this.cancelPromo}
              handleChange={this.handleChange}
              handlePromo={this.handlePromo}
            />
            <Col xs={12}>
              <div className="text-right mt-4">
                <Button
                  // type="button"
                  className="p-3 pr-4 pl-4"
                  size="sm"
                  onClick={this.handlePay}
                  style={{
                    background: '#000000',
                    fontWeight: 'bold',
                    fontSize: '14px',
                    lineHeight: '18px',

                    color: '#FFFFFF',
                  }}
                >
                  PAY NOW
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
  allPromocode: state.promocode || {},
  detailPromocode: state.promocode.detailPromocode || {},
  detailTransaction: state.transaction.detailTransaction || {},
});

const mapDispatchToProps = dispatch => ({
  fetchPromocode: dispatch.promocode.getPromocode,
  getDetailTransaction: dispatch.transaction.getDetailTransaction,
  updateTransaction: dispatch.transaction.updateTransaction,
  checkPromocode: dispatch.promocode.checkPromocode,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TransactionDetail));
