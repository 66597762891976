import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet';
import Admin from '../../../containers/Backoffice/Admin';
import Header from '../UI/Backoffice/Header';
import { Sidebar } from '../UI/Backoffice/Sidebar';

const Template = ({ pageTitle, children }) => (
  <div>
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>

    <Admin Layout={Header} />
    <Container fluid>
      <Row>
        <Sidebar />
        <Col md="10" sm="9" className="px-sm-5 py-sm-5 ml-sm-auto">
          {children}
        </Col>
      </Row>
    </Container>
  </div>
);

Template.propTypes = {
  pageTitle: PropTypes.string,
  children: PropTypes.element.isRequired,
};

Template.defaultProps = {
  pageTitle: 'Emji Backoffice',
};

export default Template;
