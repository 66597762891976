import React from 'react';
import {
  Row,
  Col,
  Card,
  CardText,
  CardBody,
  CardTitle,
  Button,
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// function isEmpty(obj) {
//   return Object.keys(obj).length === 0;
// }

class DetailMessage extends React.Component {
  static defaultProps = {
    error: null,
    success: null,
    member: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      pName: '',
      message_type: [],
      price_type: [],
      pt_form: [],
      description: '',
      benefits: [],
      image: [],
      benefit: false,
    };

    this.fethcData = this.fethcData.bind(this);
  }
  componentDidMount() {
    this.fethcData();
  }

  fethcData() {
    this.props.getMessage(this.props.match.params.id);
  }

  render() {
    const { detailMessages } = this.props;
    return (
      <div>
        <Row className="pt-5">
          <Col lg={{ size: 12 }}>
            <Card>
              <Row>
                
              </Row>

              <CardBody>
                <CardTitle>Name / Company : <br/>{detailMessages.name}</CardTitle>
                <hr />
                <CardText>Email : <br/>{detailMessages.email}</CardText>
                <hr />
                <CardText>Phone : <br/>{detailMessages.phone}</CardText>
                <hr />
                <CardText>Message : <br/>{detailMessages.message}</CardText>
              </CardBody>
            </Card>
            <Row className="col-xs-12 mt-3">
              <Col xs={{ size: 12 }} className="text-right">
                <Link to="/backoffice/messages">
                  <Button size="sm" color="warning">
                    Back
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  detailMessages: state.book.detailMessages || {},
});

const mapDispatchToProps = dispatch => ({
  getMessage: dispatch.book.getDetailMessages,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DetailMessage));

// export default withRouter(DetailMessage);
