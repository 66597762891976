import React, { useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import Amenties from '../../../containers/Backoffice/Amenties';
import { Table } from 'reactstrap';
// import config from '../../../constants/config';
import Skeleton from 'react-loading-skeleton';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from 'reactstrap';

function isEmpty(obj) {
  if (obj === 'null') {
    return true;
  } else if (obj === undefined) {
    return true;
  } else {
    return Object.keys(obj).length === 0;
  }
}

const InputAmenties = props => {
  return (
    <div className="row mb-2">
      <div className="col-3">
        <Label>{props.name}</Label>
      </div>
      <div className="col-9">
        <Input
          type="text"
          style={{ height: '30px' }}
          value={props.quota}
          onChange={e =>
            props.handleChange({ id: props.id, value: e.target.value })
          }
          name="quota"
        />
      </div>
      <div className="col-3"></div>
      <div className="col-9 mt-2">
        <Button
          size="sm"
          color="primary"
          outline
          className="mr-2"
          onClick={() => props.onUpdate(props.id)}
        >
          Update
        </Button>
        <Button
          size="sm"
          color="danger"
          outline
          onClick={() => props.onDelete(props.id)}
        >
          Remove
        </Button>
      </div>
    </div>
  );
};

function changeQuota(value, id, data) {
  for (var i in data) {
    if (data[i].id === id) {
      data[i].quota = value;
      break; //Stop this loop, we found it!
    }
  }
  return data;
}

function changeName(value, id, data) {
  if (data) {
    for (var i in data) {
      if (data[i].id === id) {
        data[i].name = value;
        break;
      }
    }
  } else {
    return data;
  }
  return data;
}

class UserAmenties extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userAmenties: null,
      addAmenties: false,
      newAmenties: { name: '', quota: 0 },
    };
    this.getAmentiesUser = this.getAmentiesUser.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeNew = this.changeNew.bind(this);
  }

  componentDidMount() {
    this.getAmentiesUser(this.props.user_id);
  }

  deleteUserAmenties = id => {
    this.setState({ loading: true });
    this.props.deleteAmenties(id).then(res => {
      if (res) {
        this.setState({ loading: false });
        this.getAmentiesUser(this.props.user_id);
      }
      this.setState({ loading: false });
    });
  };

  updateUserAmenties = id => {
    const { userAmenties } = this.state;
    this.setState({ loading: true });
    let data = userAmenties.filter(item => {
      return item.id === id;
    });
    console.log(id, data[0]);
    this.props.updateAmenties(data[0]).then(res => {
      if (res) {
        this.setState({ loading: false });
        this.getAmentiesUser(this.props.user_id);
      }
      this.setState({ loading: false });
    });
  };

  addAmenties = () => {
    const { addAmenties } = this.state;
    this.setState({ addAmenties: !addAmenties });
  };

  createAmenties = () => {
    this.setState({ loading: true });
    const { user_id, createNewAmenties } = this.props;
    const { newAmenties } = this.state;
    let formData = {
      name: newAmenties.name,
      user_id: user_id,
      quota: newAmenties.quota,
    };
    createNewAmenties(formData).then(res => {
      if (res) {
        this.setState({ loading: false });
        this.setState({ addAmenties: false });
        this.setState({ newAmenties: { name: '', quota: 0 } });
        this.getAmentiesUser(this.props.user_id);
      }
    });
  };

  handleChange = e => {
    var aa = changeQuota(e.value, e.id, this.state.userAmenties);
    this.setState({ userAmenties: aa });
  };

  handleChangeName = e => {
    var aa = changeName(e.value, e.id, this.state.userAmenties);
    this.setState({ userAmenties: aa });
  };

  changeNew = e => {
    let data = this.state.newAmenties;
    if (e.target.name === 'name') {
      data.name = e.target.value;
    } else {
      data.quota = e.target.value;
    }
    this.setState({ newAmenties: data });
  };

  getAmentiesUser = user_id => {
    this.setState({ loading: true });
    this.props.fetchAmentiesBU(user_id).then(res => {
      if (res.length !== 0) {
        this.setState({ userAmenties: res });
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false });
      }
    });
  };

  render() {
    if (this.state.loading) {
      return (
        <div>
          <ModalBody>
            <Skeleton height="200px" />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.props.toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </div>
      );
    }
    if (this.state.userAmenties === null) {
      return (
        <div>
          <p className=" pb-0 p-3 mb-0">User ID: {this.props.user_id}</p>
          {this.state.addAmenties ? (
            <div className="row px-5 mb-3">
              <div className="col-12 mb-2">
                <span>Name</span>
                <Input
                  type="text"
                  value={this.state.newAmenties.name}
                  onChange={this.changeNew}
                  name="name"
                />
              </div>
              <div className="col-12">
                <span>Quota</span>
                <Input
                  type="number"
                  value={this.state.newAmenties.quota}
                  onChange={this.changeNew}
                  name="quota"
                />
              </div>
              <div className="col-12 text-right mt-3">
                <Button
                  size="sm"
                  color="primary"
                  outline
                  onClick={this.createAmenties}
                >
                  Add
                </Button>
                <Button
                  size="sm"
                  color="danger"
                  className="ml-3"
                  outline
                  onClick={this.addAmenties}
                >
                  Cancel
                </Button>
              </div>
            </div>
          ) : (
            <p className=" pb-0 p-3 mb-0">
              <Button
                size="sm"
                color="primary"
                outline
                onClick={this.addAmenties}
              >
                Add Amenties
              </Button>
            </p>
          )}
          <hr className="m-0" />
          <ModalBody>No Amenties Yet</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.props.toggleModal}>
              Done
            </Button>
          </ModalFooter>
        </div>
      );
    }
    if (this.state.userAmenties !== null) {
      return (
        <div>
          <p className=" pb-0 p-3 mb-0">User ID: {this.props.user_id}</p>
          {this.state.addAmenties ? (
            <div className="row px-5 mb-3">
              <div className="col-12 mb-2">
                <span>Name</span>
                <Input
                  type="text"
                  value={this.state.newAmenties.name}
                  onChange={this.changeNew}
                  name="name"
                />
              </div>
              <div className="col-12">
                <span>Quota</span>
                <Input
                  type="number"
                  value={this.state.newAmenties.quota}
                  onChange={this.changeNew}
                  name="quota"
                />
              </div>
              <div className="col-12 text-right mt-3">
                <Button
                  size="sm"
                  color="primary"
                  outline
                  onClick={this.createAmenties}
                >
                  Add
                </Button>
                <Button
                  size="sm"
                  color="danger"
                  className="ml-3"
                  outline
                  onClick={this.addAmenties}
                >
                  Cancel
                </Button>
              </div>
            </div>
          ) : (
            <p className=" pb-0 p-3 mb-0">
              <Button
                size="sm"
                color="primary"
                outline
                onClick={this.addAmenties}
              >
                Add Amenties
              </Button>
            </p>
          )}
          <hr className="m-0" />
          <ModalBody>
            {this.state.userAmenties.map((item, index) => {
              return (
                <div key={index}>
                  <InputAmenties
                    {...item}
                    onUpdate={this.updateUserAmenties}
                    onDelete={this.deleteUserAmenties}
                    handleChange={this.handleChange}
                    handleChangeName={this.handleChangeName}
                  />
                </div>
              );
            })}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.props.toggleModal}>
              Done
            </Button>
          </ModalFooter>
        </div>
      );
    }
  }
}

const ModalUpload = props => {
  return (
    <div>
      <Modal isOpen={props.modal} toggle={props.toggleModal}>
        <ModalHeader toggle={props.toggleModal}>Detail User</ModalHeader>
        <UserAmenties {...props} toggleModal={props.toggleModal} />
      </Modal>
    </div>
  );
};

const Page = props => {
  useEffect(() => {
    props.fetchAmenties();
  }, []);
  return (
    <div>
      <Row className="pt-5">
        <Col lg={6} xs={12}>
          <h1>Amenties page</h1>
        </Col>
        <Col lg={6} xs={12} className="text-right">
          <ModalUpload {...props} toggleModal={props.toggleModal} />
        </Col>

        <Table hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th className="text-right">Action</th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(props.allAmenties) &&
              props.allAmenties.map((item, index) => {
                return (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{item.full_name}</td>
                    <td
                      style={{
                        verticalAlign: 'middle',
                        textAlign: 'right',
                        minWidth: '25vh',
                      }}
                    >
                      <Button
                        size="sm"
                        outline
                        color="primary"
                        onClick={() => props.toggleModal(item.id)}
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Row>
    </div>
  );
};

class AmentiesBO extends React.Component {
  constructor(props) {
    super(props);
    this.state = { modal: false };
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    const { history } = this.props;
    if (history.location.search !== '') {
      let user_id = history.location.search.replace('?', '');
      this.toggleModal(user_id);
    }
  }

  toggleModal = id => {
    const { modal } = this.state;
    this.setState({ user_id: id });
    return this.setState({ modal: !modal });
  };

  render() {
    return (
      <div>
        {this.state.modal ? (
          <ModalUpload
            {...this.props}
            {...this.state}
            toggleModal={this.toggleModal}
            user_id={this.state.user_id}
          />
        ) : null}
        <Amenties
          {...this.props}
          Layout={Page}
          toggleModal={this.toggleModal}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  allAmenties: state.amenties.amentiesAll || {},
});

const mapDispatchToProps = dispatch => ({
  fetchAmentiesBU: dispatch.amenties.fetchAmentiesBU,
  fetchAmenties: dispatch.amenties.fetchAmenties,
  createNewAmenties: dispatch.amenties.createAmenties,
  deleteAmenties: dispatch.amenties.deleteAmenties,
  updateAmenties: dispatch.amenties.updateAmenties,
});

export default connect(mapStateToProps, mapDispatchToProps)(AmentiesBO);
