import initState from '../store/recipes';
import axios from 'axios';
import config from '../constants/config';

export default {
  /**
   *  Initial state
   */
  state: {
    promocode: initState.promocode,
  },

  /**
   * Reducers
   */
  reducers: {
    replacePromocode(state, payload) {
      let all = [];
      // Pick out the props I need
      if (payload && typeof payload === 'object') {
        all = payload.rows.map(item => ({
          id: item.id,
          type: item.type,
          code: item.code,
          quantity: item.quantity,
          percentage: item.percentage,
          cut_price: item.cut_price,
        }));
      }

      return { ...state, all };
    },
    replaceDetailPromocode(state, payload) {
      let detailPromocode = [];
      // Pick out the props I need
      if (payload && typeof payload === 'object') {
        detailPromocode = payload;
      }
      return { ...state, detailPromocode };
    },

    replaceCheckPromocode(state, payload,c) {
      let checkPromocode = [];
      // Pick out the props I need
      if (payload && typeof payload === 'object') {
        checkPromocode = payload;
      }
      return  { ...state, checkPromocode };
    },
  },

  /**
   * Effects/Actions
   */
  effects: () => ({
    /**
     * Get All Promocode
     *
     * @return {Promise}
     */
    getPromocode() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${config.apiUrl}/api/v1/promocode/`)
          .then(res => {
            this.replacePromocode(res.data);
            resolve();
          })
          .catch(reject);
      });
    },
    /**
     * Get All Promocode
     *
     * @return {Promise}
     */
    getDetailPromocode(code) {
      let promo = new Promise(async (resolve, reject) => {
        axios
          .get(`${config.apiUrl}/api/v1/promocode/${code}`)
          .then(response => {
            this.replaceDetailPromocode(response.data);
            resolve(response);
            return response.data;
          })
          .catch(reject);
      });
      return promo;
    },

    checkPromocode(code) {
      let promo = new Promise((resolve, reject) => {
        axios
          .get(`${config.apiUrl}/api/v1/promocode/${code}`)
          .then(res => {
            this.replaceCheckPromocode(res.data);
            resolve(res);
          })
          .catch(reject);
      });
      return promo;
    },

    /**
     * Create New Promocode
     *
     * @return {Promise}
     */
    createPromocode(formData) {
      return new Promise(async (resolve, reject) => {
        axios
          .post(`${config.apiUrl}/api/v1/promocode/`, formData)
          .then(res => {
            // this.replacePromocode(res.data);
            resolve();
          })
          .catch(reject);
      });
    },
    /**
     * Create New Promocode
     *
     * @return {Promise}
     */
    updatePromocode(formData) {
      return new Promise(async (resolve, reject) => {
        axios
          .put(`${config.apiUrl}/api/v1/promocode/${formData.id}`, formData)
          .then(res => {
            resolve();
          })
          .catch(reject);
      });
    },
    /**
     * Delete Promocode
     *
     * @return {Promise}
     */
    deletePromocode(formData) {
      return new Promise(async (resolve, reject) => {
        axios
          .delete(`${config.apiUrl}/api/v1/promocode/${formData}`)
          .then(res => {
            this.getPromocode();
            resolve();
          })
          .catch(reject);
      });
    },
  }),
};
