import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Navbar } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import '../Landing/Header.css';
import imgBack from './vector.png';
// import { Link } from 'react-router-dom';
import './index.css';

class Header extends Component {
  static propTypes = {
    member: PropTypes.shape({
      firstName: PropTypes.string,
      email: PropTypes.string,
    }),
    logout: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    member: {},
  };

  constructor(props) {
    super(props);

    this.goBack = this.goBack.bind(this);
    this.toggleDropDown = this.toggleDropDown.bind(this);
    this.state = { isOpen: false };
  }

  onLogout = () => {
    const { logout, history } = this.props;
    logout().then(() => history.push('/login'));
  };

  goBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  toggleDropDown = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  render() {
    return (
      <header>
        <Navbar>
          <p
            className="ml-lg-5 pl-lg-5 pt-4 pb-lg-5"
            onClick={this.goBack}
            style={{ cursor: 'pointer' }}
          >
            <img
              style={{ width: '43px', verticalAlign: 'text-bottom' }}
              src={imgBack}
              alt="Back Button"
            />
            <span className="ml-4 back-btn">Back</span>
          </p>
        </Navbar>
      </header>
    );
  }
}

export default withRouter(Header);
