import React, { useState, Component, useEffect } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  Table,
  Button,
  Input,
  Container,
  Row,
  Badge,
  Col,
} from 'reactstrap';
import moment from 'moment';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import './index.css';
import { withRouter } from 'react-router-dom';

function isEmpty(obj) {
  if (obj === 'null') {
    return true;
  } else if (obj === undefined) {
    return true;
  } else {
    return Object.keys(obj).length === 0;
  }
}

function formatMoney(amount, decimalCount = 0, decimal = ',', thousands = '.') {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
  }
}

const TableAmanties = props => {
  useEffect(() => {
    props.fetchAmentiesBU(props.member.id);
  }, []);

  if (props.amentiesUserData !== null) {
    return (
      <Table responsive>
        <thead>
          <tr>
            <th
              style={{ borderTop: 0 }}
              className="dashboard__tab-amenties-title"
            >
              Item
            </th>
            <th
              style={{ borderTop: 0 }}
              className="dashboard__tab-amenties-title"
            >
              Quota
            </th>
            <th
              style={{ borderTop: 0 }}
              className="dashboard__tab-amenties-title"
            ></th>
          </tr>
        </thead>
        <tbody>
          {props.amentiesUserData.length === 0
            ? <h3>No data</h3>
            : props.amentiesUserData.map((item, index) => {
              return (
                <tr key={index}>
                  <td>
                    {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                  </td>
                  <td>{item.quota}</td>
                  <td className="dasboard__tab-amenties-button">
                    + Add more {item.name}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    );
  } else {
    return <h1>No amenitis yet</h1>;
  }
};

const PaymentTab = props => {
  const [activeTab, setActiveTab] = useState('1');
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  let pendingTransaction, successTransaction;
  if (!isEmpty(props.allTransaction)) {
    pendingTransaction = props.allTransaction.filter(function(item) {
      return item.status === 'pending' || item.status === 'waiting';
    });
  }

  if (!isEmpty(props.allTransaction)) {
    successTransaction = props.allTransaction.filter(function(item) {
      return item.status === 'success';
    });
  }
  return (
    <div>
      <Nav>
        <NavItem>
          <p
            className={classnames(
              { active_dash: activeTab === '1' },
              'dashboard__tab-button mr-5  my-2'
            )}
            onClick={() => {
              toggle('1');
            }}
          >
            All
          </p>
        </NavItem>
        <NavItem>
          <p
            className={classnames(
              { active_dash: activeTab === '2' },
              'dashboard__tab-button mr-5  my-2'
            )}
            onClick={() => {
              toggle('2');
            }}
          >
            Pending{' '}
          </p>
        </NavItem>
        <NavItem>
          <p
            className={classnames(
              { active_dash: activeTab === '3' },
              'dashboard__tab-button my-2'
            )}
            onClick={() => {
              toggle('3');
            }}
          >
            Success
          </p>
        </NavItem>
      </Nav>
      <Row>
        <hr style={{ width: '100%' }}></hr>
      </Row>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              {!isEmpty(props.allTransaction) ? (
                props.allTransaction.map((item, index) => {
                  return (
                    <Col
                      xs="12"
                      className="plr-0 transaction__card"
                      key={index}
                    >
                      <span>Order ID {item.order_id}</span>
                      <br />
                      <span>
                        Order date {moment(item.date).format('DD/MM/YYYY')}
                      </span>
                      <br />
                      <br />
                      <span>{item.product_name}</span>
                      <br />
                      <span>{item.plan}</span>
                      <br />
                      <span>Amount Total</span>
                      <br />
                      <span>Rp. {formatMoney(item.price)}</span>
                      <br />
                      <br />
                      <span>Reservation date for</span>
                      <br />
                      <span>
                        {moment(item.reservation_date).format('DD/MM/YYYY')}
                      </span>
                      <br />
                      {item.status === 'pending' ? (
                        <Link to={`/transaction_detail/${item.order_id}`}>
                          <Button className="dashboard__transaction-pay-button btn btn-secondary py-2 px-4 mt-3 mb-4">
                            PAY NOW
                          </Button>
                        </Link>
                      ) : item.status === 'waiting' ? (
                        <Link to={`/transaction_process/${item.order_id}`}>
                          <Button className="dashboard__transaction-pay-button btn btn-secondary py-2 px-4 mt-3 mb-4">
                            PAY NOW
                          </Button>
                        </Link>
                      ) : item.status === 'expired' ? (
                        <p className="transaction__expired mt-3">Expired</p>
                      ) : item.status === 'verification' ? (
                        <p className="transaction__expired mt-3">
                          Waiting verification
                        </p>
                      ) : (
                        <p className="transaction__success mt-3">Success</p>
                      )}
                      <hr />
                    </Col>
                  );
                })
              ) : (
                <h1>No transaction yet</h1>
              )}
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col sm="12">
              {!isEmpty(pendingTransaction) ? (
                pendingTransaction.map((item, index) => {
                  return (
                    <Col
                      xs="12"
                      className="plr-0 transaction__card"
                      key={index}
                    >
                      <span>Order ID {item.order_id}</span>
                      <br />
                      <span>
                        Order date {moment(item.date).format('DD/MM/YYYY')}
                      </span>
                      <br />
                      <br />
                      <span>{item.product_name}</span>
                      <br />
                      <span>{item.plan}</span>
                      <br />
                      <span>Amount Total</span>
                      <br />
                      <span>Rp. {formatMoney(item.price)}</span>
                      <br />
                      <br />
                      <span>Reservation date for</span>
                      <br />
                      <span>
                        {moment(item.reservation_date).format('DD/MM/YYYY')}
                      </span>
                      <br />
                      {item.status === 'pending' ? (
                        <Link to={`/transaction_detail/${item.order_id}`}>
                          <Button className="dashboard__transaction-pay-button btn btn-secondary py-2 px-4 mt-3 mb-4">
                            PAY NOW
                          </Button>
                        </Link>
                      ) : item.status === 'waiting' ? (
                        <Link to={`/transaction_process/${item.order_id}`}>
                          <Button className="dashboard__transaction-pay-button btn btn-secondary py-2 px-4 mt-3 mb-4">
                            PAY NOW
                          </Button>
                        </Link>
                      ) : (
                        <p className="transaction__success mt-3">Success</p>
                      )}
                      <hr />
                    </Col>
                  );
                })
              ) : (
                <h1>No transaction yet</h1>
              )}
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row>
            <Col sm="12">
              {!isEmpty(successTransaction) ? (
                successTransaction.map((item, index) => {
                  return (
                    <Col
                      xs="12"
                      className="plr-0 transaction__card"
                      key={index}
                    >
                      <span>Order ID {item.order_id}</span>
                      <br />
                      <span>
                        Order date {moment(item.date).format('DD/MM/YYYY')}
                      </span>
                      <br />
                      <br />
                      <span>{item.product_name}</span>
                      <br />
                      <span>{item.plan}</span>
                      <br />
                      <span>Amount Total</span>
                      <br />
                      <span>Rp. {formatMoney(item.price)}</span>
                      <br />
                      <br />
                      <span>Reservation date for</span>
                      <br />
                      <span>
                        {moment(item.reservation_date).format('DD/MM/YYYY')}
                      </span>
                      <br />
                      {item.paid === false ? (
                        <Link to={`/transaction_detail/${item.order_id}`}>
                          <Button className="dashboard__transaction-pay-button btn btn-secondary py-2 px-4 mt-3 mb-4">
                            PAY NOW
                          </Button>
                        </Link>
                      ) : item.status === 'expired' ? (
                        <p className="transaction__expired mt-3">Expired</p>
                      ) : (
                        <p className="transaction__success mt-3">Success</p>
                      )}
                      <hr />
                    </Col>
                  );
                })
              ) : (
                <h1>No transaction yet</h1>
              )}
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

const DashboardTab = props => {
  const [activeTab, setActiveTab] = useState('1');
  useEffect(() => {
    let redir = props.location.search;
    if (redir === '?from-booking') {
      toggle('2');
    }
  }, []);

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  let pendingTransaction;
  if (!isEmpty(props.allTransaction) && props.allTransaction.length !== 0) {
    pendingTransaction = props.allTransaction.filter(function(item) {
      return item.status === 'pending' || item.status === 'waiting';
    });
  }

  return (
    <div>
      <Nav>
        <NavItem>
          <p
            className={classnames(
              { active_dash: activeTab === '1' },
              'dashboard__tab-button mr-md-5 mr-4  my-2'
            )}
            onClick={() => {
              toggle('1');
            }}
          >
            My Booking
          </p>
        </NavItem>
        <NavItem>
          <p
            className={classnames(
              { active_dash: activeTab === '2' },
              'dashboard__tab-button mr-md-5 mr-4  my-2'
            )}
            onClick={() => {
              toggle('2');
            }}
          >
            Payment
            {!isEmpty(pendingTransaction) && (
              <Badge pill color="danger" className="ml-2">
                {pendingTransaction.length}
              </Badge>
            )}
          </p>
        </NavItem>
        <NavItem>
          <p
            className={classnames(
              { active_dash: activeTab === '3' },
              'dashboard__tab-button mr-md-5 mr-4  my-2'
            )}
            onClick={() => {
              toggle('3');
            }}
          >
            Amenities
          </p>
        </NavItem>
      </Nav>
      <Row>
        <hr style={{ width: '100%' }}></hr>
      </Row>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <AllTransactionTable {...props} />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <PaymentTab {...props} />
        </TabPane>
        <TabPane tabId="3">
          <TableAmanties {...props} />
        </TabPane>
      </TabContent>
    </div>
  );
};

const AllTransactionTable = props => {
  return (
    <Table responsive hover className="transaction__all-table">
      <thead>
        <tr>
          <th>Date</th>
          <th>Space</th>
          <th>Plans</th>
          <th>Amount</th>
          <th>Location</th>
        </tr>
      </thead>
      <tbody>
        {props.allTransaction !== undefined ? (
          !isEmpty(props.allTransaction) ? (
            props.allTransaction.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{moment(item.reservation_date).format('DD/MM/YYYY')}</td>
                  <td>{item.product_name}</td>
                  <td>{item.plan}</td>
                  <td>{item.quantity}</td>
                  <td>{item.location_name}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td>No transaction yet</td>
            </tr>
          )
        ) : (
          <tr>
            <td>No transaction yet</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isEdit: false,
      full_name: this.props.member.full_name,
      company: this.props.member.company,
      email: this.props.member.email,
      phone_number: this.props.member.phone_number,
      token: this.props.member.token,
      id: this.props.member.id,
    };

    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.update = this.update.bind(this);
  }

  componentDidMount() {
    this.props
      .getAllTransaction(this.props.member.id)
      .then(res => this.setState({ allTransaction: res }));
  }

  toggleEdit = () => {
    const { isEdit } = this.state;
    this.setState({ isEdit: !isEdit });
  };

  update = e => {
    const { updateProfile } = this.props;
    e.preventDefault();
    this.setState({ loading: true });
    return updateProfile(this.state, this.state.token)
      .then(() =>
        this.setState({
          loading: false,
        })
      )
      .catch(() => {});
  };

  cancelEdit = () => {
    const { full_name, company, phone_number, email } = this.props.member;
    const { isEdit } = this.state;
    this.setState({ full_name: full_name });
    this.setState({ company: company });
    this.setState({ phone_number: phone_number });
    this.setState({ email: email });
    this.setState({ isEdit: !isEdit });
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { isEdit, full_name, company, phone_number, email } = this.state;
    return (
      <div>
        <Container className="text-left">
          <h1 className="dashboard__heading mb-4 mt-3">Personal Information</h1>
          <Row>
            <Col xs="6" md="6">
              <p className="mb-0 dashboard__label">Name</p>
              {isEdit ? (
                <Input
                  name="full_name"
                  value={full_name}
                  onChange={this.handleChange}
                  style={{ height: 'calc(1.5em + 0.25rem + 2px)' }}
                />
              ) : (
                <p className="dashboard__sub">{full_name}</p>
              )}
            </Col>
            <Col xs="6" md="6">
              <p className="mb-0 dashboard__label">Company Name</p>
              {isEdit ? (
                <Input
                  name="company"
                  value={company}
                  onChange={this.handleChange}
                  style={{ height: 'calc(1.5em + 0.25rem + 2px)' }}
                />
              ) : (
                <p className="dashboard__sub">{company}</p>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs="6" md="6" className={isEdit ? 'mt-2' : ''}>
              <p className="mb-0 dashboard__label">Email</p>
              {isEdit ? (
                <Input
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                  style={{ height: 'calc(1.5em + 0.25rem + 2px)' }}
                />
              ) : (
                <p className="dashboard__sub">{email}</p>
              )}
            </Col>
            <Col xs="6" md="6" className={isEdit ? 'mt-2' : ''}>
              <p className="mb-0 dashboard__label">Mobile Number</p>
              {isEdit ? (
                <Input
                  name="phone_number"
                  value={phone_number}
                  onChange={this.handleChange}
                  style={{ height: 'calc(1.5em + 0.25rem + 2px)' }}
                />
              ) : (
                <p className="dashboard__sub">{phone_number}</p>
              )}
            </Col>
            <Col xs="12">
              <div
                style={{
                  cursor: 'pointer',
                  marginTop: '1em',
                }}
              >
                {isEdit ? (
                  <div>
                    <Button
                      size="sm"
                      outline
                      color="primary"
                      className="mr-2"
                      type="button"
                      onClick={this.update}
                    >
                      Save
                    </Button>
                    <Button
                      size="sm"
                      outline
                      color="danger"
                      onClick={this.cancelEdit}
                    >
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <div onClick={this.toggleEdit}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0)">
                        <path
                          d="M22.1511 15.0768C21.8209 15.0768 21.5533 15.3444 21.5533 15.6746V20.9817C21.5522 21.9717 20.7501 22.7739 19.7602 22.7749H2.98862C1.99864 22.7739 1.19662 21.9717 1.19545 20.9817V5.40561C1.19662 4.41587 1.99864 3.61361 2.98862 3.61244H8.29576C8.62591 3.61244 8.89348 3.34487 8.89348 3.01472C8.89348 2.6848 8.62591 2.41699 8.29576 2.41699H2.98862C1.33881 2.41886 0.00186789 3.7558 0 5.40561V20.9819C0.00186789 22.6318 1.33881 23.9687 2.98862 23.9706H19.7602C21.41 23.9687 22.7469 22.6318 22.7488 20.9819V15.6746C22.7488 15.3444 22.4812 15.0768 22.1511 15.0768Z"
                          fill="#9C9696"
                        />
                        <path
                          d="M22.5121 0.879149C21.4616 -0.171305 19.7586 -0.171305 18.7081 0.879149L8.04433 11.5429C7.97124 11.616 7.91848 11.7066 7.89093 11.8061L6.48861 16.8687C6.43094 17.0763 6.48954 17.2986 6.64178 17.4511C6.79424 17.6033 7.01652 17.6619 7.22409 17.6045L12.2868 16.2019C12.3862 16.1744 12.4768 16.1216 12.5499 16.0485L23.2134 5.3845C24.2623 4.33334 24.2623 2.6317 23.2134 1.58054L22.5121 0.879149ZM9.34671 11.9314L18.0742 3.20374L20.8889 6.01841L12.1612 14.7461L9.34671 11.9314ZM8.78448 13.0597L11.0332 15.3086L7.92268 16.1704L8.78448 13.0597ZM22.3682 4.53928L21.7343 5.17319L18.9194 2.35828L19.5536 1.72437C20.137 1.14089 21.0831 1.14089 21.6666 1.72437L22.3682 2.42576C22.9508 3.00994 22.9508 3.95533 22.3682 4.53928Z"
                          fill="#9C9696"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <p
                      style={{
                        display: 'inline-block',
                        marginLeft: '.5em',
                        color: '#9C9696',
                        verticalAlign: 'text-top',
                      }}
                    >
                      Edit Information
                    </p>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <hr style={{ width: '100%' }}></hr>
          </Row>
          <DashboardTab {...this.props} {...this.state} />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
  amentiesUserData: state.amenties.userData || {},
  // allTransaction: state.transaction.allTransaction || [],
});

const mapDispatchToProps = dispatch => ({
  updateProfile: dispatch.member.updateProfile,
  getAllTransaction: dispatch.transaction.getAllTransactionBU,
  fetchAmentiesBU: dispatch.amenties.fetchAmentiesBU,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Dashboard));
