import initState from '../store/recipes';
import axios from 'axios';
import config from '../constants/config'

export default {
  /**
   *  Initial state
   */
  state: {
    coffee: initState.coffee,
  },

  /**
   * Reducers
   */
  reducers: {
    replaceInstagramFeed(state, payload) {
      let feed = [];
      // Pick out the props I need
      if (payload && typeof payload === 'object') {
        feed = payload.map((item,index) => ({
          url:payload[index]["node"].display_url,
        }));
      }

      return { ...state,feed};
    },
    replaceInstagramFeedSpace(state, payload) {
      let feedSpace = [];
      // Pick out the props I need
      if (payload && typeof payload === 'object') {
        feedSpace = payload.map((item,index) => ({
          url:payload[index]["node"].display_url,
        }));
      }

      return { ...state,feedSpace};
    },
    replaceCoffee(state, payload) {
      let all = [];
      // Pick out the props I need
      if (payload && typeof payload === 'object') {
        all = payload.rows.map(item => ({
          id: item.id,
          category: item.category,
          name: item.name,
          image: item.image,
          price: item.price,
        }));
      }

      return { ...state, all };
    },
    replaceDetailCoffee(state, payload) {
      let detailCoffee = [];
      // Pick out the props I need
      if (payload && typeof payload === 'object') {
        detailCoffee = payload;
      }

      return { ...state, detailCoffee };
    },
    imageUrl(state, payload) {
      let imgUrl = payload;

      return { ...state, imgUrl };
    },
  },

  /**
   * Effects/Actions
   */
  effects: () => ({
    /**
     * Get All Coffee
     *
     * @return {Promise}
     */
    getCoffee() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${config.apiUrl}/api/v1/coffee/`)
          .then(res => {
            this.replaceCoffee(res.data);
            resolve();
          })
          .catch(reject);
      });
    },

    getInstagramFeed() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`https://www.instagram.com/emjicoffeebar/?__a=1`)
          .then(res => {
            let data = res.data.graphql.user.edge_owner_to_timeline_media.edges;
            this.replaceInstagramFeed(data);
            resolve();
          })
          .catch(reject);
      });
    },

    getInstagramFeedSpace() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`https://www.instagram.com/emjispace/?__a=1`)
          .then(res => {
            let data = res.data.graphql.user.edge_owner_to_timeline_media.edges;
            this.replaceInstagramFeedSpace(data);
            resolve();
          })
          .catch(reject);
      });
    },
    /**
     * Get All Coffee
     *
     * @return {Promise}
     */
    getDetailCoffee(id) {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${config.apiUrl}/api/v1/coffee/${id}`)
          .then(res => {
            this.replaceDetailCoffee(res.data);
            resolve();
          })
          .catch(reject);
      });
    },
    /**
     * Upload Image
     *
     * @return {Promise}
     */
    uploadImage(formData) {
      let bodyFormData = new FormData();
      bodyFormData.append('image', formData.img);
      // let popo = formData.image[0]
      return new Promise(async (resolve, reject) => {
        axios
          .post(`${config.apiUrl}/upload`, bodyFormData)
          .then(res => {
            this.imageUrl(res.data.path);
            resolve();
          })
          .catch(reject);
      });
    },
    /**
     * Create New Coffee
     *
     * @return {Promise}
     */
    createCoffee(formData) {
      return new Promise(async (resolve, reject) => {
        axios
          .post(`${config.apiUrl}/api/v1/coffee/`, formData)
          .then(res => {
            // this.replaceCoffee(res.data);
            resolve();
          })
          .catch(reject);
      });
    },
    /**
     * Create New Coffee
     *
     * @return {Promise}
     */
    updateCoffee(formData) {
      return new Promise(async (resolve, reject) => {
        axios
          .put(`${config.apiUrl}/api/v1/coffee/${formData.id}`, formData)
          .then(res => {
            resolve();
          })
          .catch(reject);
      });
    },
    /**
     * Delete Coffee
     *
     * @return {Promise}
     */
    deleteCoffee(formData) {
      return new Promise(async (resolve, reject) => {
        axios
          .delete(`${config.apiUrl}/api/v1/coffee/${formData}`)
          .then(res => {
            this.getCoffee();
            resolve();
          })
          .catch(reject);
      });
    },
  }),
};
