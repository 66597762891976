import React from 'react';
import {
  Row,
  Col,
  Card,
  Form,
  Label,
  Alert,
  Input,
  Button,
  CardBody,
  FormGroup,
  CardHeader,
  CustomInput,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class CreatePromocode extends React.Component {
  static defaultProps = {
    error: null,
    success: null,
    member: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      cName: ''.toUpperCase(),
      quantity: null,
      type: 'percentage',
      days: '',
      percentage: null,
      cut_price: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInt = this.handleInt.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
  }

  onChangeType = data => {
    // not allowed AND not working
    // eslint-disable-next-line
    this.setState(state => {
      if (data === 'cut_price') {
        state.percentage = 0
      } else {
        state.cut_price = 0
      }
      state.type = data;
      return {};
    });
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value.toUpperCase() });
  handleInt = e => this.setState({ [e.target.name]: parseInt(e.target.value) });

  handleSubmit = e => {
    e.preventDefault();
    const { createPromocode, history } = this.props;

    return createPromocode(this.state)
      .then(() => setTimeout(() => history.push('/backoffice/promocodes'), 1000))
      .catch(() => {});
  };

  render() {
    const { success, error } = this.props;
    const { cName } = this.state;
    return (
      <div>
        <Row className="pt-5">
          <Col lg={{ size: 12 }}>
            <Card>
              <CardHeader>Create Promocode</CardHeader>
              <CardBody>
                {!!success && <Alert color="success">{success}</Alert>}
                {!!error && <Alert color="danger">{error}</Alert>}

                <Form onSubmit={this.handleSubmit}>
                  <FormGroup row>
                    <Label for="exampleEmail" sm={2}>
                      Name
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="text"
                        name="cName"
                        id="cName"
                        placeholder="Promocode name"
                        value={cName}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleText" sm={2}>
                      Quantity
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="number"
                        name="quantity"
                        id="quantity"
                        max={200}
                        value={this.state.quantity}
                        onChange={this.handleInt}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="typeSelecte" sm={2}>
                      Type
                    </Label>
                    <Col sm={10}>
                      <CustomInput
                        type="select"
                        id="typeSelecte"
                        name="typeSelecte"
                        value={this.state.type}
                        onChange={e => this.onChangeType(e.target.value)}
                      >
                        <option value="default" disabled>
                          Select
                        </option>
                        <option value="percentage">Percentage</option>
                        <option value="cut_price">Cut Price</option>
                      </CustomInput>
                    </Col>
                  </FormGroup>
                  {this.state.type === 'percentage' ? (
                    <FormGroup row>
                      <Label for="percentage" sm={2}>
                        Percentage
                      </Label>
                      <Col sm={10}>
                        <Input
                          type="text"
                          name="percentage"
                          id="percentage"
                          pattern="\d*"
                          max={100}
                          maxLength={2}
                          value={this.state.percentage}
                          onChange={this.handleInt}
                        />
                      </Col>
                    </FormGroup>
                  ) : (
                    <FormGroup row>
                      <Label for="cut_price" sm={2}>
                        Cut Price
                      </Label>
                      <Col sm={10}>
                        <Input
                          type="number"
                          name="cut_price"
                          id="cut_price"
                          value={this.state.cut_price}
                          onChange={this.handleInt}
                        />
                      </Col>
                    </FormGroup>
                  )}
                  <FormGroup row>
                    <Label for="days" sm={2}>
                      Days
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="number"
                        name="days"
                        id="days"
                        max={200}
                        value={this.state.days}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </FormGroup>

                  <hr />
                </Form>
              </CardBody>
            </Card>
            <FormGroup check row className="mt-4 text-right">
              <Col sm={{ size: 10, offset: 2 }}>
                <Button color="primary" outline onClick={this.handleSubmit}>
                  Create
                </Button>
              </Col>
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  allPromocode: state.promocode || {},
  imgData: state.promocode.imgUrl || {},
});

const mapDispatchToProps = dispatch => ({
  createPromocode: dispatch.promocode.createPromocode,
  uploadImage: dispatch.promocode.uploadImage,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreatePromocode));

// export default withRouter(CreatePromocode);
