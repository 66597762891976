import React from 'react';
import {
  Row,
  Col,
  Card,
  Form,
  Label,
  Alert,
  Input,
  Button,
  CardBody,
  FormGroup,
  CardHeader,
  // CustomInput,
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ModalImage from 'react-modal-image';
import config from '../../../../constants/config';

class EditLocation extends React.Component {
  static defaultProps = {
    error: null,
    success: null,
    member: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      pName: props.detailLocations.name || '',
      id: props.detailLocations.id || 0,
      pt_form: [],
      description: props.detailLocations.description || '',
      address: props.detailLocations.address || '',
      maps: props.detailLocations.maps || '',
      image: props.detailLocations.image || [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onAddPrice = this.onAddPrice.bind(this);
    this.handleChangePrice = this.handleChangePrice.bind(this);
    this.handleLoadImage = this.handleLoadImage.bind(this);
    this.handleImage = this.handleImage.bind(this);
    this.fethcData = this.fethcData.bind(this);
    this.removeImage = this.removeImage.bind(this);
  }

  componentDidMount() {
    this.fethcData();
  }

  fethcData() {
    this.props.getLocation(this.props.match.params.locationId);
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleChangePrice = (name, value) => {
    // not allowed AND not working
    this.setState(state => {
      let lp = this.state.location_type;
      lp.forEach((element, index) => {
        if (Object.keys(lp[index])[0] === name) {
          lp[index][name] = value;
        }
      });
      return {
        lp,
      };
    });
  };

  removeImage = id => {
    var newArrImg = this.state.image.filter(function(a) {
      return a !== id;
    });
    this.setState(() => ({
      image: newArrImg,
    }));
  };

  handleImage = file => {
    if (file) {
      this.setState(() => ({
        img: file,
      }));
    }
  };

  handleLoadImage = file => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // const listImg = this.state.image.push(new Blob([reader.result], {type: "image/png"}));
        const listImg = this.state.image.push(reader.result);
        // as a good practice, use a function to set state
        this.setState(() => ({
          imagePreviewUrl: listImg,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  onAddPrice = data => {
    // not allowed AND not working
    this.setState(state => {
      let lp = { [data]: '' };
      const list = this.state.location_type.push(lp);
      return {
        list,
      };
    });
  };

  onRmvPrice = data => {
    // not allowed AND not working
    // eslint-disable-next-line
    this.setState(state => {
      const list = this.state.location_type.filter(
        el => Object.keys(el)[0] !== data
      );
      const location_type = this.state.location_type.filter(
        el => Object.keys(el)[0] !== data
      );
      this.state.location_type.filter(el => Object.keys(el)[0] !== data);
      //   const pList = this.state.pList.filter(el => el !== data);
      return {
        location_type,
        list,
        // pList,
      };
    });
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleSubmit = e => {
    e.preventDefault();
    const { updateLocations, history } = this.props;

    return updateLocations(this.state)
      .then(() => setTimeout(() => history.push('/backoffice/locations'), 1000))
      .catch(() => {});
  };

  upload = e => {
    e.preventDefault();
    const { uploadImage } = this.props;
    return (
      uploadImage(this.state)
        .then(() =>
          this.setState(state => {
            const imglist = this.state.image.push(this.props.imgData);
            return {
              imglist,
            };
          })
        )
        // this.state.image.push(this.props.imgData)
        .catch(() => {})
    );
  };

  render() {
    const { success, error } = this.props;
    const { pName } = this.state;
    return (
      <div>
        <Row className="pt-5">
          <Col lg={{ size: 12 }}>
            <Card>
              <CardHeader>Edit Location</CardHeader>
              <CardBody>
                {!!success && <Alert color="success">{success}</Alert>}
                {!!error && <Alert color="danger">{error}</Alert>}

                <Form onSubmit={this.handleSubmit}>
                  <FormGroup row>
                    <Label for="exampleEmail" sm={2}>
                      Name
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="text"
                        name="pName"
                        id="pName"
                        placeholder="Location name"
                        value={pName}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleText" sm={2}>
                      Description
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="textarea"
                        name="description"
                        id="description"
                        max={200}
                        value={this.state.description}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleText" sm={2}>
                      Address
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="textarea"
                        name="address"
                        id="address"
                        max={200}
                        value={this.state.address}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleText" sm={2}>
                      Maps
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="text"
                        name="maps"
                        id="maps"
                        max={200}
                        value={this.state.maps}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleFile" sm={2}>
                      Image
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="file"
                        accept="image/x-png,image/gif,image/jpeg"
                        name="photo"
                        style={{ width: '55%', display: 'inline-block' }}
                        onChange={e => this.handleImage(e.target.files[0])}
                      />
                      <Button
                        type="submit"
                        size="sm"
                        color="primary"
                        disabled={this.state.img ? false : true}
                        onClick={this.upload}
                      >
                        Upload
                      </Button>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleFile" sm={2}>
                      List Image
                    </Label>
                    <Col sm={10}>
                      {this.state.image.length !== undefined
                        ? this.state.image.map((items, index) => {
                            return (
                              <div className="mt-3" key={index}>
                                <div
                                  style={{
                                    maxWidth: '10%',
                                    display: 'inline-block',
                                  }}
                                >
                                  <ModalImage
                                    small={`${config.apiUrl}${items}`}
                                    large={`${config.apiUrl}${items}`}
                                    alt={items}
                                  />
                                </div>
                                <Button
                                  onClick={() => this.removeImage(items)}
                                  color="danger"
                                  size="sm"
                                  outline
                                  className="ml-4"
                                >
                                  Remove
                                </Button>
                              </div>
                            );
                          })
                        : 's'}
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>

            {/* <FormGroup check row className="mt-4 text-right">
              <Col sm={{ size: 10, offset: 2 }}>
                <Button color="primary" outline onClick={this.handleSubmit}>
                  Create
                </Button>
              </Col>
            </FormGroup> */}

            <FormGroup check row className="mt-4 text-right">
              <Col sm={{ size: 12 }}>
                <Button
                  color="primary"
                  className="mr-3"
                  outline
                  size="sm"
                  onClick={this.handleSubmit}
                >
                  Save
                </Button>
                <Link to={`/backoffice/location/${this.state.id}`}>
                  <Button color="danger" outline size="sm">
                    Cancel
                  </Button>
                </Link>
              </Col>
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  imgData: state.locations.imgUrl || {},
  detailLocations: state.locations.detailLocations || {},
});

const mapDispatchToProps = dispatch => ({
  updateLocations: dispatch.locations.updateLocations,
  uploadImage: dispatch.locations.uploadImage,
  getLocation: dispatch.locations.getDetailLocations,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditLocation));

// export default withRouter(EditLocation);
