import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import Locations from '../../../containers/Backoffice/Locations';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';

const Page = props => (
  <div>
    <Row className="pt-5">
      <Col lg={6} xs={12}>
        <h1>Locations page</h1>
      </Col>
      <Col lg={6} xs={12} className="text-right">
        <Link to="/backoffice/locations/new">
          <Button outline color="primary">
            Add New Location
          </Button>
        </Link>
      </Col>

      <Table hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Description</th>
            <th>Address</th>
            <th style={{ textAlign: 'center' }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {props.locations.all ? (
            props.locations.all.map((item, index) => {
              return (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{item.name}</td>
                  <td style={{ maxWidth: '40vh' }}>
                    {item.description.length >= 100
                      ? item.description.substring(0, 100) + '...'
                      : item.description}
                  </td>
                  <td>{item.address}</td>
                  <td
                    style={{
                      verticalAlign: 'middle',
                      textAlign: 'center',
                      minWidth: '25vh',
                    }}
                  >
                    <Link to={`/backoffice/location/${item.id}`}>
                      <Button
                        size="sm"
                        outline
                        color="primary"
                        className="mr-3"
                      >
                        View
                      </Button>
                    </Link>
                    <Button
                      size="sm"
                      outline
                      color="danger"
                      onClick={() => props.deleteLocations(item.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })
          ) : (
            <h1>No Item Found</h1>
          )}
        </tbody>
      </Table>
    </Row>
  </div>
);

const LocationsBO = props => {
  return <Locations {...props} Layout={Page} />;
};

const mapStateToProps = state => ({
  allLocations: state.locations || {},
  detailLocations: state.detailLocations || {},
});

const mapDispatchToProps = dispatch => ({
  fetchLocations: dispatch.locations.getLocations,
  getLocations: dispatch.locations.getDetailLocations,
  deleteLocations: dispatch.locations.deleteLocations,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationsBO);
