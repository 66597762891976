import initState from '../store/recipes';
import config from '../constants/config';
import axios from 'axios';

export default {
  /**
   *  Initial state
   */
  state: {
    gallery: initState.gallery,
  },

  /**
   * Reducers
   */
  reducers: {
    imageGallery(state, payload) {
      let imgGallery = payload;

      return { ...state, imgGallery };
    },
    galleryAll(state, payload) {
      let galleryAll = payload;

      return { ...state, galleryAll };
    },
  },

  /**
   * Effects/Actions
   */
  effects: () => ({

    /**
     * Delete Gallery
     *
     * @return {Promise}
     */
    deleteGallery(formData) {
      return new Promise(async (resolve, reject) => {
        axios
          .delete(`${config.apiUrl}/api/v1/gallery/${formData}`)
          .then(res => {
            this.fetchGallery();
            resolve();
          })
          .catch(reject);
      });
    },
    fetchGallery() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${config.apiUrl}/api/v1/gallery/all`)
          .then(res => {
            this.galleryAll(res.data.rows);
            resolve();
          })
          .catch(reject);
      });
    },

    uploadImage(formData) {
      let bodyFormData = new FormData();
      bodyFormData.append('image', formData[0]);
      // let popo = formData.image[0]
      new Promise(async (resolve, reject) => {
        const res = await axios
          .post(`${config.apiUrl}/upload`, bodyFormData)
          .catch(reject);
        //   let { data } = res.data;
        if (res.data) {
          const resData = await axios
            .post(`${config.apiUrl}/api/v1/gallery`, res.data)
            .then(ress => {
              resolve();
            })
            .catch(reject);
          return resData;
        }
      });
      return;
    },

    uploadTransaction(formData) {
      console.log(formData);
      let bodyFormData = new FormData();
      bodyFormData.append('image', formData[0]);
      // let popo = formData.image[0]
      if (formData.length === 0) {
        return false;
      } else {
        let uploader = new Promise(async (resolve, reject) => {
          const res = await axios
            .post(`${config.apiUrl}/upload`, bodyFormData)
            .catch(reject);
          //   let { data } = res.data;
          if (res.data) {
            const resData = await axios
              .post(`${config.apiUrl}/api/v1/gallery`, res.data)
              .then(ress => {
                resolve(ress);
              })
              .catch(reject);
            return resData;
          }
        });
        return uploader;
      }
    },
  }),
};
