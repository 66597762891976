import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Promocode extends Component {
  static propTypes = {
    Layout: PropTypes.func.isRequired,
    adminLogout: PropTypes.func.isRequired,
    fetchPromocode: PropTypes.func.isRequired,
    admin: PropTypes.shape({}).isRequired,
  };

  state = {
    error: null,
    loading: false,
  };

  componentDidMount = () => this.getAllProduct();

  getAllProduct = data => {
    const { fetchPromocode } = this.props;
    this.setState({ loading: true });
    return fetchPromocode()
      .then(() =>
        this.setState({
          loading: false,
          error: null,
        })
      )
      .catch(err =>
        this.setState({
          loading: false,
          error: err,
        })
      );
  };

  deletePromocode = (id) => {
    this.setState({ loading: true });
    // return deletePromocode(data)
    //   .then(() =>
    //     this.setState({
    //       loading: false,
    //       error: null,
    //     })
    //   )
    //   .catch(err =>
    //     this.setState({
    //       loading: false,
    //       error: err,
    //     })
    //   );
  };

  render = () => {
    const { Layout, admin, adminLogout,promocode,deletePromocode } = this.props;
    const { loading, error } = this.state;

    return (
      <Layout
        error={error}
        loading={loading}
        admin={admin}
        promocode={promocode}
        deletePromocode={deletePromocode}
        fetchPromocode={() => this.getAllProduct()}
        logout={adminLogout}
        reFetch={() => this.fetchData()}
      />
    );
  };
}

const mapStateToProps = state => ({
  admin: state.admin || {},
  promocode: state.promocode || {},
});

const mapDispatchToProps = dispatch => ({
  adminLogout: dispatch.admin.logout,
  fetchPromocode: dispatch.promocode.getPromocode,
  deletePromocode: dispatch.promocode.deletePromocode,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Promocode);
