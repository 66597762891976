import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import Promocode from '../../../containers/Backoffice/Promocodes';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';


function formatMoney(amount, decimalCount = 0, decimal = ',', thousands = '.') {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
  }
}


const Page = props => (
  <div>
    <Row className="pt-5">
      <Col lg={6} xs={12}>
        <h1>Promocode page</h1>
      </Col>
      <Col lg={6} xs={12} className="text-right">
        <Link to="/backoffice/promocode/new">
          <Button outline color="primary">
            Add New Promocode
          </Button>
        </Link>
      </Col>

      <Table hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Code</th>
            <th>Type</th>
            <th>Discount</th>
            <th>Quantity</th>            
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {props.promocode.all ? (
            props.promocode.all.map((item, index) => {
              return (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{item.code}</td>
                  <td style={{ maxWidth: '40vh' }}>{item.type[0].toUpperCase() + item.type.replace("_"," ").substr(1)}</td>
                  <td>{item.type === 'percentage' ? <span>{item.percentage}%</span> : <span>Rp. {formatMoney(item.cut_price)}</span> }</td>
                  <td>{item.quantity}</td>
                  <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                    <Button
                      size="sm"
                      outline
                      color="danger"
                      onClick={() => props.deletePromocode(item.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })
          ) : (
            <h1>No Item Found</h1>
          )}
        </tbody>
      </Table>
    </Row>
  </div>
);

const PromocodeBO = props => {
  return <Promocode {...props} Layout={Page} />;
};

const mapStateToProps = state => ({
  allPromocode: state.promocode || {},
  detailPromocode: state.detailPromocode || {},
});

const mapDispatchToProps = dispatch => ({
  fetchPromocode: dispatch.promocode.getPromocode,
  getPromocode: dispatch.promocode.getDetailPromocode,
  deletePromocode: dispatch.promocode.deletePromocode,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromocodeBO);