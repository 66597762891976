const devMode = process.env.NODE_ENV !== 'development';

let apiUrl;

if (window.location.origin === 'http://localhost:3000') {
  apiUrl = 'https://emji.id';
} else {
  apiUrl = 'https://emji.id';
}

export default {
  // App Details
  appName: 'Emji Web',
  apiUrl: apiUrl,
  // Build Configuration - eg. Debug or Release?
  DEV: devMode,

  // Google Analytics - uses a 'dev' account while we're testing
  gaTrackingId: devMode ? 'UA-84284256-2' : 'UA-84284256-1',
};
