import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Admin extends Component {
  static propTypes = {
    Layout: PropTypes.func.isRequired,
    fetchProducts: PropTypes.func.isRequired,
    admin: PropTypes.shape({}).isRequired,
  };

  state = {
    error: null,
    loading: false,
  };

  getAllProduct = data => {
    const { fetchProducts } = this.props;
    this.setState({ loading: true });
    return fetchProducts()
      .then(() =>
        this.setState({
          loading: false,
          error: null,
        })
      )
      .catch(err =>
        this.setState({
          loading: false,
          error: err,
        })
      );
  };

  render = () => {
    const { Layout, admin, login } = this.props;
    const { loading, error } = this.state;

    return (
      <Layout
        error={error}
        loading={loading}
        admin={admin}
        onFormSubmit={login}
      />
    );
  };
}

const mapStateToProps = state => ({
  admin: state.admin || {},
});

const mapDispatchToProps = dispatch => ({
  login: dispatch.admin.login,
  fetchAdmin: dispatch.admin.getAdminData,
  fetchProducts: dispatch.products.getProducts,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Admin);
