import React from 'react';
import {
  Row,
  Col,
  // Label,
  // Input,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  Button,
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import config from '../../../../constants/config';

function isEmpty(obj) {
  if (obj === 'null') {
    return true;
  } else if (obj === undefined) {
    return true;
  } else {
    return Object.keys(obj).length === 0;
  }
}
class DetailCoffee extends React.Component {
  static defaultProps = {
    error: null,
    success: null,
    member: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      pName: '',
      coffee_type: [],
      price_type: [],
      pt_form: [],
      description: '',
      benefits: [],
      image: [],
      benefit: false,
    };

    this.fethcData = this.fethcData.bind(this);
  }
  componentDidMount() {
    this.fethcData();
  }

  fethcData() {
    this.props.getCoffee(this.props.match.params.coffeeId);
  }

  render() {
    const { detailCoffee } = this.props;
    return (
      <div>
        <Row className="pt-5">
          <Col lg={{ size: 12 }}>
            <Card>
              <Row>
                {!isEmpty(detailCoffee) ? (
                  detailCoffee.image.map((item, index) => {
                    return (
                      <Col lg={4} key={index}>
                        <CardImg
                          key={index}
                          width="40%"
                          src={`${config.apiUrl}${item}`}
                          alt="Card image cap"
                        />
                      </Col>
                    );
                  })
                ) : (
                  <h1>No Item</h1>
                )}
              </Row>

              <CardBody>
                <CardTitle>{detailCoffee.name}</CardTitle>
                <hr />
                <CardText>{detailCoffee.description}</CardText>
                <hr />
                <CardText>
                  {!isEmpty(detailCoffee) &&
                    detailCoffee.type_price.map((item, index) => {
                      return <span key={index}>{item}</span>;
                    })}
                </CardText>
              </CardBody>
            </Card>
            <Row className="col-xs-12 mt-3">
              <Col xs={{ size: 12 }} className="text-right">
                <Link
                  to={`/backoffice/coffee/${detailCoffee.id}/edit`}
                  className="mr-3"
                >
                  <Button size="sm" color="primary">
                    Edit
                  </Button>
                </Link>
                <Link to="/backoffice/coffee">
                  <Button size="sm" color="warning">
                    Back
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  detailCoffee: state.coffee.detailCoffee || {},
});

const mapDispatchToProps = dispatch => ({
  getCoffee: dispatch.coffee.getDetailCoffee,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DetailCoffee));

// export default withRouter(DetailCoffee);
