import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Amenties extends Component {
  static propTypes = {
    Layout: PropTypes.func.isRequired,
    fetchAmenties: PropTypes.func.isRequired,
    admin: PropTypes.shape({}).isRequired,
  };

  state = {
    error: null,
    loading: false,
  };

  componentDidMount = () => this.getAllLocation();

  getAllLocation = data => {
    const { fetchAmenties } = this.props;
    this.setState({ loading: true });
    return fetchAmenties()
      .then(() =>
        this.setState({
          loading: false,
          error: null,
        })
      )
      .catch(err =>
        this.setState({
          loading: false,
          error: err,
        })
      );
  };

  deleteAmenties = id => {
    this.setState({ loading: true });
    // return deleteAmenties(data)
    //   .then(() =>
    //     this.setState({
    //       loading: false,
    //       error: null,
    //     })
    //   )
    //   .catch(err =>
    //     this.setState({
    //       loading: false,
    //       error: err,
    //     })
    //   );
  };

  render = () => {
    const {
      Layout,
      fetchAmenties,
      deleteAmenties,
      allAmenties,
      admin,
      adminLogout,
      amenties,
      history
    } = this.props;
    const { loading, error } = this.state;

    return (
      <Layout
        error={error}
        loading={loading}
        admin={admin}
        amenties={amenties}
        history={history}
        toggleModal={this.props.toggleModal}
        allAmenties={allAmenties}
        fetchAmenties={fetchAmenties}
        deleteAmenties={deleteAmenties}
        // fetchAmenties={() => this.getAllLocation()}
        logout={adminLogout}
        reFetch={() => this.fetchData()}
      />
    );
  };
}

const mapStateToProps = state => ({
  admin: state.admin || {},
  // amenties: state.amenties || {},
  allAmenties: state.amenties.amentiesAll || {},
});

const mapDispatchToProps = dispatch => ({
  //   adminLogout: dispatch.admin.logout,
    fetchAmenties: dispatch.amenties.fetchAmenties,
  //   deleteAmenties: dispatch.amenties.deleteAmenties,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Amenties);
