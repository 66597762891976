import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import Coffee from '../../../containers/Backoffice/Coffees';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import ModalImage from 'react-modal-image';
import config from '../../../constants/config';

const Page = props => (
  <div>
    <Row className="pt-5">
      <Col lg={6} xs={12}>
        <h1>Coffee page</h1>
      </Col>
      <Col lg={6} xs={12} className="text-right">
        <Link to="/backoffice/coffee/new">
          <Button outline color="primary">
            Add New Coffee
          </Button>
        </Link>
      </Col>

      <Table hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Category</th>
            <th>Price</th>
            <th className="text-center">Image</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {props.coffee.all ? (
            props.coffee.all.map((item, index) => {
              return (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{item.name}</td>
                  <td style={{ maxWidth: '40vh' }}>{item.category}</td>
                  <td>{item.price}</td>
                  <td className="text-center">
                    <div
                      style={{
                        maxWidth: '50%',
                        display: 'inline-block',
                        textAlign: 'center'
                      }}
                    >
                      <ModalImage
                        small={`${config.apiUrl}${item.image}`}
                        large={`${config.apiUrl}${item.image}`}
                        alt={item.image}
                      />
                    </div>
                  </td>
                  <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                    <Button
                      size="sm"
                      outline
                      color="danger"
                      onClick={() => props.deleteCoffee(item.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })
          ) : (
            <h1>No Item Found</h1>
          )}
        </tbody>
      </Table>
    </Row>
  </div>
);

const CoffeeBO = props => {
  return <Coffee {...props} Layout={Page} />;
};

const mapStateToProps = state => ({
  allCoffee: state.coffee || {},
  detailCoffee: state.detailCoffee || {},
});

const mapDispatchToProps = dispatch => ({
  fetchCoffee: dispatch.coffee.getCoffee,
  getCoffee: dispatch.coffee.getDetailCoffee,
  deleteCoffee: dispatch.coffee.deleteCoffee,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoffeeBO);
