import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import moment from 'moment';
import { connect } from 'react-redux';
import Transactions from '../../../containers/Backoffice/Transactions';
import {
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import config from '../../../constants/config';
// import { Link } from 'react-router-dom';

// function isEmpty(obj) {
//   if (obj === 'null') {
//     return true;
//   } else if (obj === undefined) {
//     return true;
//   } else {
//     return Object.keys(obj).length === 0;
//   }
// }

function formatMoney(amount, decimalCount = 0, decimal = ',', thousands = '.') {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
  }
}

const ModalDetail = props => {
  const { data, history } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  let user_id;
  if (props.data.users) {
    user_id = props.data.users.split(',')[0].replace('(', '');
  }
  const addAmenties = () => history.push(`/backoffice/amenties?${user_id}`);
  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>
        Transaction Detail - {data.order_id}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={12} md={6}>
            <p>
              Status : <br />{' '}
              {data.status === 'waiting'
                ? 'Waiting for payment'
                : data.status === 'pending'
                ? 'Pending'
                : data.status === 'verification'
                ? 'Verification'
                : data.status === 'success'
                ? 'Success'
                : 'Pending'}
            </p>
            <p>
              Location : <br /> {data.location_name}
            </p>
            <p>
              Product : <br /> {data.product_name}
            </p>
            <p>
              Plan : <br /> {data.plan}
            </p>
          </Col>
          <Col xs={12} md={6}>
            <p>
              Price : <br /> Rp. {formatMoney(data.price)}
            </p>
            <p>
              Quantity : <br /> {data.quantity}
            </p>
            <p>
              Reservation Date : <br />
              {moment(data.reservation_date).format('DD/MM/YYYY')}
            </p>
            <p>
              Book Date : <br /> {moment(data.book_date).format('DD/MM/YYYY')}
            </p>
          </Col>
          <Col xs={12} md={6}>
            {data.status === 'verification' ||
              (data.status === 'invalid_image' && (
                <p>
                  Image : <br />
                  <img src={`${config.apiUrl}${data.proof_img}`} alt="ss" />
                </p>
              ))}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button outline color="warning" onClick={addAmenties}>
          Add Amenties
        </Button>
        {data.status === 'verification' && (
          <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} color="light">
            <DropdownToggle caret color="success">
              Verification
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>Change Status</DropdownItem>
              <DropdownItem
                onClick={() => {
                  return (
                    props.toggle(),
                    props.updateTransaction({
                      order_id: data.order_id,
                      status: 'success',
                      paid: true,
                    })
                  );
                }}
              >
                Success
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => {
                  return (
                    props.toggle(),
                    props.updateTransaction({
                      order_id: data.order_id,
                      status: 'expired',
                      paid: false,
                    })
                  );
                }}
              >
                Expired
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  return (
                    props.toggle(),
                    props.updateTransaction({
                      order_id: data.order_id,
                      status: 'invalid_image',
                      paid: false,
                    })
                  );
                }}
              >
                Invalid Image Proof
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        )}
        <Button color="danger" onClick={props.toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const Page = props => {
  useEffect(() => {
    props.fetchTransactions();
  }, []);

  const [modal, setModal] = useState(false);

  const toggle = id => {
    props.getDetailTransaction(id);
    setModal(!modal);
  };

  return (
    <div>
      <ModalDetail
        {...props}
        toggle={toggle}
        modal={modal}
        updateTransaction={props.updateTransactions}
        data={props.detailTransaction}
      />
      <Row className="pt-5">
        <Col lg={6} xs={12}>
          <h1>Transactions page</h1>
        </Col>

        <Table hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Status</th>
              <th>Product Name</th>
              <th>Quantity</th>
              <th>Order ID</th>
              <th>Price</th>
              <th style={{ textAlign: 'center' }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {props.transaction === null || props.transaction === undefined ? (
              <h1>No Item Found</h1>
            ) : (
              props.transaction.map((item, index) => {
                if (item.order_id === null) {
                  return null;
                } else {
                  return (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{item.name}</td>
                      <td>
                        {item.status === 'waiting'
                          ? 'Waiting for payment'
                          : item.status === 'pending'
                          ? 'Pending'
                          : item.status === 'verification'
                          ? 'Verification'
                          : item.status === 'success'
                          ? 'Success'
                          : 'Invalid Image Proof'}
                      </td>
                      <td>{item.product_name}</td>
                      <td>{item.quantity}</td>
                      <td>{item.order_id}</td>
                      <td>RP. {formatMoney(item.price)}</td>
                      <td
                        style={{ verticalAlign: 'middle', textAlign: 'center' }}
                      >
                        <Button
                          size="sm"
                          outline
                          color="primary"
                          className="mr-3"
                          onClick={() => toggle(item.order_id)}
                        >
                          Detail
                        </Button>
                      </td>
                    </tr>
                  );
                }
              })
            )}
          </tbody>
        </Table>
      </Row>
    </div>
  );
};

const TransactionsBO = props => {
  return <Transactions {...props} Layout={Page} />;
};

const mapStateToProps = state => ({
  detailTransactions: state.detailTransactions || {},
  allTransactions: state.transaction.allTransactions || {},
});

const mapDispatchToProps = dispatch => ({
  getTransactions: dispatch.transaction.getTransactions,
  getDetailTransaction: dispatch.transaction.getDetailTransaction,
  updateTransactions: dispatch.transaction.updateTransactionStatusAdmin,
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsBO);
