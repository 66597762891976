import initState from '../store/recipes';
import axios from 'axios';
import config from '../constants/config'

export default {
  namespace: 'book',
  /**
   *  Initial state
   */
  state: {
    book: initState.book,
  },

  /**
   * Reducers
   */
  reducers: {
    replaceMessages(state, payload) {
      let allMessage = [];
      // Pick out the props I need
      if (payload && typeof payload === 'object') {
        allMessage = payload.rows;
      }

      return { ...state, allMessage };
    },

    replaceDetailMessages(state, payload) {
      let detailMessages = [];
      // Pick out the props I need
      if (payload && typeof payload === 'object') {
        detailMessages = payload;
      }

      return { ...state, detailMessages };
    },
  },

  /**
   * Effects/Actions
   */
  effects: () => ({
    /**
     * Get All Locations
     *
     * @return {Promise}
     */
    sendMessage(data) {
      return new Promise(async (resolve, reject) => {
        axios
          .post(`${config.apiUrl}/api/v1/message`, data)
          .then(res => {
            // this.replaceLocations(res.data);
            resolve();
          })
          .catch(reject);
      });
    },
    sendBook(data) {
      return new Promise(async (resolve, reject) => {
        axios
          .post(`${config.apiUrl}/api/v1/book-a-tour`, data)
          .then(res => {
            // this.replaceLocations(res.data);
            resolve();
          })
          .catch(reject);
      });
    },

    getDetailMessages(id) {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${config.apiUrl}/api/v1/message/${id}`)
          .then(res => {
            this.replaceDetailMessages(res.data);
            resolve();
          })
          .catch(reject);
      });
    },

    getMessages() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${config.apiUrl}/api/v1/message`)
          .then(res => {
            this.replaceMessages(res.data);
            resolve();
          })
          .catch(reject);
      });
    },
  }),
};
