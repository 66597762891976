import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import img2 from '../../../../images/emji-home.png';
import img3 from '../../../../images/emji-space.png';
import img4 from '../../../../images/emji-bar.png';
import img5 from '../../../../images/emji-bandar.png';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

const Home = () => (
  <div>
    <div style={{ display: 'none' }}>
      <Link to="/backoffice/login"></Link>
      <Link to="/backoffice"></Link>
      <Link to="/dashboard"></Link>
    </div>
    <Container>
      <Slider {...settings}>
        <img src={img2} width="100%" alt="Emji Space" />
        <img src="/hb-1.jpg" width="100%" height="479px" alt="Emji Space" />
        <img src="/hb-2.jpg" width="100%" height="479px" alt="Emji Space" />
      </Slider>
    </Container>
    <Container className="mt-5">
      <Row>
        <Col md="4">
          <Link
            className={`nav-link ${window.location.pathname === '/emji/space' &&
              'active'}`}
            to="/emji/space"
          >
            <img src={img3} width="100%" alt="Emji Space Home" />
          </Link>
        </Col>
        <Col md="4">
          <Link
            className={`nav-link ${window.location.pathname ===
              '/emji/coffee' && 'active'}`}
            to="/emji/coffee"
          >
            <img src={img4} width="100%" alt="Emji Space Home" />
          </Link>
        </Col>
        <Col md="4">
          <Link
            className={`nav-link ${window.location.pathname ===
              '/emji/bandar-coffee' && 'active'}`}
            to="/"
          >
            <img src="/cs.jpg" width="100%" alt="Emji Space Home" />
          </Link>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Home;
