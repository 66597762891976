import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import Gallery from '../../../containers/Backoffice/Gallery';
import { Table } from 'reactstrap';
import ImageUploader from 'react-images-upload';
import config from '../../../constants/config';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function isEmpty(obj) {
  if (obj === 'null') {
    return true;
  } else if (obj === undefined) {
    return true;
  } else {
    return Object.keys(obj).length === 0;
  }
}

class Uploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pictures: [] };
    this.onDrop = this.onDrop.bind(this);
    this.upload = this.upload.bind(this);
  }

  upload = e => {
    e.preventDefault();
    const { uploadImage, history } = this.props;
    return uploadImage(this.state.pictures)
      .then(() => {
        return (
          setTimeout(() => history.push('/backoffice'), 1000),
          setTimeout(() => history.push('/backoffice/gallery'), 1100)
        );
      })
      .catch(() => {});
  };

  onDrop(picture) {
    this.setState({
      pictures: this.state.pictures.concat(picture),
    });
  }

  render() {
    return (
      <div>
        <ModalBody>
          <ImageUploader
            withPreview={true}
            withIcon={true}
            singleImage={true}
            buttonText="Choose images"
            onChange={this.onDrop}
            imgExtension={['.jpg', '.gif', '.png', '.gif']}
            maxFileSize={5242880}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.upload}>
            Upload
          </Button>
          <Button color="secondary" onClick={this.props.toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </div>
    );
  }
}

const ModalUpload = props => {
  const { className } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Button outline color="primary" onClick={toggle}>
        Upload New Picture
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Upload Image</ModalHeader>
        <Uploader {...props} />
      </Modal>
    </div>
  );
};

const Page = props => {
  console.log(props.deleteGallery,props)
  useEffect(() => {
    props.fetchGallery();
    // console.log(props)
  }, []);
  return (
    <div>
      <Row className="pt-5">
        <Col lg={6} xs={12}>
          <h1>Gallery page</h1>
        </Col>
        <Col lg={6} xs={12} className="text-right">
          <ModalUpload {...props} />
        </Col>

        <Table hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Image</th>
              <th className="text-right">Action</th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(props.allImage) &&
              props.allImage.map((item, index) => {
                return (
                  <tr>
                    <th scope="row">{index + 1}</th>
                    <td>
                      <img
                        src={`${config.apiUrl}${item.url}`}
                        style={{ width: '10%' }}
                        alt="emji gallery"
                      />
                    </td>
                    <td
                      style={{
                        verticalAlign: 'middle',
                        textAlign: 'right',
                        minWidth: '25vh',
                      }}
                    >
                      <Button
                        size="sm"
                        outline
                        color="danger"
                        onClick={() => props.deleteGallery(item.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Row>
    </div>
  );
};

const GaleryBO = props => {
  return <Gallery {...props} Layout={Page} />;
};

const mapStateToProps = state => ({
  allImage: state.gallery.galleryAll || {},
});

const mapDispatchToProps = dispatch => ({
  uploadImage: dispatch.gallery.uploadImage,
  fetchGallery: dispatch.gallery.fetchGallery,
  deleteGallery: dispatch.gallery.deleteGallery,
});

export default connect(mapStateToProps, mapDispatchToProps)(GaleryBO);
