import React, { useEffect } from 'react';
import img2 from '../../../../../images/space/background.png';
import img4 from '../Images/background-black.png';
import Slider from 'react-slick';
import benefit from '../Images/benefit.png';
import about from '../Images/about.png';
import './index.css';
import { Link } from 'react-router-dom';
import config from '../../../../../constants/config';
import { ReactComponent as Wifi } from './wifi.svg';
import { ReactComponent as LocIcon } from './location.svg';
import { ReactComponent as OnlineIcon } from './online.svg';
import { ReactComponent as WSIcon } from './workshop.svg';
import { ReactComponent as ProjectorIcon } from './projector.svg';
import CoffeeIcon from './coffeecup.png';
import { ReactComponent as DateIcon } from './date.svg';
import { ReactComponent as FlexibleIcon } from './flex.svg';

import {
  Container,
  Row,
  Alert,
  Col,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  Button,
  FormGroup,
  Input,
} from 'reactstrap';
import '../Space.css';
import { connect } from 'react-redux';
// import { ReactComponent } from '*.svg';


function formatMoney(amount, decimalCount = 0, decimal = ',', thousands = '.') {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
        Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
  }
}

function isEmpty(obj) {
  if (obj === 'null') {
    return true;
  } else if (obj === undefined) {
    return true;
  } else {
    return Object.keys(obj).length === 0;
  }
}
class LocationSlider extends React.Component {
  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      arrows: false,
      slidesToShow: 3.5,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1.2,
            slidesToScroll: 1,
          },
        },
      ],
    };
    const { locations } = this.props;
    return (
      <Slider {...settings} className="mt-5">
        {!isEmpty(locations) &&
          locations.map((item, index) => {
            return (
              <Col xs="12" key="index">
                <Card className="b__card">
                  <CardImg
                    top
                    width="100%"
                    style={{ maxHeight: '181px', minHeight: '181px' }}
                    src={`${config.apiUrl}${item.image[0]}`}
                    alt="Card image cap"
                  />
                  <CardBody>
                    <CardTitle className="space__card-title">
                      {item.name}
                    </CardTitle>
                    <CardText className="space__paragraph space__card-caption">
                      {item.address}
                    </CardText>
                    <Link to={`/emji/space/location/${item.id}`}>
                      <Button className="btn__location">
                        LOCATION{' '}
                        <svg
                          style={{
                            verticalAlign: 'top',
                            marginTop: '2px',
                            marginLeft: '.2em',
                          }}
                          width="21"
                          height="15"
                          viewBox="0 0 21 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M20.714 6.80951L14.4648 0.560254C14.0835 0.178971 13.4651 0.178971 13.0838 0.560254C12.7025 0.941615 12.7025 1.55982 13.0838 1.94118L17.6662 6.52353H0.976446C0.437214 6.52353 0 6.96074 0 7.49997C0 8.03913 0.437214 8.47642 0.976446 8.47642H17.6662L13.084 13.0588C12.7026 13.4401 12.7026 14.0583 13.084 14.4397C13.2746 14.6302 13.5246 14.7257 13.7745 14.7257C14.0243 14.7257 14.2742 14.6302 14.4649 14.4397L20.714 8.19044C21.0954 7.80908 21.0954 7.19087 20.714 6.80951Z"
                            fill="black"
                          />
                        </svg>
                      </Button>
                    </Link>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
      </Slider>
    );
  }
}

class GallerySlider extends React.Component {
  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      arrows: false,
      slidesToShow: 3.5,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1.2,
            slidesToScroll: 1,
          },
        },
      ],
    };
    const { gallery } = this.props;
    return (
      <Slider {...settings} className="mt-5">
        {!isEmpty(gallery) &&
          gallery.map((item, index) => {
            return (
              <Col xs="12" key="index">
                <Card>
                  <CardImg
                    top
                    width="100%"
                    src={`${config.apiUrl}${item.url}`}
                    alt="Card image cap"
                  />
                </Card>
              </Col>
            );
          })}
      </Slider>
    );
  }
}

class FormContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      full_name: '',
      message: '',
      phone_number: '',
      email: '',
      successMessage: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  componentDidMount() {
    const { history } = this.props;
    if (history.location.search === '?success') {
      this.setState({ successMessage: true });
      setTimeout(() => this.setState({ successMessage: false }), 5000);
    }
    // window.scrollTo(0, 0);
  }

  handleSubmit = e => {
    e.preventDefault();
    const { sendMessage, history } = this.props;

    return sendMessage(this.state)
      .then(() => setTimeout(() => history.push('/'), 1000))
      .then(() => setTimeout(() => history.push('/emji/space?success'), 1001))
      .catch(() => { });
  };
  render() {
    return (
      <Row>
        <Col xs={12}>
          {this.state.successMessage && (
            <Alert color="success">
              Your massages successfuly sent! We will reach you soon.
            </Alert>
          )}
        </Col>
        <Col md={3} xs={12} className="text-left">
          <h5 className="space__contact-h5">Mediterania Group</h5>
          <p className="space__contact-p">
            The Bellagio Mansion Unit G-01, Jl. Mega Kuningan Timur II <br />
            Kuningan, East Kuningan, Setiabudi, South <br /> Jakarta
          </p>
          <a href="tel:+6281776539000">
            <svg
              className="mr-3"
              width="51"
              height="51"
              viewBox="0 0 51 51"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="51" height="51" fill="black" />
              <path
                d="M36.9755 32.918C37.0263 33.3074 36.9077 33.646 36.62 33.9337L33.2427 37.2855C33.0903 37.4548 32.8915 37.5988 32.646 37.7172C32.4005 37.8358 32.1593 37.9119 31.9224 37.9458C31.9055 37.9458 31.8546 37.9501 31.7699 37.9586C31.6854 37.967 31.5753 37.9713 31.4399 37.9713C31.1182 37.9713 30.5976 37.9163 29.8781 37.8062C29.1586 37.6961 28.2783 37.4252 27.2373 36.9936C26.196 36.5619 25.0153 35.9143 23.6948 35.051C22.3744 34.1878 20.9693 33.0027 19.4796 31.496C18.2945 30.3279 17.3127 29.2106 16.5339 28.1441C15.7552 27.0775 15.1288 26.0914 14.6548 25.1857C14.1808 24.2801 13.8253 23.459 13.5883 22.7226C13.3513 21.9862 13.1904 21.3514 13.1058 20.8181C13.0212 20.2848 12.9873 19.8659 13.0042 19.5611C13.0212 19.2564 13.0296 19.0871 13.0296 19.0533C13.0635 18.8163 13.1397 18.575 13.2582 18.3296C13.3767 18.0841 13.5206 17.8852 13.6899 17.7328L17.0672 14.3555C17.3042 14.1185 17.575 14 17.8797 14C18.0998 14 18.2945 14.0635 18.4638 14.1904C18.6331 14.3174 18.777 14.474 18.8955 14.6602L21.6126 19.8151C21.7649 20.0859 21.8072 20.3822 21.7395 20.7038C21.6718 21.0255 21.5279 21.2963 21.3078 21.5164L20.0636 22.7607C20.0297 22.7945 20.0001 22.8496 19.9747 22.9257C19.9493 23.0019 19.9366 23.0654 19.9366 23.1162C20.0043 23.4717 20.1567 23.878 20.3937 24.3351C20.5968 24.7414 20.91 25.2365 21.3332 25.8206C21.7565 26.4046 22.3574 27.0775 23.1362 27.8393C23.898 28.6181 24.5751 29.2232 25.1676 29.655C25.76 30.0865 26.2553 30.4041 26.6532 30.6072C27.051 30.8104 27.3557 30.9331 27.5673 30.9753L27.8846 31.0388C27.9185 31.0388 27.9736 31.0261 28.0497 31.0007C28.1259 30.9753 28.1809 30.9457 28.2148 30.9118L29.6622 29.439C29.9671 29.1682 30.3224 29.0327 30.7288 29.0327C31.0166 29.0327 31.245 29.0835 31.4143 29.1851H31.4397L36.3405 32.0799C36.6962 32.3002 36.9077 32.5794 36.9755 32.918Z"
                fill="white"
              />
            </svg>
          </a><a href="https://wa.me/6281776539000">
            <svg
              className="mr-3"
              width="51"
              height="51"
              viewBox="0 0 51 51"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="51" height="51" fill="black" />
              <path
                d="M41 25.5879C41 34.197 33.9675 41.1758 25.2908 41.1758C22.5364 41.1758 19.9486 40.4718 17.6972 39.2363L9 42L11.8356 33.6366C10.4052 31.2878 9.58133 28.533 9.58133 25.5879C9.58133 16.9788 16.6146 10 25.2908 10C33.9682 10 41 16.9788 41 25.5879ZM25.2908 12.4825C18.0076 12.4825 12.0834 18.3616 12.0834 25.5879C12.0834 28.4555 13.0181 31.1111 14.5993 33.2715L12.9492 38.1387L18.0247 36.5255C20.11 37.8948 22.6082 38.6933 25.2912 38.6933C32.5733 38.6933 38.4987 32.8149 38.4987 25.5886C38.4987 18.3623 32.5737 12.4825 25.2908 12.4825ZM33.2236 29.178C33.1266 29.019 32.8702 28.923 32.4855 28.7321C32.1001 28.5412 30.2064 27.6164 29.8544 27.4894C29.5013 27.3621 29.2439 27.2981 28.9876 27.6804C28.7312 28.0629 27.9931 28.923 27.768 29.178C27.5433 29.4336 27.3189 29.4656 26.9335 29.2743C26.5488 29.0834 25.3083 28.6795 23.8373 27.3781C22.6928 26.3655 21.9198 25.1154 21.6951 24.7324C21.4708 24.3502 21.6716 24.1436 21.864 23.9534C22.0375 23.782 22.2494 23.5072 22.4418 23.2843C22.6348 23.061 22.6988 22.902 22.8265 22.6468C22.9556 22.3918 22.8912 22.1689 22.7945 21.9772C22.6985 21.7863 21.9276 19.9061 21.6069 19.141C21.2862 18.3765 20.9659 18.5038 20.7408 18.5038C20.5164 18.5038 20.2594 18.4718 20.0027 18.4718C19.746 18.4718 19.3285 18.5675 18.9755 18.9497C18.6228 19.3323 17.6283 20.2567 17.6283 22.1365C17.6283 24.0167 19.0075 25.8332 19.2005 26.0878C19.3929 26.3424 21.8636 30.326 25.7783 31.856C29.6933 33.3852 29.6933 32.875 30.3995 32.811C31.1049 32.7474 32.6772 31.8869 32.9993 30.9952C33.3193 30.102 33.3193 29.3372 33.2236 29.178Z"
                fill="white"
              />
            </svg>
          </a><svg
            className="mr-3"
            width="51"
            height="51"
            viewBox="0 0 51 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="51" height="51" fill="black" />
            <path
              d="M27 29.7139L23.7834 26.8984L14.5869 34.782C14.9211 35.0922 15.3724 35.2853 15.8701 35.2853H38.1299C38.6257 35.2853 39.0751 35.0922 39.4076 34.782L30.2166 26.8984L27 29.7139Z"
              fill="white"
            />
            <path
              d="M39.4131 17.2172C39.0789 16.9052 38.6294 16.7139 38.1299 16.7139H15.8701C15.3743 16.7139 14.9249 16.907 14.5906 17.2209L27 27.8567L39.4131 17.2172Z"
              fill="white"
            />
            <path
              d="M14 18.3447V33.7739L22.9756 26.1466L14 18.3447Z"
              fill="white"
            />
            <path
              d="M31.0244 26.1463L40 33.7736V18.3389L31.0244 26.1463Z"
              fill="white"
            />
          </svg>
        </Col>
        <Col md={9} xs={12}>
          <Row className="pl-md-4 pt-md-0 pt-5">
            <Col md={4}>
              <FormGroup>
                <Input
                  className="border-dark"
                  type="text"
                  name="full_name"
                  id="full_name"
                  value={this.state.full_name}
                  onChange={this.handleChange}
                  placeholder="Name / Company"
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Input
                  className="border-dark"
                  type="email"
                  name="email"
                  id="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  placeholder="Email"
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Input
                  className="border-dark"
                  type="number"
                  name="phone_number"
                  id="phone_number"
                  value={this.state.phone_number}
                  onChange={this.handleChange}
                  placeholder="Mobile Phone"
                />
              </FormGroup>
            </Col>
            <Col xs={12} className="mt-3">
              <FormGroup>
                <Input
                  className="border-dark"
                  type="textarea"
                  name="message"
                  id="message"
                  value={this.state.message}
                  onChange={this.handleChange}
                  placeholder="Your Message"
                  style={{ minHeight: '163px' }}
                />
              </FormGroup>
            </Col>
            <Col xs={12} className="text-md-right text-center">
              <FormGroup>
                <Button
                  className="mt-3"
                  onClick={this.handleSubmit}
                  style={{
                    backgroundColor: '#000',
                    color: 'white',
                    width: '204px',
                  }}
                >
                  SEND
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Col>
        {/* <Col md={3} xs={12} className="text-left d-block d-md-none">
          <h5 className="space__contact-h5">Mediterania Group</h5>
          <p className="space__contact-p">
            The Bellagio Mansion Unit G-01, Jl. Mega Kuningan Timur II <br />
            Kuningan, East Kuningan, Setiabudi, South <br /> Jakarta
          </p>
          <svg
            className="mr-3"
            width="51"
            height="51"
            viewBox="0 0 51 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="51" height="51" fill="black" />
            <path
              d="M36.9755 32.918C37.0263 33.3074 36.9077 33.646 36.62 33.9337L33.2427 37.2855C33.0903 37.4548 32.8915 37.5988 32.646 37.7172C32.4005 37.8358 32.1593 37.9119 31.9224 37.9458C31.9055 37.9458 31.8546 37.9501 31.7699 37.9586C31.6854 37.967 31.5753 37.9713 31.4399 37.9713C31.1182 37.9713 30.5976 37.9163 29.8781 37.8062C29.1586 37.6961 28.2783 37.4252 27.2373 36.9936C26.196 36.5619 25.0153 35.9143 23.6948 35.051C22.3744 34.1878 20.9693 33.0027 19.4796 31.496C18.2945 30.3279 17.3127 29.2106 16.5339 28.1441C15.7552 27.0775 15.1288 26.0914 14.6548 25.1857C14.1808 24.2801 13.8253 23.459 13.5883 22.7226C13.3513 21.9862 13.1904 21.3514 13.1058 20.8181C13.0212 20.2848 12.9873 19.8659 13.0042 19.5611C13.0212 19.2564 13.0296 19.0871 13.0296 19.0533C13.0635 18.8163 13.1397 18.575 13.2582 18.3296C13.3767 18.0841 13.5206 17.8852 13.6899 17.7328L17.0672 14.3555C17.3042 14.1185 17.575 14 17.8797 14C18.0998 14 18.2945 14.0635 18.4638 14.1904C18.6331 14.3174 18.777 14.474 18.8955 14.6602L21.6126 19.8151C21.7649 20.0859 21.8072 20.3822 21.7395 20.7038C21.6718 21.0255 21.5279 21.2963 21.3078 21.5164L20.0636 22.7607C20.0297 22.7945 20.0001 22.8496 19.9747 22.9257C19.9493 23.0019 19.9366 23.0654 19.9366 23.1162C20.0043 23.4717 20.1567 23.878 20.3937 24.3351C20.5968 24.7414 20.91 25.2365 21.3332 25.8206C21.7565 26.4046 22.3574 27.0775 23.1362 27.8393C23.898 28.6181 24.5751 29.2232 25.1676 29.655C25.76 30.0865 26.2553 30.4041 26.6532 30.6072C27.051 30.8104 27.3557 30.9331 27.5673 30.9753L27.8846 31.0388C27.9185 31.0388 27.9736 31.0261 28.0497 31.0007C28.1259 30.9753 28.1809 30.9457 28.2148 30.9118L29.6622 29.439C29.9671 29.1682 30.3224 29.0327 30.7288 29.0327C31.0166 29.0327 31.245 29.0835 31.4143 29.1851H31.4397L36.3405 32.0799C36.6962 32.3002 36.9077 32.5794 36.9755 32.918Z"
              fill="white"
            />
          </svg>
          <svg
            className="mr-3"
            width="51"
            height="51"
            viewBox="0 0 51 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="51" height="51" fill="black" />
            <path
              d="M41 25.5879C41 34.197 33.9675 41.1758 25.2908 41.1758C22.5364 41.1758 19.9486 40.4718 17.6972 39.2363L9 42L11.8356 33.6366C10.4052 31.2878 9.58133 28.533 9.58133 25.5879C9.58133 16.9788 16.6146 10 25.2908 10C33.9682 10 41 16.9788 41 25.5879ZM25.2908 12.4825C18.0076 12.4825 12.0834 18.3616 12.0834 25.5879C12.0834 28.4555 13.0181 31.1111 14.5993 33.2715L12.9492 38.1387L18.0247 36.5255C20.11 37.8948 22.6082 38.6933 25.2912 38.6933C32.5733 38.6933 38.4987 32.8149 38.4987 25.5886C38.4987 18.3623 32.5737 12.4825 25.2908 12.4825ZM33.2236 29.178C33.1266 29.019 32.8702 28.923 32.4855 28.7321C32.1001 28.5412 30.2064 27.6164 29.8544 27.4894C29.5013 27.3621 29.2439 27.2981 28.9876 27.6804C28.7312 28.0629 27.9931 28.923 27.768 29.178C27.5433 29.4336 27.3189 29.4656 26.9335 29.2743C26.5488 29.0834 25.3083 28.6795 23.8373 27.3781C22.6928 26.3655 21.9198 25.1154 21.6951 24.7324C21.4708 24.3502 21.6716 24.1436 21.864 23.9534C22.0375 23.782 22.2494 23.5072 22.4418 23.2843C22.6348 23.061 22.6988 22.902 22.8265 22.6468C22.9556 22.3918 22.8912 22.1689 22.7945 21.9772C22.6985 21.7863 21.9276 19.9061 21.6069 19.141C21.2862 18.3765 20.9659 18.5038 20.7408 18.5038C20.5164 18.5038 20.2594 18.4718 20.0027 18.4718C19.746 18.4718 19.3285 18.5675 18.9755 18.9497C18.6228 19.3323 17.6283 20.2567 17.6283 22.1365C17.6283 24.0167 19.0075 25.8332 19.2005 26.0878C19.3929 26.3424 21.8636 30.326 25.7783 31.856C29.6933 33.3852 29.6933 32.875 30.3995 32.811C31.1049 32.7474 32.6772 31.8869 32.9993 30.9952C33.3193 30.102 33.3193 29.3372 33.2236 29.178Z"
              fill="white"
            />
          </svg>
          <svg
            className="mr-3"
            width="51"
            height="51"
            viewBox="0 0 51 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="51" height="51" fill="black" />
            <path
              d="M27 29.7139L23.7834 26.8984L14.5869 34.782C14.9211 35.0922 15.3724 35.2853 15.8701 35.2853H38.1299C38.6257 35.2853 39.0751 35.0922 39.4076 34.782L30.2166 26.8984L27 29.7139Z"
              fill="white"
            />
            <path
              d="M39.4131 17.2172C39.0789 16.9052 38.6294 16.7139 38.1299 16.7139H15.8701C15.3743 16.7139 14.9249 16.907 14.5906 17.2209L27 27.8567L39.4131 17.2172Z"
              fill="white"
            />
            <path
              d="M14 18.3447V33.7739L22.9756 26.1466L14 18.3447Z"
              fill="white"
            />
            <path
              d="M31.0244 26.1463L40 33.7736V18.3389L31.0244 26.1463Z"
              fill="white"
            />
          </svg>
        </Col>
       */}
      </Row>
    );
  }
}

const HomeSpace = props => {
  useEffect(() => {
    props.fetchLocation();
    props.fetchProduct();
    props.fetchGallery();
  }, []);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <div>
      <Slider {...settings}>
        <img src={img2} width="100%" alt="Emji Space" />
        <img src="/home-banner-1.jpg" width="100%" height="479px" alt="Emji Space" />
        <img src="/home-banner-2.jpg" width="100%" height="479px" alt="Emji Space" />
      </Slider>
      <Container className="mt-5 space__bg-color" id="about">
        <Row>
          <Col
            xs="12"
            lg="6"
            className="pl-lg-5 pt-md-5"
            style={{ color: 'white' }}
          >
            <p>About Us</p>
            <h1>Empowering, Engaging, Educating, Community Space</h1>
            <p>
              EMJI SPACE along with EMJI COFFEE BAR, bringing the name of
              Mediterania Group, we provide all in one working, event and
              hangout space for you. Delivering cozy and affordable places with
              mission to become your leading community space and the beginning
              to your business journey.
            </p>
          </Col>
          <Col xs="12" md="6">
            <img src={about} className="about__image" alt="about_space" />
          </Col>
        </Row>
      </Container>
      <Container
        className="space__background mt-5 pt-5"
        style={{ backgroundImage: `url(${img4})` }}
      >
        <h1 className="text-center space__locations-heading " id="`locations`">
          Locations
        </h1>
        <LocationSlider {...props} />
      </Container>

      <Container className="mt-5 pt-5 pb-5" id="space">
        <Row>
          {props.products[0] && (
            <Col xs="12" md="4" className="p-2" style={{ maxHeight: '317px' }} >
              <img
                src={`${config.apiUrl}${props.products[0].image[0]}`}
                alt=""
                style={{ minHeight: '299px', maxHeight: '299px' }}
              />
              <Card>
                <CardBody className="space__card">
                  <CardTitle className="font-card">
                    {props.products[0].name}
                  </CardTitle>
                  <CardText className="font-p">
                    {console.log(props.products[0].type_price[0].split(':'))}
                    Starting From<br></br>IDR {formatMoney(props.products[0].type_price[0].split(':')[1])}/{props.products[0].type_price[0].split(':')[0]}
                  </CardText>
                  <Link to={`/emji/space/products/${props.products[0].id}`}>
                    <Button size="sm" className="space__btn-product">
                      LEARN MORE
                      <svg
                        width="13"
                        height="15"
                        viewBox="0 0 17 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.9165 6.80951L11.1146 0.560254C10.8216 0.178971 10.3465 0.178971 10.0535 0.560254C9.76048 0.941615 9.76048 1.55982 10.0535 1.94118L13.5746 6.52353H0.750294C0.335951 6.52353 0 6.96074 0 7.49997C0 8.03913 0.335951 8.47642 0.750294 8.47642H13.5746L10.0536 13.0588C9.7606 13.4401 9.7606 14.0583 10.0536 14.4397C10.2001 14.6302 10.3922 14.7257 10.5842 14.7257C10.7762 14.7257 10.9682 14.6302 11.1147 14.4397L15.9165 8.19044C16.2095 7.80908 16.2095 7.19087 15.9165 6.80951Z"
                          fill="black"
                        />
                      </svg>
                    </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
          )}
          {props.products[1] && (
            <Col xs="12" md="4" className="p-2" style={{ maxHeight: '317px' }} >
              <img
                src={`${config.apiUrl}${props.products[1].image[0]}`}
                alt=""
                style={{ minHeight: '299px', maxHeight: '299px' }}
              />
              <Card>
                <CardBody className="space__card">
                  <CardTitle className="font-card">
                    {props.products[1].name}
                  </CardTitle>
                  <CardText className="font-p">
                    Starting From<br></br>IDR {formatMoney(props.products[1].type_price[0].split(':')[1])}/{props.products[1].type_price[0].split(':')[0]}
                  </CardText>
                  <Link to={`/emji/space/products/${props.products[1].id}`}>
                    <Button size="sm" className="space__btn-product">
                      LEARN MORE
                      <svg
                        width="13"
                        height="15"
                        viewBox="0 0 17 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.9165 6.80951L11.1146 0.560254C10.8216 0.178971 10.3465 0.178971 10.0535 0.560254C9.76048 0.941615 9.76048 1.55982 10.0535 1.94118L13.5746 6.52353H0.750294C0.335951 6.52353 0 6.96074 0 7.49997C0 8.03913 0.335951 8.47642 0.750294 8.47642H13.5746L10.0536 13.0588C9.7606 13.4401 9.7606 14.0583 10.0536 14.4397C10.2001 14.6302 10.3922 14.7257 10.5842 14.7257C10.7762 14.7257 10.9682 14.6302 11.1147 14.4397L15.9165 8.19044C16.2095 7.80908 16.2095 7.19087 15.9165 6.80951Z"
                          fill="black"
                        />
                      </svg>
                    </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
          )}

          {props.products[2] && (
            <Col xs="12" md="4" className="p-2" style={{ maxHeight: '317px' }} >
              <img
                src={`${config.apiUrl}${props.products[2].image[0]}`}
                alt=""
                style={{ minHeight: '299px', maxHeight: '299px' }}
              />
              <Card>
                <CardBody className="space__card">
                  <CardTitle className="font-card">
                    {props.products[2].name}
                  </CardTitle>
                  <CardText className="font-p">
                    Starting From<br></br>IDR {formatMoney(props.products[2].type_price[0].split(':')[1])}/{props.products[2].type_price[0].split(':')[0]}
                  </CardText>
                  <Link to={`/emji/space/products/${props.products[2].id}`}>
                    <Button size="sm" className="space__btn-product">
                      LEARN MORE
                      <svg
                        width="13"
                        height="15"
                        viewBox="0 0 17 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.9165 6.80951L11.1146 0.560254C10.8216 0.178971 10.3465 0.178971 10.0535 0.560254C9.76048 0.941615 9.76048 1.55982 10.0535 1.94118L13.5746 6.52353H0.750294C0.335951 6.52353 0 6.96074 0 7.49997C0 8.03913 0.335951 8.47642 0.750294 8.47642H13.5746L10.0536 13.0588C9.7606 13.4401 9.7606 14.0583 10.0536 14.4397C10.2001 14.6302 10.3922 14.7257 10.5842 14.7257C10.7762 14.7257 10.9682 14.6302 11.1147 14.4397L15.9165 8.19044C16.2095 7.80908 16.2095 7.19087 15.9165 6.80951Z"
                          fill="black"
                        />
                      </svg>
                    </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
        <Row>
          {props.products[3] && (
            <Col xs="12" md="6">
              <img
                src={`${config.apiUrl}${props.products[3].image[0]}`}
                alt=""
                style={{ minHeight: '299px', maxHeight: '299px' }}
              />
              <Card>
                <CardBody className="space__card">
                  <CardTitle className="font-card">
                    {props.products[3].name}
                  </CardTitle>
                  <CardText className="font-p">
                    Starting From<br></br>IDR {formatMoney(props.products[3].type_price[0].split(':')[1])}/{props.products[3].type_price[0].split(':')[0]}
                  </CardText>
                  <Link to={`/emji/space/products/${props.products[3].id}`}>
                    <Button size="sm" className="space__btn-product">
                      LEARN MORE
                      <svg
                        width="13"
                        height="15"
                        viewBox="0 0 17 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.9165 6.80951L11.1146 0.560254C10.8216 0.178971 10.3465 0.178971 10.0535 0.560254C9.76048 0.941615 9.76048 1.55982 10.0535 1.94118L13.5746 6.52353H0.750294C0.335951 6.52353 0 6.96074 0 7.49997C0 8.03913 0.335951 8.47642 0.750294 8.47642H13.5746L10.0536 13.0588C9.7606 13.4401 9.7606 14.0583 10.0536 14.4397C10.2001 14.6302 10.3922 14.7257 10.5842 14.7257C10.7762 14.7257 10.9682 14.6302 11.1147 14.4397L15.9165 8.19044C16.2095 7.80908 16.2095 7.19087 15.9165 6.80951Z"
                          fill="black"
                        />
                      </svg>
                    </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
          )}
          {props.products[4] && (
            <Col xs="12" md="6">
              <img
                src={`${config.apiUrl}${props.products[4].image[0]}`}
                alt=""
                style={{ minHeight: '299px', maxHeight: '299px' }}
              />
              <Card>
                <CardBody className="space__card">
                  <CardTitle className="font-card">
                    {props.products[4].name}
                  </CardTitle>
                  <CardText className="font-p">
                    Starting From<br></br>IDR {formatMoney(props.products[4].type_price[0].split(':')[1])}/{props.products[4].type_price[0].split(':')[0]}
                  </CardText>
                  <Link to={`/emji/space/products/${props.products[4].id}`}>
                    <Button size="sm" className="space__btn-product">
                      LEARN MORE
                      <svg
                        width="13"
                        height="15"
                        viewBox="0 0 17 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.9165 6.80951L11.1146 0.560254C10.8216 0.178971 10.3465 0.178971 10.0535 0.560254C9.76048 0.941615 9.76048 1.55982 10.0535 1.94118L13.5746 6.52353H0.750294C0.335951 6.52353 0 6.96074 0 7.49997C0 8.03913 0.335951 8.47642 0.750294 8.47642H13.5746L10.0536 13.0588C9.7606 13.4401 9.7606 14.0583 10.0536 14.4397C10.2001 14.6302 10.3922 14.7257 10.5842 14.7257C10.7762 14.7257 10.9682 14.6302 11.1147 14.4397L15.9165 8.19044C16.2095 7.80908 16.2095 7.19087 15.9165 6.80951Z"
                          fill="black"
                        />
                      </svg>
                    </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </Container>
      <Container className="mt-5 text-center p-4" id="facility">
        <Row className="space__facility">
          <Col
            className="pt-5"
            xs="12"
            md="4"
            style={{ backgroundColor: 'black' }}
          >
            <h3
              className="mt-5 pt-4"
              style={{ fontSize: '45px', color: '#FFFFFF' }}
            >
              Facility
            </h3>
          </Col>
          <Col xs="12" md="8" className="p-3 p-md-5">
            <Row className="mt-4">
              <Col xs="4" md="3">
                <LocIcon />
                <p className="mt-2">Strategic location</p>
              </Col>
              <Col xs="4" md="3">
                <DateIcon />
                <p className="mt-2">Mon - Sun Open</p>
              </Col>
              <Col xs="4" md="3">
                <div className="div__svg" >
                  <img src={CoffeeIcon} alt="coffee icon" />
                </div>
                <p className="mt-2">Coffee and Mineral Water</p>
              </Col>
              <Col xs="4" md="3">
                <FlexibleIcon />
                <p className="mt-2">Flexible Booking Schedule</p>
              </Col>
              <Col xs="4" md="3">
                <OnlineIcon />
                <p className="mt-2">Easy Online Booking</p>
              </Col>
              <Col xs="4" md="3">
                <WSIcon />
                <p className="mt-2">Exclusive Events and Workshops</p>
              </Col>
              <Col xs="4" md="3">
                <ProjectorIcon />
                <p className="">Projector for Meeting and Events</p>
              </Col>
              <Col xs="4" md="3">
                <Wifi />
                <p className="">High Speed Wifi</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container className="mt-5" id="benefit">
        <Row>
          <Col xs="12" md="6" className="mb-4">
            <img src={benefit} alt="benefit" width="100%" className="pt-5" />
          </Col>
          <Col xs="12" md="6" className="pr-md-5 pl-md-5">
            <p style={{ fontSize: '18px', fontWeight: 'bold' }}>BENEFITS</p>
            <h1 className="benefit__sub d-none d-md-block">
              So Many Office Space, <br /> But Only Here <br />
              With Us Where You Get <br /> To Design Your Own <br /> Start Up
              Space
            </h1>
            <p className="benefit__sub d-block d-md-none">
              So Many Office Space, But Only Here With Us Where You Get To
              Design Your Own Start Up Space
            </p>
            <p
              style={{ fontSize: '15px', lineHeight: '19px' }}
              className="d-none d-md-block"
            >
              We are proud of what we have come up to at <br />
              our spaces, but we do care of your <br />
              convenience and comfort at each office <br /> spaces. By renting
              our upcoming office <br />
              spaces, we give you access to design and <br />
              visualize your own concept for your own <br /> office space and we
              will make it happen for <br /> you.
            </p>
            <p
              style={{ fontSize: '15px', lineHeight: '19px' }}
              className=" d-block d-md-none"
            >
              We are proud of what we have come up to at our spaces, but we do
              care of your convenience and comfort at each office spaces. By
              renting our upcoming office spaces, we give you access to design
              and visualize your own concept for your own office space and we
              will make it happen for you.
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="mt-5" id="gallery">
        <Row>
          <Col xs="12" className="mb-4 text-center">
            <h3 className="space__locations-heading" style={{ color: '#000' }}>
              Gallery
            </h3>
          </Col>
          <Col xs="12" md="12" className="pr-md-5 pl-md-5">
            <GallerySlider {...props} />
          </Col>
        </Row>
      </Container>

      <Container id="contact" className="text-center mt-5">
        <h3 className="space__contact mb4">Contact</h3>
        <FormContact {...props} />
      </Container>
    </div>
  );
};

const mapStateToProps = state => ({
  locations: state.locations.all || {},
  products: state.products.all || {},
  gallery: state.gallery.galleryAll || {},
});

const mapDispatchToProps = dispatch => ({
  fetchLocation: dispatch.locations.getLocations,
  fetchProduct: dispatch.products.getProducts,
  fetchGallery: dispatch.gallery.fetchGallery,
  sendMessage: dispatch.book.sendMessage,
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeSpace);
