/* global window */
import React from 'react';
import { Col, Nav, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';

const SidebarNavItems = () => (
  <div>
    <NavItem>
      <Link className={`nav-link ${window.location.pathname === '/backoffice' && 'active'}`} to="/backoffice">
        <i className="icon-home" />
        {' '}
        <span>Home</span>
      </Link>
    </NavItem>
    <NavItem>
      <Link className={`nav-link ${window.location.pathname.startsWith('/backoffice/amenties') && 'active'}`} to="/backoffice/amenties">
        <i className="icon-location-pin" />
        {' '}
        <span>Amenities</span>
      </Link>
    </NavItem>
    <NavItem>
      <Link className={`nav-link ${window.location.pathname.startsWith('/backoffice/locations') && 'active'}`} to="/backoffice/locations">
        <i className="icon-location-pin" />
        {' '}
        <span>Locations</span>
      </Link>
    </NavItem>
    <NavItem>
      <Link className={`nav-link ${window.location.pathname.startsWith('/backoffice/products') && 'active'}`} to="/backoffice/products">
        <i className="icon-organization" />
        {' '}
        <span>Space Products</span>
      </Link>
    </NavItem>
    <NavItem>
      <Link className={`nav-link ${window.location.pathname.startsWith('/backoffice/coffees') && 'active'}`} to="/backoffice/coffees">
        <i className="icon-list" />
        {' '}
        <span>Coffee Products</span>
      </Link>
    </NavItem>
    <NavItem>
      <Link className={`nav-link ${window.location.pathname.startsWith('/backoffice/transactions') && 'active'}`} to="/backoffice/transactions">
        <i className="icon-book-open" />
        {' '}
        <span>Transactions</span>
      </Link>
    </NavItem>
    <NavItem>
      <Link className={`nav-link ${window.location.pathname.startsWith('/backoffice/gallery') && 'active'}`} to="/backoffice/gallery">
        <i className="icon-film" />
        {' '}
        <span>Gallery</span>
      </Link>
    </NavItem>
    <NavItem>
      <Link className={`nav-link ${window.location.pathname.startsWith('/backoffice/messages') && 'active'}`} to="/backoffice/messages">
        <i className="icon-envelope" />
        {' '}
        <span>Messages</span>
      </Link>
    </NavItem>
    <NavItem>
      <Link className={`nav-link ${window.location.pathname.startsWith('/backoffice/promocodes') && 'active'}`} to="/backoffice/promocodes">
        <i className="icon-calculator" />
        {' '}
        <span>Promo Codes</span>
      </Link>
    </NavItem>
  </div>
);

const Sidebar = () => (
  <div>
    <Col sm="3" md="2" className="d-none d-sm-block sidebar">
      <Nav vertical>
        {SidebarNavItems()}
      </Nav>
    </Col>
  </div>
);

export { Sidebar, SidebarNavItems };
