import initState from '../store/recipes';
import axios from 'axios';
import config from '../constants/config'

export default {
  /**
   *  Initial state
   */
  state: {
    products: initState.products,
  },

  /**
   * Reducers
   */
  reducers: {
    replaceInstagramFeed(state, payload) {
      let feed = [];
      // Pick out the props I need
      if (payload && typeof payload === 'object') {
        feed = payload.map((item,index) => ({
          url:payload[index]["node"].display_url,
        }));
      }

      return { ...state,feed};
    },
    replaceInstagramFeedSpace(state, payload) {
      let feedSpace = [];
      // Pick out the props I need
      if (payload && typeof payload === 'object') {
        feedSpace = payload.map((item,index) => ({
          url:payload[index]["node"].display_url,
        }));
      }

      return { ...state,feedSpace};
    },
    replaceProducts(state, payload) {
      let all = [];
      // Pick out the props I need
      if (payload && typeof payload === 'object') {
        all = payload.rows.map(item => ({
          id: item.id,
          description: item.description,
          name: item.name,
          benefits: item.benefits,
          image: item.image,
          type_price: item.type_price,
          product_type: item.product_type,
        }));
      }

      return { ...state, all };
    },
    replaceDetailProducts(state, payload) {
      let detailProducts = [];
      // Pick out the props I need
      if (payload && typeof payload === 'object') {
        detailProducts = payload;
      }

      return { ...state, detailProducts };
    },
    imageUrl(state, payload) {
      let imgUrl = payload;

      return { ...state, imgUrl };
    },
  },

  /**
   * Effects/Actions
   */
  effects: () => ({
    /**
     * Get All Products
     *
     * @return {Promise}
     */
    getProducts() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${config.apiUrl}/api/v1/product/`)
          .then(res => {
            this.replaceProducts(res.data);
            resolve();
          })
          .catch(reject);
      });
    },

    getInstagramFeed() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`https://www.instagram.com/emjicoffeebar/?__a=1`)
          .then(res => {
            let data = res.data.graphql.user.edge_owner_to_timeline_media.edges;
            this.replaceInstagramFeed(data);
            resolve();
          })
          .catch(reject);
      });
    },

    getInstagramFeedSpace() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`https://www.instagram.com/emjispace/?__a=1`)
          .then(res => {
            let data = res.data.graphql.user.edge_owner_to_timeline_media.edges;
            this.replaceInstagramFeedSpace(data);
            resolve();
          })
          .catch(reject);
      });
    },
    /**
     * Get All Products
     *
     * @return {Promise}
     */
    getDetailProducts(id) {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${config.apiUrl}/api/v1/product/${id}`)
          .then(res => {
            this.replaceDetailProducts(res.data);
            resolve();
          })
          .catch(reject);
      });
    },
    /**
     * Upload Image
     *
     * @return {Promise}
     */
    uploadImage(formData) {
      let bodyFormData = new FormData();
      bodyFormData.append('image', formData.img);
      // let popo = formData.image[0]
      return new Promise(async (resolve, reject) => {
        axios
          .post(`${config.apiUrl}/upload`, bodyFormData)
          .then(res => {
            this.imageUrl(res.data.path);
            resolve();
          })
          .catch(reject);
      });
    },
    /**
     * Create New Product
     *
     * @return {Promise}
     */
    createProducts(formData) {
      return new Promise(async (resolve, reject) => {
        axios
          .post(`${config.apiUrl}/api/v1/product/`, formData)
          .then(res => {
            // this.replaceProducts(res.data);
            resolve();
          })
          .catch(reject);
      });
    },
    /**
     * Create New Product
     *
     * @return {Promise}
     */
    updateProducts(formData) {
      return new Promise(async (resolve, reject) => {
        axios
          .put(`${config.apiUrl}/api/v1/product/${formData.id}`, formData)
          .then(res => {
            resolve();
          })
          .catch(reject);
      });
    },
    /**
     * Delete Product
     *
     * @return {Promise}
     */
    deleteProducts(formData) {
      return new Promise(async (resolve, reject) => {
        axios
          .delete(`${config.apiUrl}/api/v1/product/${formData}`)
          .then(res => {
            this.getProducts();
            resolve();
          })
          .catch(reject);
      });
    },
  }),
};
