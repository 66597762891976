import React from 'react';
import {
  Row,
  Col,
  Card,
  // FormText,
  Form,
  Label,
  Alert,
  Input,
  Button,
  CardBody,
  FormGroup,
  CardHeader,
  CustomInput,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ImageUploader from 'react-images-upload';

class CreateCoffee extends React.Component {
  static defaultProps = {
    error: null,
    success: null,
    member: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      cName: '',
      category: '',
      price: '',
      image: [],
      pictures: [],
      notValid: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onAddPrice = this.onAddPrice.bind(this);
    this.handleChangePrice = this.handleChangePrice.bind(this);
    this.handleLoadImage = this.handleLoadImage.bind(this);
    this.handleImage = this.handleImage.bind(this);
    this.onRmvPrice = this.onRmvPrice.bind(this);
    this.onRmvCategory = this.onRmvCategory.bind(this);
    this.handleChangeBenefit = this.handleChangeBenefit.bind(this);
    this.validation = this.validation.bind(this);
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onDrop(picture) {
    console.log(picture);
    this.setState({
      img: picture[0],
    });
  }

  handleChangePrice = (name, value) => {
    // not allowed AND not working
    this.setState(state => {
      let lp = this.state.coffee_type;
      // lp[e.target.name] = e.target.value;
      // let aa = [];
      lp.forEach((element, index) => {
        if (Object.keys(lp[index])[0] === name) {
          lp[index][name] = value;
        }
      });
      return {
        lp,
      };
    });
  };

  handleChangeBenefit = (index, value) => {
    // not allowed AND not working
    this.setState(state => {
      state.benefits[index] = value;
      return {
        state,
      };
    });
  };

  handleSubmit = () => {
    const { createCoffee, history } = this.props;

    return createCoffee(this.state)
      .then(() => setTimeout(() => history.push('/backoffice/coffees'), 1000))
      .catch(() => {});
  };

  validation = (e) => {
    e.preventDefault();
    const { cName, category, price, img } = this.state;
    if (cName !== '' && category !== '' && price !== '' && img !== null) {
      this.setState({ notValid: false });
      this.handleSubmit();
    } else {
      this.setState({ notValid: true })
      setTimeout(() => this.setState({ notValid: false }), 2500)
      return ;
    }
  };

  handleImage = file => {
    if (file) {
      this.setState(() => ({
        img: file,
      }));
    }
  };

  handleLoadImage = file => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // const listImg = this.state.image.push(new Blob([reader.result], {type: "image/png"}));
        const listImg = this.state.image.push(reader.result);
        // console.log(reader.result);
        // as a good practice, use a function to set state
        this.setState(() => ({
          imagePreviewUrl: listImg,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  onAddPrice = data => {
    // not allowed AND not working
    // eslint-disable-next-line
    this.setState(state => {
      let lp = { [data]: '' };
      const list = this.state.coffee_type.push(lp);
      const pList = this.state.pList;
      pList.push(data);
      return {
        list,
        pList,
      };
    });
  };

  onRmvPrice = data => {
    // not allowed AND not working
    // eslint-disable-next-line
    // console.log()
    this.setState(state => {
      const list = this.state.coffee_type.filter(
        el => Object.keys(el)[0] !== data
      );
      const coffee_type = this.state.coffee_type.filter(
        el => Object.keys(el)[0] !== data
      );
      this.state.coffee_type.filter(el => Object.keys(el)[0] !== data);
      const pList = this.state.pList.filter(el => el !== data);
      // console.log(this.state.coffee_type);
      return {
        coffee_type,
        list,
        pList,
      };
    });
  };

  onAddCategory = data => {
    // not allowed AND not working
    // eslint-disable-next-line
    this.setState(state => {
      state.category = data;
      return {};
    });
  };

  onRmvCategory = data => {
    // not allowed AND not working
    // eslint-disable-next-line
    this.setState(state => {
      const listL = this.state.categorys.filter(
        el => Object.keys(el)[0] !== data
      );
      // console.log(listL,data);
      const categorys = this.state.categorys.filter(
        el => Object.keys(el)[0] !== data
      );
      this.state.categorys.filter(el => Object.keys(el)[0] !== data);
      const lList = this.state.lList.filter(el => el !== data);
      // console.log(this.state.categorys);
      return {
        categorys,
        listL,
        lList,
      };
    });
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  hasBenefit = () =>
    this.setState(prevState => ({ benefit: !prevState.benefit }));

  upload = e => {
    e.preventDefault();
    const { uploadImage } = this.props;
    return (
      uploadImage(this.state)
        .then(() =>
          this.setState(state => {
            state.image = this.props.imgData;
            return {};
          })
        )
        // this.state.image.push(this.props.imgData)
        .catch(() => {})
    );
  };

  render() {
    const { success, error } = this.props;
    const { cName } = this.state;
    return (
      <div>
        <Row className="pt-5">
          <Col lg={{ size: 12 }}>
            {this.state.notValid && (
              <Alert color="danger">
                Tolong lengkapi semua data
              </Alert>
            )}
            <Card>
              <CardHeader>Create Coffee</CardHeader>
              <CardBody>
                {!!success && <Alert color="success">{success}</Alert>}
                {!!error && <Alert color="danger">{error}</Alert>}

                <Form onSubmit={this.handleSubmit}>
                  <FormGroup row>
                    <Label for="exampleEmail" sm={2}>
                      Name
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="text"
                        name="cName"
                        id="cName"
                        placeholder="Coffee name"
                        value={cName}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleText" sm={2}>
                      Price
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="number"
                        name="price"
                        id="price"
                        max={200}
                        value={this.state.price}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="categorySelect" sm={2}>
                      Category
                    </Label>
                    <Col sm={10}>
                      <CustomInput
                        type="select"
                        id="categorySelect"
                        name="categorySelect"
                        value={
                          this.state.category ? this.state.category : 'default'
                        }
                        onChange={e => this.onAddCategory(e.target.value)}
                      >
                        <option value="default" disabled>
                          Select
                        </option>
                        <option value="food">Food</option>
                        <option value="beverage">Beverage</option>
                      </CustomInput>
                    </Col>
                    <hr />
                  </FormGroup>

                  <hr />
                  <FormGroup row>
                    <Label for="exampleFile" sm={2}>
                      Image
                    </Label>
                    <Col xs={8}>
                      <ImageUploader
                        withPreview={true}
                        withIcon={true}
                        singleImage={true}
                        buttonText="Choose images"
                        onChange={this.onDrop}
                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                        maxFileSize={5242880}
                      />
                    </Col>

                    <Col xs={12} className="text-center">
                      <Button
                        color="primary"
                        onClick={this.upload}
                        disabled={this.state.img ? false : true}
                      >
                        Upload
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
            <FormGroup check row className="mt-4 text-right">
              <Col sm={{ size: 10, offset: 2 }}>
                <Button color="primary" outline onClick={this.validation}>
                  Create
                </Button>
              </Col>
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  allCoffee: state.coffee || {},
  imgData: state.coffee.imgUrl || {},
});

const mapDispatchToProps = dispatch => ({
  createCoffee: dispatch.coffee.createCoffee,
  uploadImage: dispatch.coffee.uploadImage,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateCoffee));

// export default withRouter(CreateCoffee);
