import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import Products from '../../../containers/Backoffice/Products';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';

const Page = props => (
  <div>
    <Row className="pt-5">
      <Col lg={6} xs={12}>
        <h1>Products page</h1>
      </Col>
      <Col lg={6} xs={12} className="text-right">
        <Link to="/backoffice/product/new">
          <Button outline color="primary">
            Add New Product
          </Button>
        </Link>
      </Col>

      <Table hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Description</th>
            <th>Price Type</th>
            <th style={{ textAlign: 'center' }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {props.products.all ? (
            props.products.all.map((item, index) => {
              return (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{item.name}</td>
                  <td style={{ maxWidth: '40vh' }}>{item.description}</td>
                  <td>
                    {item.type_price
                      ? item.type_price.map(item => {
                          return <p key={item}>- {item.replace(':', ' : Rp.')}</p>;
                        })
                      : ''}
                  </td>
                  <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                    <Link to={`/backoffice/product/${item.id}`}>
                      <Button
                        size="sm"
                        outline
                        color="primary"
                        className="mr-3"
                      >
                        View
                      </Button>
                    </Link>
                    <Button size="sm" outline color="danger" onClick={() => props.deleteProducts(item.id)}>
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })
          ) : (
            <h1>No Item Found</h1>
          )}
        </tbody>
      </Table>
    </Row>
  </div>
);

const ProductsBO = props => {
  return <Products {...props} Layout={Page} />;
};

const mapStateToProps = state => ({
  allProducts: state.products || {},
  detailProducts: state.detailProducts || {},
});

const mapDispatchToProps = dispatch => ({
  fetchProducts: dispatch.products.getProducts,
  getProducts: dispatch.products.getDetailProducts,
  deleteProducts: dispatch.products.deleteProducts,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsBO);
