import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Admin extends Component {
  static propTypes = {
    Layout: PropTypes.func.isRequired,
    adminLogout: PropTypes.func.isRequired,
    fetchAdmin: PropTypes.func.isRequired,
    admin: PropTypes.shape({}).isRequired,
  };

  state = {
    error: null,
    loading: false,
  };

  componentDidMount = () => this.fetchData();

  fetchData = data => {
    const { fetchAdmin, admin } = this.props;
    const d = { id: admin.id, token: admin.token };
    this.setState({ loading: true });
    return fetchAdmin(d)
      .then(() =>
        this.setState({
          loading: false,
          error: null,
        })
      )
      .catch(err =>
        this.setState({
          loading: false,
          error: err,
        })
      );
  };

  render = () => {
    const { Layout, admin, adminLogout } = this.props;
    const { loading, error } = this.state;

    return (
      <Layout
        error={error}
        loading={loading}
        admin={admin}
        logout={adminLogout}
        reFetch={() => this.fetchData()}
      />
    );
  };
}

const mapStateToProps = state => ({
  admin: state.admin || {},
});

const mapDispatchToProps = dispatch => ({
  adminLogout: dispatch.admin.logout,
  fetchAdmin: dispatch.admin.getAdminData,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Admin);
