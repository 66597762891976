import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';

const UserPrivateRoute = ({
  comp,
  component,
  layout,
  title,
  metaDesc,
  auth,
  ...rest
}) => {
  let LayoutToRender = layout;
  let ComponentToRender = comp;
  return (
    <Route
      {...rest}
      render={props =>
        auth ? (
          <LayoutToRender {...props}>
            <ComponentToRender />
          </LayoutToRender>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = state => ({ auth: state.member.token });

export default withRouter(connect(mapStateToProps)(UserPrivateRoute));
