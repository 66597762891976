import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Transaction extends Component {
  static propTypes = {
    admin: PropTypes.shape({}).isRequired,
  };

  state = {
    error: null,
    loading: false,
  };

  componentDidMount = () => this.getAllTransaction();

  getAllTransaction = data => {
    const { fetchTransactions } = this.props;
    this.setState({ loading: true });
    return fetchTransactions()
      .then(() =>
        this.setState({
          loading: false,
          error: null,
        })
      )
      .catch(err =>
        this.setState({
          loading: false,
          error: err,
        })
      );
  };

  deleteTransactions = id => {
    this.setState({ loading: true });
    // return deleteTransactions(data)
    //   .then(() =>
    //     this.setState({
    //       loading: false,
    //       error: null,
    //     })
    //   )
    //   .catch(err =>
    //     this.setState({
    //       loading: false,
    //       error: err,
    //     })
    //   );
  };

  render = () => {
    const {
      Layout,
      allImage,
      admin,
      adminLogout,
      detailTransaction,
      updateTransactions,
      transaction,
      getDetailTransaction,
      uploadImage,
      history,
    } = this.props;
    const { loading, error } = this.state;
    return (
      <Layout
        error={error}
        loading={loading}
        admin={admin}
        transaction={transaction}
        uploadImage={uploadImage}
        detailTransaction={detailTransaction}
        history={history}
        updateTransactions={updateTransactions}
        getDetailTransaction={getDetailTransaction}
        allImage={allImage}
        fetchTransactions={() => this.getAllTransaction()}
        logout={adminLogout}
        reFetch={() => this.fetchData()}
      />
    );
  };
}

const mapStateToProps = state => ({
  admin: state.admin || {},
  transaction: state.transaction.allTransaction || {},
  detailTransaction: state.transaction.detailTransaction || {},
  //   allImage: state.transaction.transactionAll || {},
});

const mapDispatchToProps = dispatch => ({
  getAllTransaction: dispatch.transaction.getTransactions,
  getDetailTransaction: dispatch.transaction.getDetailTransaction,
  fetchTransactions: dispatch.transaction.getAllTransaction,
  updateTransactions: dispatch.transaction.updateTransactionStatusAdmin,
  //   deleteTransactions: dispatch.transaction.deleteTransactions,
});

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
