import initState from '../store/recipes';
import axios from 'axios';
import config from '../constants/config'

export default {
  namespace: 'locations',
  /**
   *  Initial state
   */
  state: {
    locations: initState.locations,
  },

  /**
   * Reducers
   */
  reducers: {
    replaceLocations(state, payload) {
      let all = [];
      // Pick out the props I need
      if (payload && typeof payload === 'object') {
        all = payload.rows.map(item => ({
          id: item.id,
          description: item.description,
          name: item.name,
          address: item.address,
          image: item.image,
        }));
      }

      return { ...state, all };
    },
    replaceProductsLocation(state, payload) {
      let productsLocation = [];
      // Pick out the props I need
      if (payload && typeof payload === 'object') {
        productsLocation = payload;
      }

      return { ...state, productsLocation };
    },
    replaceDetailLocations(state, payload) {
      let detailLocations = [];
      // Pick out the props I need
      if (payload && typeof payload === 'object') {
        detailLocations = payload;
      }

      return { ...state, detailLocations };
    },
    imageUrl(state, payload) {
      let imgUrl = payload;

      return { ...state, imgUrl };
    },
  },

  /**
   * Effects/Actions
   */
  effects: () => ({
    /**
     * Get All Locations
     *
     * @return {Promise}
     */
    getLocations() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${config.apiUrl}/api/v1/location`)
          .then(res => {
            this.replaceLocations(res.data);
            resolve();
          })
          .catch(reject);
      });
    },
    /**
     * Get All Locations
     *
     * @return {Promise}
     */
    getProductBL(id) {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${config.apiUrl}/api/v1/location/${id}/products`)
          .then(res => {
            this.replaceProductsLocation(res.data);
            resolve();
          })
          .catch(reject);
      });
    },
    /**
     * Get All Locations
     *
     * @return {Promise}
     */
    getDetailLocations(id) {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${config.apiUrl}/api/v1/location/${id}`)
          .then(res => {
            this.replaceDetailLocations(res.data);
            resolve();
          })
          .catch(reject);
      });
    },
    /**
     * Upload Image
     *
     * @return {Promise}
     */
    uploadImage(formData) {
      let bodyFormData = new FormData();
      bodyFormData.append('image', formData.img);
      // let popo = formData.image[0]
      return new Promise(async (resolve, reject) => {
        axios
          .post(`${config.apiUrl}/upload`, bodyFormData)
          .then(res => {
            this.imageUrl(res.data.path);
            resolve();
          })
          .catch(reject);
      });
    },
    /**
     * Create New Product
     *
     * @return {Promise}
     */
    createLocations(formData) {
      return new Promise(async (resolve, reject) => {
        axios
          .post(`${config.apiUrl}/api/v1/location`, formData)
          .then(res => {
            // this.replaceLocations(res.data);
            resolve();
          })
          .catch(reject);
      });
    },
    /**
     * Create New Product
     *
     * @return {Promise}
     */
    updateLocations(formData) {
      return new Promise(async (resolve, reject) => {
        axios
          .put(`${config.apiUrl}/api/v1/location/${formData.id}`, formData)
          .then(res => {
            // this.replaceLocations(res.data);
            resolve();
          })
          .catch(reject);
      });
    },
    /**
     * Delete Product
     *
     * @return {Promise}
     */
    deleteLocations(formData) {
      return new Promise(async (resolve, reject) => {
        axios
          .delete(`${config.apiUrl}/api/v1/location/${formData}`)
          .then(res => {
            this.getLocations();
            resolve();
          })
          .catch(reject);
      });
    },
  }),
};
