import initState from '../store/recipes';
import config from '../constants/config';
import axios from 'axios';

export default {
  /**
   *  Initial state
   */
  state: {
    amenties: initState.amenties,
  },

  /**
   * Reducers
   */
  reducers: {
    replaceUserData(state, payload) {
      let userData = payload;

      return { ...state, userData };
    },
    amentiesAll(state, payload) {
      let amentiesAll = payload;

      return { ...state, amentiesAll };
    },
  },

  /**
   * Effects/Actions
   */
  effects: () => ({
    /**
     * Delete Amenties
     *
     * @return {Promise}
     */
    deleteAmenties(formData) {
      const deleteAmenties = new Promise(async (resolve, reject) => {
        axios
          .delete(`${config.apiUrl}/api/v1/amenties/${formData}`)
          .then(res => {
            this.fetchAmenties();
            resolve(res.data.rows);
          })
          .catch(reject);
      });
      return deleteAmenties;
    },
    fetchAmenties() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${config.apiUrl}/api/v1/users`)
          .then(res => {
            this.amentiesAll(res.data.rows);
            resolve();
          })
          .catch(reject);
      });
    },

    fetchAmentiesBU(id) {
      const test = new Promise(async (resolve, reject) => {
        const res = await axios
          .get(`${config.apiUrl}/api/v1/amenties/user/${id}`)
          .catch(reject);
        if (res.data.rows) {
          this.replaceUserData(res.data.rows);
          resolve(res.data.rows);
        } else {
          resolve(res.data.rows);
        }
      });
      return test;
    },

    createAmenties(formData) {
      const test = new Promise(async (resolve, reject) => {
        const res = await axios
          .post(`${config.apiUrl}/api/v1/amenties`, formData)
          .catch(reject);
        if (res.data) {
          resolve(res.data);
        } else {
          resolve(res.data);
        }
      });
      return test;
    },

    updateAmenties(formData) {
      const test = new Promise(async (resolve, reject) => {
        const res = await axios
          .put(`${config.apiUrl}/api/v1/amenties/${formData.id}`, formData)
          .catch(reject);
        if (res.data) {
          resolve(res.data);
        } else {
          resolve(res.data);
        }
      });
      return test;
    },

    uploadTransaction(formData) {
      console.log(formData);
      let bodyFormData = new FormData();
      bodyFormData.append('image', formData[0]);
      // let popo = formData.image[0]
      if (formData.length === 0) {
        return false;
      } else {
        let uploader = new Promise(async (resolve, reject) => {
          const res = await axios
            .post(`${config.apiUrl}/upload`, bodyFormData)
            .catch(reject);
          //   let { data } = res.data;
          if (res.data) {
            const resData = await axios
              .post(`${config.apiUrl}/api/v1/amenties`, res.data)
              .then(ress => {
                resolve(ress);
              })
              .catch(reject);
            return resData;
          }
        });
        return uploader;
      }
    },
  }),
};
