import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Products extends Component {
  static propTypes = {
    Layout: PropTypes.func.isRequired,
    adminLogout: PropTypes.func.isRequired,
    fetchProducts: PropTypes.func.isRequired,
    admin: PropTypes.shape({}).isRequired,
  };

  state = {
    error: null,
    loading: false,
  };

  componentDidMount = () => this.getAllProduct();

  getAllProduct = data => {
    const { fetchProducts } = this.props;
    this.setState({ loading: true });
    return fetchProducts()
      .then(() =>
        this.setState({
          loading: false,
          error: null,
        })
      )
      .catch(err =>
        this.setState({
          loading: false,
          error: err,
        })
      );
  };

  deleteProducts = (id) => {
    this.setState({ loading: true });
    // return deleteProducts(data)
    //   .then(() =>
    //     this.setState({
    //       loading: false,
    //       error: null,
    //     })
    //   )
    //   .catch(err =>
    //     this.setState({
    //       loading: false,
    //       error: err,
    //     })
    //   );
  };

  render = () => {
    const { Layout, admin, adminLogout,products,deleteProducts } = this.props;
    const { loading, error } = this.state;

    return (
      <Layout
        error={error}
        loading={loading}
        admin={admin}
        products={products}
        deleteProducts={deleteProducts}
        fetchProducts={() => this.getAllProduct()}
        logout={adminLogout}
        reFetch={() => this.fetchData()}
      />
    );
  };
}

const mapStateToProps = state => ({
  admin: state.admin || {},
  products: state.products || {},
});

const mapDispatchToProps = dispatch => ({
  adminLogout: dispatch.admin.logout,
  fetchProducts: dispatch.products.getProducts,
  deleteProducts: dispatch.products.deleteProducts,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Products);
