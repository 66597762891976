import React from 'react';
import PropTypes from 'prop-types';
import { Container} from 'reactstrap';
import { Helmet } from 'react-helmet';
import Member from '../../../containers/Member';
import Header from '../UI/Landing/Header';
import Footer from '../UI/Landing/Footer';

const Template = ({ pageTitle, children }) => (
  <div>
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>

    <Member Layout={Header} />
    <Container fluid>
    {children}
    </Container>
    <Footer/>
  </div>
);

Template.propTypes = {
  pageTitle: PropTypes.string,
  children: PropTypes.element.isRequired,
};

Template.defaultProps = {
  pageTitle: 'Emji',
};

export default Template;
