import React from 'react';
import { Row, Col,Container } from 'reactstrap';
import './Footer.css';

const Footer = () => (
  <footer className="mt-5">
    <div className="footer">
      <Container>
        <Row className="pt-3 pb-3">
          <Col sm="12" className="text-center pt-3">
            <p className="footer__cp-text">
              Copyright © Century 21 Mediterania Group
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  </footer>
);

export default Footer;
