import initState from '../store/recipes';
import axios from 'axios';
import config from '../constants/config';

export default {
  namespace: 'transaction',
  /**
   *  Initial state
   */
  state: {
    transaction: initState.transaction,
  },

  /**
   * Reducers
   */
  reducers: {
    replaceAllTransaction(state, payload) {
      let allTransaction = [];
      // Pick out the props I need
      if (payload && typeof payload === 'object') {
        allTransaction = payload;
      } else {
        allTransaction = [];
      }

      return { ...state, allTransaction };
    },

    replaceDetailTransaction(state, payload) {
      let detailTransaction = [];
      // Pick out the props I need
      if (payload && typeof payload === 'object') {
        detailTransaction = payload;
      }

      return { ...state, detailTransaction };
    },
  },

  /**
   * Effects/Actions
   */
  effects: () => ({
    /**
     * Get All Locations
     *
     * @return {Promise}
     */
    makeTransaction(data) {
      return new Promise(async (resolve, reject) => {
        axios
          .post(`${config.apiUrl}/api/v1/transaction`, data)
          .then(res => {
            resolve();
          })
          .catch(reject);
      });
    },

    updateTransaction(data) {
      let transaction = new Promise(async (resolve, reject) => {
        axios
          .post(`${config.apiUrl}/api/v1/transaction/${data.order_id}`, data)
          .then(res => {
            resolve(res);
          })
          .catch(reject);
      });
      return transaction;
    },

    updateTransactionStatus(data) {
      console.log(data)
      let transaction = new Promise(async (resolve, reject) => {
        axios
          .post(`${config.apiUrl}/api/v1/transaction/status/${data.order_id}`, data)
          .then(res => {
            resolve(res);
          })
          .catch(reject);
      });
      return transaction;
    },

    updateTransactionStatusAdmin(data) {
      console.log(data)
      let transaction = new Promise(async (resolve, reject) => {
        axios
          .post(`${config.apiUrl}/api/v1/transaction/admin/status/${data.order_id}`, data)
          .then(res => {
            resolve(res);
          })
          .catch(reject);
      });
      return transaction;
    },

    checkPromocode(code) {
      let promo = new Promise((resolve, reject) => {
        axios
          .get(`${config.apiUrl}/api/v1/promocode/${code}`)
          .then(res => {
            this.replaceCheckPromocode(res.data);
            resolve(res);
          })
          .catch(reject);
      });
      return promo;
    },

    getDetailTransaction(id) {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${config.apiUrl}/api/v1/transaction/${id}`)
          .then(res => {
            // console.log(res.data)
            this.replaceDetailTransaction(res.data);
            resolve();
          })
          .catch(reject);
      });
    },

    getAllTransaction(id) {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${config.apiUrl}/api/v1/transactions`)
          .then(res => {
            if (res.data.transaction) {
              this.replaceAllTransaction([]);
            } else {
              this.replaceAllTransaction(res.data);
            }
            resolve(res.data);
          })
          .catch(error => console.log(error));
      });
    },

    getAllTransactionBU(id) {
      console.log(id)
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${config.apiUrl}/api/v1/transaction/all/${id}`)
          .then(res => {
            if (res.data.transaction) {
              this.replaceAllTransaction([]);
            } else {
              this.replaceAllTransaction(res.data);
            }
            console.log(res.data)
            resolve(res.data);
          })
          .catch(error => console.log(error));
      });
    },
  }),
};
