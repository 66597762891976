import React, { useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import Messages from '../../../containers/Backoffice/Messages';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';

function isEmpty(obj) {
  if (obj === 'null') {
    return true;
  } else if (obj === undefined) {
    return true;
  } else {
    return Object.keys(obj).length === 0;
  }
}


const Page = props => {
  useEffect(() => {
    props.fetchMessages();
  }, []);
  return (
    <div>
      <Row className="pt-5">
        <Col xs={12}>
          <h1>Messages page</h1>
        </Col>

        <Table hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th style={{ textAlign: 'center' }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(props.message) ? (
              props.message.map((item, index) => {
                return (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.phone}</td>
                    <td
                      style={{ verticalAlign: 'middle', textAlign: 'center' }}
                    >
                      <Link to={`/backoffice/message/${item.id}`}>
                        <Button
                          size="sm"
                          outline
                          color="primary"
                          className="mr-3"
                        >
                          View
                        </Button>
                      </Link>
                    </td>
                  </tr>
                );
              })
            ) : (
              <h1>No Item Found</h1>
            )}
          </tbody>
        </Table>
      </Row>
    </div>
  );
};

const MessagesBO = props => {
  return <Messages {...props} Layout={Page} />;
};

const mapStateToProps = state => ({
  detailMessages: state.detailMessages || {},
  allMessages: state.book.allMessages || {},
});

const mapDispatchToProps = dispatch => ({
  getMessages: dispatch.book.getMessages,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagesBO);
