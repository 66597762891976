import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Gallery extends Component {
  static propTypes = {
    Layout: PropTypes.func.isRequired,
    adminLogout: PropTypes.func.isRequired,
    fetchLocations: PropTypes.func.isRequired,
    admin: PropTypes.shape({}).isRequired,
  };

  state = {
    error: null,
    loading: false,
  };

  componentDidMount = () => this.getAllLocation();

  getAllLocation = data => {
    // const { fetchLocations } = this.props;
    this.setState({ loading: true });
    // return fetchLocations()
    //   .then(() =>
    //     this.setState({
    //       loading: false,
    //       error: null,
    //     })
    //   )
    //   .catch(err =>
    //     this.setState({
    //       loading: false,
    //       error: err,
    //     })
    //   );
  };

  deleteLocations = id => {
    this.setState({ loading: true });
    // return deleteLocations(data)
    //   .then(() =>
    //     this.setState({
    //       loading: false,
    //       error: null,
    //     })
    //   )
    //   .catch(err =>
    //     this.setState({
    //       loading: false,
    //       error: err,
    //     })
    //   );
  };

  render = () => {
    const {
      Layout,
      fetchGallery,
      deleteGallery,
      allImage,
      admin,
      adminLogout,
      gallery,
      uploadImage,
      history
    } = this.props;
    const { loading, error } = this.state;

    return (
      <Layout
        error={error}
        loading={loading}
        admin={admin}
        gallery={gallery}
        uploadImage={uploadImage}
        history={history}
        allImage={allImage}
        fetchGallery={fetchGallery}
        deleteGallery={deleteGallery}
        fetchLocations={() => this.getAllLocation()}
        logout={adminLogout}
        reFetch={() => this.fetchData()}
      />
    );
  };
}

const mapStateToProps = state => ({
  admin: state.admin || {},
  gallery: state.gallery || {},
  allImage: state.gallery.galleryAll || {},
});

const mapDispatchToProps = dispatch => ({
  //   adminLogout: dispatch.admin.logout,
  //   fetchLocations: dispatch.gallery.getLocations,
  //   deleteLocations: dispatch.gallery.deleteLocations,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Gallery);
