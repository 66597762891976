import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import ImageUploader from 'react-images-upload';
// import moment from 'moment';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './index.css';
// function isEmpty(obj) {
//   return Object.keys(obj).length === 0;
// }

function formatMoney(amount, decimalCount = 0, decimal = ',', thousands = '.') {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
  }
}

class TransactionProcess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pictures: [],
      loading: false,
      isEdit: false,
      full_name: this.props.member.full_name,
      company: this.props.member.company,
      email: this.props.member.email,
      phone_number: this.props.member.phone_number,
      token: this.props.member.token,
      id: this.props.member.id,
      order_id: this.props.detailTransaction.order_id,
    };

    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.update = this.update.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  componentDidMount() {
    this.props.getDetailTransaction(window.location.pathname.split('/')[2]);
  }

  toggleEdit = () => {
    const { isEdit } = this.state;
    this.setState({ isEdit: !isEdit });
  };

  update = e => {
    const { updateProfile } = this.props;
    e.preventDefault();
    this.setState({ loading: true });
    return updateProfile(this.state, this.state.token)
      .then(() =>
        this.setState({
          loading: false,
        })
      )
      .catch(() => {});
  };

  cancelEdit = () => {
    const { full_name, company, phone_number, email } = this.props.member;
    const { isEdit } = this.state;
    this.setState({ full_name: full_name });
    this.setState({ company: company });
    this.setState({ phone_number: phone_number });
    this.setState({ email: email });
    this.setState({ isEdit: !isEdit });
  };

  handleSubmit = data => {
    const { updateTransactionStatus, history } = this.props;
    updateTransactionStatus(this.state)
      .then(res => {
        if (
          res.data !== null ||
          (res.data !== undefined && res.data.status === 200)
        ) {
          this.setState({ success: true });
          setTimeout(() => history.push('/dashboard'), 1000);
        }
      })
      .catch(() => {});
    return;
  };

  upload = data => {
    const { uploadImage } = this.props;
    if (data !== undefined || data !== null) {
      uploadImage(data)
        .then(res => {
          if (res.data !== null || res.data !== undefined) {
            this.setState({ imgUrl: res.data.url });
          }
        })
        .catch(() => {});
    } else {
      return false;
    }
  };

  onDrop(picture) {
    this.setState({
      pictures: this.state.pictures.concat(picture),
    });
    this.upload(picture);
  }
  handleChange = e => this.setState({ [e.target.name]: e.target.value });
  render() {
    return (
      <div className="td__wrapper mb-5 pb-5 pt-3">
        <Container className="text-center mb-5">
          <h3 className="tp__heading">
            Transfer pembayaran ke nomor rekening BCA :
          </h3>
          <Row>
            <Col xs="12">
              <h3 className="mb-0 tp__rekening mt-3">035 322 2021</h3>
              <p className="mb-0 mt-2 mb-5">a/n PT. Emji Space</p>
              <p className="mb-0 mt-4">Jumlah yang harus dibayar</p>
              <h3 className="mb-0 mt-2 td__total-p mb-3">
                {' '}
                Rp. {formatMoney(this.props.detailTransaction.price)}
              </h3>
              <p className="mb-0 mt-5">
                Segera melakukan pembayaran dalam waktu
              </p>
              <p className="mb-0 mt-3"></p>
              <p className="mb-0 mt-3 mb-5"></p>
              <Container>
                <hr className="mt-5" />
              </Container>
              <p className="mb-0 mt-5">Upload bukti transfer</p>
              <ImageUploader
                withPreview={true}
                withIcon={true}
                singleImage={true}
                buttonText="Choose images"
                onChange={this.onDrop}
                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                maxFileSize={5242880}
              />
              <Button
                disabled={
                  this.state.imgUrl === null || this.state.imgUrl === undefined
                    ? true
                    : false
                }
                type="button"
                className="p-3 pr-5 pl-5"
                onClick={this.handleSubmit}
                size="sm"
                style={{
                  background: '#000000',
                  fontWeight: 'bold',
                  fontSize: '14px',
                  lineHeight: '18px',

                  color: '#FFFFFF',
                }}
              >
                SEND
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
  detailTransaction: state.transaction.detailTransaction || {},
});

const mapDispatchToProps = dispatch => ({
  updateProfile: dispatch.member.updateProfile,
  uploadImage: dispatch.gallery.uploadTransaction,
  getDetailTransaction: dispatch.transaction.getDetailTransaction,
  updateTransactionStatus: dispatch.transaction.updateTransactionStatus,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TransactionProcess));
