import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Message extends Component {
  static propTypes = {
    Layout: PropTypes.func.isRequired,
    adminLogout: PropTypes.func.isRequired,
    fetchMessages: PropTypes.func.isRequired,
    admin: PropTypes.shape({}).isRequired,
  };

  state = {
    error: null,
    loading: false,
  };

  componentDidMount = () => this.getAllMessage();

  getAllMessage = data => {
    const { fetchMessages } = this.props;
    this.setState({ loading: true });
    return fetchMessages()
      .then(() =>
        this.setState({
          loading: false,
          error: null,
        })
      )
      .catch(err =>
        this.setState({
          loading: false,
          error: err,
        })
      );
  };

  deleteMessages = id => {
    this.setState({ loading: true });
    // return deleteMessages(data)
    //   .then(() =>
    //     this.setState({
    //       loading: false,
    //       error: null,
    //     })
    //   )
    //   .catch(err =>
    //     this.setState({
    //       loading: false,
    //       error: err,
    //     })
    //   );
  };

  render = () => {
    const {
      Layout,
      allImage,
      admin,
      adminLogout,
      message,
      uploadImage,
      history,
    } = this.props;
    const { loading, error } = this.state;

    return (
      <Layout
        error={error}
        loading={loading}
        admin={admin}
        message={message}
        uploadImage={uploadImage}
        history={history}
        allImage={allImage}
        fetchMessages={() => this.getAllMessage()}
        logout={adminLogout}
        reFetch={() => this.fetchData()}
      />
    );
  };
}

const mapStateToProps = state => ({
  admin: state.admin || {},
  message: state.book.allMessage || {},
  //   allImage: state.message.messageAll || {},
});

const mapDispatchToProps = dispatch => ({
  getAllMessage: dispatch.book.getMessages,
  //   adminLogout: dispatch.admin.logout,
  fetchMessages: dispatch.book.getMessages,
  //   deleteMessages: dispatch.message.deleteMessages,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Message);
