import React from 'react';
import {
  Row,
  Col,
  Card,
  Form,
  Label,
  Alert,
  Input,
  Button,
  CardBody,
  FormGroup,
  CardHeader,
  CustomInput,
} from 'reactstrap';
import ModalImage from 'react-modal-image';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import config from '../../../../constants/config'

function capital_letter(str) {
  str = str.split(' ');

  for (var i = 0, x = str.length; i < x; i++) {
    str[i] = str[i][0].toLowerCase();
  }

  return str.join('');
}

const PriceInput = props => {
  let Pname = Object.keys(props.items)[0];
  let Pvalue = Object.values(props.items)[0];
  return (
    <FormGroup row>
      <Label for="exampleEmail" sm={2}>
        {(function() {
          switch (Pname) {
            case 'dp':
              return 'Daily Pass';
            case 'm':
              return 'Monthly';
            case 'w':
              return 'Weekly';
            case 'fd':
              return 'Full Day';
            case 'hd':
              return 'Half Day';
            case 'h':
              return 'Hourly';
            default:
              return null;
          }
        })()}
      </Label>
      <Col sm={8}>
        <Input
          type="number"
          name={Pname}
          id={props.items}
          placeholder="Price"
          value={Pvalue}
          onChange={e => props.handleChange(e.target.name, e.target.value)}
        />
      </Col>
      <Col sm={2}>
        <Button color="danger" onClick={() => props.handleRemove(Pname)}>
          Remove
        </Button>
      </Col>
    </FormGroup>
  );
};

class EditCoffee extends React.Component {
  static defaultProps = {
    error: null,
    success: null,
    member: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      pName: props.detailCoffee.name || '',
      coffee_type: props.detailCoffee.type_price || [],
      price_type: [],
      id: props.detailCoffee.id || 0,
      pt_form: [],
      description: props.detailCoffee.description || '',
      capacity: props.detailCoffee.capacity || '',
      benefits: [],
      image: props.detailCoffee.image || [],
      benefit: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onAddPrice = this.onAddPrice.bind(this);
    this.handleChangePrice = this.handleChangePrice.bind(this);
    this.handleLoadImage = this.handleLoadImage.bind(this);
    this.handleImage = this.handleImage.bind(this);
    this.fethcData = this.fethcData.bind(this);
    this.removeImage = this.removeImage.bind(this);
  }

  componentDidMount() {
    this.fethcData();

    let lp = this.state.coffee_type;
    lp = lp.map((str, index) => ({
      [capital_letter(str)]: str.substring(str.indexOf(':') + 1),
    }));
    this.setState({ coffee_type: lp });
  }

  fethcData() {
    this.props.getCoffee(this.props.match.params.coffeeId);
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  removeImage = id => {
    var newArrImg = this.state.image.filter(function(a) {
      return a !== id;
    });
    this.setState(() => ({
      image: newArrImg,
    }));
  };

  handleChangePrice = (name, value) => {
    // not allowed AND not working
    this.setState(state => {
      let lp = this.state.coffee_type;
      lp.forEach((element, index) => {
        if (Object.keys(lp[index])[0] === name) {
          lp[index][name] = value;
        }
      });
      return {
        lp,
      };
    });
  };

  handleImage = file => {
    if (file) {
      this.setState(() => ({
        img: file,
      }));
    }
  };

  handleLoadImage = file => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // const listImg = this.state.image.push(new Blob([reader.result], {type: "image/png"}));
        const listImg = this.state.image.push(reader.result);
        // as a good practice, use a function to set state
        this.setState(() => ({
          imagePreviewUrl: listImg,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  onAddPrice = data => {
    // not allowed AND not working
    this.setState(state => {
      let lp = { [data]: '' };
      const list = this.state.coffee_type.push(lp);
      return {
        list,
      };
    });
  };

  onRmvPrice = data => {
    // not allowed AND not working
    // eslint-disable-next-line
    this.setState(state => {
      const list = this.state.coffee_type.filter(
        el => Object.keys(el)[0] !== data
      );
      const coffee_type = this.state.coffee_type.filter(
        el => Object.keys(el)[0] !== data
      );
      this.state.coffee_type.filter(el => Object.keys(el)[0] !== data);
      //   const pList = this.state.pList.filter(el => el !== data);
      return {
        coffee_type,
        list,
        // pList,
      };
    });
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  hasBenefit = () =>
    this.setState(prevState => ({ benefit: !prevState.benefit }));

  handleSubmit = e => {
    e.preventDefault();
    const { updateCoffee, history } = this.props;

    return updateCoffee(this.state)
      .then(() => setTimeout(() => history.push('/backoffice/coffees'), 1000))
      .catch(() => {});
  };

  upload = e => {
    e.preventDefault();
    const { uploadImage } = this.props;
    return (
      uploadImage(this.state)
        .then(() =>
          this.setState(state => {
            const imglist = this.state.image.push(this.props.imgData);
            return {
              imglist,
            };
          })
        )
        // this.state.image.push(this.props.imgData)
        .catch(() => {})
    );
  };

  render() {
    const { success, error } = this.props;
    const { pName } = this.state;
    return (
      <div>
        <Row className="pt-5">
          <Col lg={{ size: 12 }}>
            <Card>
              <CardHeader>Edit Coffee</CardHeader>
              <CardBody>
                {!!success && <Alert color="success">{success}</Alert>}
                {!!error && <Alert color="danger">{error}</Alert>}

                <Form onSubmit={this.handleSubmit}>
                  <FormGroup row>
                    <Label for="exampleEmail" sm={2}>
                      Name
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="text"
                        name="pName"
                        id="pName"
                        placeholder="Coffee name"
                        value={pName}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleText" sm={2}>
                      Description
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="textarea"
                        name="description"
                        id="description"
                        max={200}
                        value={this.state.description}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleText" sm={2}>
                      Capacity
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="number"
                        name="capacity"
                        id="capacity"
                        max={200}
                        value={this.state.capacity}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </FormGroup>
                  <hr />
                  <FormGroup row>
                    <Label for="hasBenefit" sm={2}>
                      Benefit?{' '}
                    </Label>
                    <Col sm={10}>
                      <CustomInput
                        className="mt-1"
                        type="switch"
                        onClick={() => this.hasBenefit()}
                        id="hasBenefit"
                        name="hasBenefit"
                        label="Yes"
                      />
                    </Col>
                  </FormGroup>
                  {this.state.benefit ? (
                    <div>
                      <FormGroup row>
                        <Label for="exampleEmail" sm={2}>
                          Benefit 1
                        </Label>
                        <Col sm={10}>
                          <Input
                            type="text"
                            name="pName"
                            id="pName"
                            placeholder="Benefit.."
                            value={pName}
                            onChange={this.handleChange}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label for="exampleEmail" sm={2}>
                          Benefit 2
                        </Label>
                        <Col sm={10}>
                          <Input
                            type="text"
                            name="pName"
                            id="pName"
                            placeholder="Benefit.."
                            value={pName}
                            onChange={this.handleChange}
                          />
                        </Col>
                      </FormGroup>
                    </div>
                  ) : (
                    ''
                  )}
                  <hr />
                  <FormGroup row>
                    <Label for="priceSelect" sm={2}>
                      Price
                    </Label>
                    <Col sm={10}>
                      <CustomInput
                        type="select"
                        id="priceSelect"
                        name="priceSelect"
                        onChange={e => this.onAddPrice(e.target.value)}
                      >
                        <option value="">Select</option>
                        <option
                          value="dp"
                          disabled={
                            this.state.coffee_type.includes('dp')
                              ? true
                              : false
                          }
                        >
                          Daily Pass
                        </option>
                        <option
                          value="w"
                          disabled={
                            this.state.coffee_type.includes('w') ? true : false
                          }
                        >
                          Weekly
                        </option>
                        <option
                          value="m"
                          disabled={
                            this.state.coffee_type.includes('m') ? true : false
                          }
                        >
                          Monthly
                        </option>
                        <option
                          value="h"
                          disabled={
                            this.state.coffee_type.includes('h') ? true : false
                          }
                        >
                          Hourly
                        </option>
                        <option
                          value="hd"
                          disabled={
                            this.state.coffee_type.includes('hd')
                              ? true
                              : false
                          }
                        >
                          Half Day (4 Hours)
                        </option>
                        <option
                          value="fd"
                          disabled={
                            this.state.coffee_type.includes('fd')
                              ? true
                              : false
                          }
                        >
                          Full Day (8 Hours)
                        </option>
                      </CustomInput>
                    </Col>
                  </FormGroup>
                  {this.state.coffee_type.length !== 0
                    ? this.state.coffee_type.map(items => {
                        return (
                          <PriceInput
                            key={Object.keys(items)}
                            items={items}
                            handleRemove={this.onRmvPrice}
                            handleChange={this.handleChangePrice}
                            value={this.state[items]}
                          />
                        );
                      })
                    : ''}
                  <hr />
                  <FormGroup row>
                    <Label for="exampleFile" sm={2}>
                      Image
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="file"
                        accept="image/*"
                        name="photo"
                        onChange={e => this.handleImage(e.target.files[0])}
                      />
                      <Button
                        type="submit"
                        size="sm"
                        color="primary"
                        onClick={this.upload}
                      >
                        Upload
                      </Button>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="exampleFile" sm={2}>
                      List Image
                    </Label>
                    <Col sm={10}>
                      {this.state.image.length !== undefined
                        ? this.state.image.map((items, index) => {
                            return (
                              <div className="mt-3" key={index}>
                                <div
                                  style={{
                                    maxWidth: '10%',
                                    display: 'inline-block',
                                  }}
                                >
                                  <ModalImage
                                    small={`${config.apiUrl}${items}`}
                                    large={`${config.apiUrl}${items}`}
                                    alt={items}
                                  />
                                </div>
                                <Button
                                  onClick={() => this.removeImage(items)}
                                  color="danger"
                                  size="sm"
                                  outline
                                  className="ml-4"
                                >
                                  Remove
                                </Button>
                              </div>
                            );
                          })
                        : 's'}
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>

            {/* <FormGroup check row className="mt-4 text-right">
              <Col sm={{ size: 10, offset: 2 }}>
                <Button color="primary" outline onClick={this.handleSubmit}>
                  Create
                </Button>
              </Col>
            </FormGroup> */}

            <FormGroup check row className="mt-4 text-right">
              <Col sm={{ size: 12 }}>
                <Button
                  color="primary"
                  className="mr-3"
                  outline
                  size="sm"
                  onClick={this.handleSubmit}
                >
                  Save
                </Button>
                <Link to={`/backoffice/coffee/${this.state.id}`}>
                  <Button color="danger" outline size="sm">
                    Cancel
                  </Button>
                </Link>
              </Col>
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  imgData: state.coffees.imgUrl || {},
  detailCoffee: state.coffees.detailCoffee || {},
});

const mapDispatchToProps = dispatch => ({
  updateCoffee: dispatch.coffees.updateCoffee,
  uploadImage: dispatch.coffees.uploadImage,
  getCoffee: dispatch.coffees.getDetailCoffee,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditCoffee));

// export default withRouter(EditCoffee);
